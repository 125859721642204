/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "skills";

/**
 * This file automatically generated by skymarshal.
 * DO NOT MODIFY BY HAND!!!!
 */

export interface UpAndOverState {
}

export enum UpAndOverState_Enum {
  INVALID = 0,
  UP = 1,
  TRAVERSE = 2,
  DOWN = 3,
  UNRECOGNIZED = -1,
}

export function upAndOverState_EnumFromJSON(object: any): UpAndOverState_Enum {
  switch (object) {
    case 0:
    case "INVALID":
      return UpAndOverState_Enum.INVALID;
    case 1:
    case "UP":
      return UpAndOverState_Enum.UP;
    case 2:
    case "TRAVERSE":
      return UpAndOverState_Enum.TRAVERSE;
    case 3:
    case "DOWN":
      return UpAndOverState_Enum.DOWN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return UpAndOverState_Enum.UNRECOGNIZED;
  }
}

export function upAndOverState_EnumToJSON(object: UpAndOverState_Enum): string {
  switch (object) {
    case UpAndOverState_Enum.INVALID:
      return "INVALID";
    case UpAndOverState_Enum.UP:
      return "UP";
    case UpAndOverState_Enum.TRAVERSE:
      return "TRAVERSE";
    case UpAndOverState_Enum.DOWN:
      return "DOWN";
    case UpAndOverState_Enum.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

function createBaseUpAndOverState(): UpAndOverState {
  return {};
}

export const UpAndOverState = {
  encode(_: UpAndOverState, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpAndOverState {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpAndOverState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UpAndOverState {
    return {};
  },

  toJSON(_: UpAndOverState): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<UpAndOverState>, I>>(base?: I): UpAndOverState {
    return UpAndOverState.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpAndOverState>, I>>(_: I): UpAndOverState {
    const message = createBaseUpAndOverState();
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };
