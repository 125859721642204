/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";

export const protobufPackage = "nav_cam_reconstruction";

export interface PoseIdPb {
  flightId: string;
  utime: number;
}

function createBasePoseIdPb(): PoseIdPb {
  return { flightId: "", utime: 0 };
}

export const PoseIdPb = {
  encode(message: PoseIdPb, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.flightId !== "") {
      writer.uint32(10).string(message.flightId);
    }
    if (message.utime !== 0) {
      writer.uint32(16).sint64(message.utime);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PoseIdPb {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePoseIdPb();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.flightId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.utime = longToNumber(reader.sint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PoseIdPb {
    return {
      flightId: isSet(object.flightId) ? globalThis.String(object.flightId) : "",
      utime: isSet(object.utime) ? globalThis.Number(object.utime) : 0,
    };
  },

  toJSON(message: PoseIdPb): unknown {
    const obj: any = {};
    if (message.flightId !== "") {
      obj.flightId = message.flightId;
    }
    if (message.utime !== 0) {
      obj.utime = Math.round(message.utime);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PoseIdPb>, I>>(base?: I): PoseIdPb {
    return PoseIdPb.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PoseIdPb>, I>>(object: I): PoseIdPb {
    const message = createBasePoseIdPb();
    message.flightId = object.flightId ?? "";
    message.utime = object.utime ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
