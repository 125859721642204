/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "common";

/**
 * This file automatically generated by skymarshal.
 * DO NOT MODIFY BY HAND!!!!
 */

export interface VehicleFault {
}

export enum VehicleFault_Enum {
  INVALID = 0,
  TASK_QUEUE_RUNNING = 59,
  LIGHTING_ATTACHMENT_DISCONNECTED = 316,
  BATTERY_LOW_LAND = 56,
  PLANNER_UNRELIABLE = 4,
  POSE_POLISHER_UNRELIABLE = 5,
  NIGHT_MODE_HEADING_UNINITIALIZED = 214,
  VIO_UNRELIABLE = 6,
  VIO_DEGRADED = 148,
  GPS_DEGRADED = 381,
  VIO_UNRELIABLE_REDUCED_OBSTACLES = 198,
  VIO_STATIC_INITIALIZATION_FAILED = 216,
  VEHICLE_UNCALIBRATED = 248,
  VOXELS_UNRELIABLE = 7,
  STEREO_UNRELIABLE = 8,
  SUBJECT_UNRELIABLE = 9,
  IGNORE_OBSTACLES = 11,
  IGNORE_SOME_FAULTS = 12,
  LOGGING_SPACE_LOW = 13,
  LOGGING_SPACE_CRITICAL = 54,
  LOGGING_SPACE_LOW_BETA = 196,
  EMMC_SPACE_LOW = 293,
  EMMC_SPACE_LOW_QCU = 428,
  EMMC_SPACE_CRITICAL = 437,
  EMMC_SPACE_CRITICAL_QCU = 438,
  FLIGHT_LOGGER_UNRELIABLE = 14,
  MOTOR_NOT_SPINNING = 15,
  LOST_RC = 16,
  SUBJECT_CAMERA_UNRELIABLE = 17,
  SUBJECT_CAMERA_STOPPED = 358,
  BASE_PROCESSES_STOPPED = 18,
  FLIGHT_PROCESSES_STOPPED = 19,
  NOT_ARMED = 25,
  MOTOR_SYNC_LOST = 26,
  MISSING_IMAGES = 30,
  VEHICLE_STUCK = 31,
  IMU_DRIVER_UNRELIABLE = 32,
  BARO_DRIVER_UNRELIABLE = 33,
  NAV_CAMERAS_UNRELIABLE = 34,
  AMBARELLA_DRIVER_UNRELIABLE = 36,
  SKILLS_UNRELIABLE = 110,
  HAND_TAKEOFF_OBSTRUCTED = 38,
  HAND_TAKEOFF_SHALLOW_OBSTRUCTED = 116,
  HAND_TAKEOFF_VERTICAL_OBSTRUCTED = 118,
  BAD_CPU_FREQUENCY = 39,
  BAD_GPU_FREQUENCY = 40,
  HIGH_WINDS_WARN = 41,
  HIGH_WINDS_SEVERE = 103,
  HIGH_WINDS_LAND = 104,
  DIRTY_CAMERAS_LOW = 23,
  DIRTY_CAMERAS_HIGH = 24,
  COLLISION = 49,
  MOTOR_HOT_WARNING = 51,
  MOTOR_HOT_CRITICAL = 52,
  PLANNER_STATE_UNRELIABLE = 57,
  EXECUTIVE_MANAGER_UNRELIABLE = 58,
  TASK_QUEUE_RUNNING_UNINTERRUPTIBLE = 420,
  TASK_QUEUE_RUNNING_INTERRUPTIBLE = 421,
  AMBARELLA_SD_STILL_MOUNTED = 60,
  BATTERY_TOO_COLD = 63,
  BATTERY_NEEDS_HEATING = 344,
  BATTERY_HEATER_ON = 345,
  BATTERY_TOO_COLD_BUT_IS_HEATING = 348,
  BATTERY_TOO_COLD_LIMP_OK = 349,
  USBC_IN_USE = 65,
  USBC_SECURITY_KEY_IN_USE = 249,
  CPU_TOO_HIGH = 66,
  GPU_TOO_HIGH = 289,
  VEHICLE_TOO_HOT_CRITICAL = 48,
  VEHICLE_TOO_HOT_WARNING = 67,
  AMBARELLA_TOO_HOT = 68,
  GPS_UNRELIABLE = 69,
  GPS_AWAITING_HEADING = 375,
  PRE_FLIGHT_TASKS_REQUIRED = 71,
  GAP_TOO_SMALL = 73,
  HAND_TAKEOFF_DANGEROUS = 74,
  ENVIRONMENT_DANGEROUS = 76,
  HAND_TAKEOFF_DANGEROUS_HIGH_GRADIENT = 91,
  HAND_TAKEOFF_DANGEROUS_DARK = 97,
  MOTOR_FLASH_IN_PROGRESS = 77,
  MOTOR_FLASH_FAILURE = 78,
  TEGRA_STATE_ESTIMATION_FAILED = 79,
  EMERGENCY_LANDING_AIRHAWK_ONLY = 80,
  EMERGENCY_LANDING_TEGRA_POSES = 81,
  VELOCITY_CONTROL_DIVERGED = 82,
  POSITION_CONTROL_DIVERGED = 93,
  YAW_CONTROL_DIVERGED = 105,
  PILOT_NOT_READY_TO_FLY = 83,
  PILOT_WAITING_TO_FLY = 84,
  PILOT_WAITING_TO_BROWSE = 85,
  GPS_UNRELIABLE_HOVER = 86,
  TEGRA_STATE_ESTIMATION_HOVER = 87,
  MOTOR_QUALITY = 90,
  GIMBAL_IMU_UNRELIABLE = 240,
  GIMBAL_UNCALIBRATED = 241,
  GIMBAL_CONTROLLER_UNRELIABLE = 242,
  GIMBAL_ENCODER_UNRELIABLE = 286,
  GIMBAL_ESTIMATOR_FAILED = 243,
  GIMBAL_ESTIMATOR_MISSING_IMU_DATA = 244,
  GIMBAL_ESTIMATOR_ACCEL_NORM_TOO_HIGH = 245,
  GIMBAL_ESTIMATOR_NEEDS_INITIALIZER = 246,
  GIMBAL_ESTIMATOR_INSUFFICIENT_TIME = 247,
  GIMBAL_ESTIMATOR_COMPUTING_MOTOR_ANGLES = 269,
  GIMBAL_UNRELIABLE = 35,
  FALCON_HOOD = 88,
  GIMBAL_OBSTRUCTED = 89,
  GIMBAL_FREAK_OUT = 102,
  GIMBAL_EMBEDDED_ESTIMATOR_NOT_INITIALIZED = 251,
  GIMBAL_EMBEDDED_ESTIMATOR_STALE_REFERENCE = 252,
  GIMBAL_VISION_ESTIMATOR_UNRELIABLE = 94,
  GIMBAL_FLASHLIGHT_TOO_HOT = 372,
  EXTERNAL_TORQUE_NORM_TOO_HIGH = 95,
  ROTOR_SPEED_CONTROL_DIVERGED = 96,
  AUTOEXPOSURE_HAS_NOT_CONVERGED = 99,
  MEDIA_TRANSCODE_POSSIBLE = 100,
  NOT_ACHIEVING_MOVEMENT_COMMAND = 106,
  CONTACT = 107,
  BATTERY_TOO_HOT_WARNING = 108,
  BATTERY_TOO_HOT_CRITICAL = 109,
  BATTERY_NOT_CHARGING_TOO_HOT = 302,
  BATTERY_NOT_CHARGING_TOO_COLD = 303,
  MEMORY_LOW = 112,
  TOO_MANY_PAGE_FAULTS = 113,
  BATTERY_GAS_GAUGE_ERROR = 114,
  BATTERY_GAS_GAUGE_VERSION_MISMATCH = 350,
  VEHICLE_IN_HAND = 115,
  FLIGHT_LOGS_NOT_MOUNTED = 119,
  MOTOR_SPINUP_FAILURE = 120,
  MEDIA_SD_MISSING = 122,
  MEDIA_SD_UNFORMATTED = 124,
  MEDIA_SD_CORRUPTED = 131,
  MEDIA_SD_SLOW = 126,
  MEDIA_SPACE_LOW = 50,
  MEDIA_SPACE_CRITICAL = 55,
  MEDIA_SPACE_FULL = 294,
  INSUFFICIENT_MEDIA_SD_STORAGE_FOR_MISSION = 312,
  MEDIA_SD_NEEDS_TO_AUTODELETE = 224,
  MEDIA_SD_WRONG_ENCRYPTION_KEY = 226,
  MEDIA_SD_MISSING_NOT_DISMISSED = 123,
  MEDIA_SD_UNFORMATTED_NOT_DISMISSED = 125,
  MEDIA_SD_CORRUPTED_NOT_DISMISSED = 132,
  MEDIA_SD_SLOW_NOT_DISMISSED = 127,
  MEDIA_SPACE_LOW_NOT_DISMISSED = 128,
  MEDIA_SD_LOADING_DTED_FILES = 298,
  LOGGING_SD_MISSING = 193,
  LOGGING_SD_WRONG_FILESYSTEM = 151,
  LOGGING_SD_WRONG_FILESYSTEM_IN_USE = 152,
  LOGGING_SD_SLOW = 222,
  LOGGING_SD_NOT_ENCRYPTED = 225,
  LOGGING_SD_LOCKED = 232,
  LOGGING_SD_SECURITY_KEY_REQUIRED = 233,
  LOGGING_SD_SECURITY_ACTIVATION_IN_PROGRESS = 234,
  LOGGING_SD_SECURITY_ACTIVATION_FAILED = 235,
  BATTERY_CRITICAL = 1,
  BATTERY_CRITICAL_FORCE_FAST_DESCENT = 365,
  BATTERY_LOW = 70,
  BATTERY_LOW_RETURN = 351,
  BATTERY_LOW_RETURN_TO_DOCK = 210,
  BATTERY_LOW_RETURN_TO_LOST_CONNECTION = 206,
  BATTERY_LOW_SAFE_LANDING_UNREACHABLE = 367,
  BATTERY_LOW_DISABLE_AUTONOMOUS = 223,
  BATTERY_VOLTAGE_IMBALANCE = 282,
  BATTERY_VOLTAGE_IMBALANCE_LAND = 287,
  BATTERY_HIGH_CURRENT = 154,
  BATTERY_HIGH_CURRENT_CRITICAL = 155,
  PAGE_ALLOCATION_FAILURE = 133,
  LOST_PHONE_COMM_SHORT = 2,
  LOST_CONNECTION_RETURN = 432,
  LOST_CONNECTION_RTH = 139,
  LOST_CONNECTION_RTD = 209,
  LOST_CONNECTION_LAND = 140,
  LOST_CONNECTION_STOP_TRACK = 208,
  PHONE_GPS_QUALITY_INSUFFICIENT = 134,
  VEHICLE_GPS_QUALITY_INSUFFICIENT = 135,
  VEHICLE_GPS_QUALITY_BLOCKING = 150,
  VEHICLE_GPS_INOPERATIVE = 283,
  BEACON_GPS_QUALITY_BLOCKING = 136,
  BEACON_NEEDS_ASSISTNOW = 137,
  BEACON_GPS_QUALITY_INSUFFICIENT = 141,
  RID_BEACON_GPS_INOPERATIVE = 290,
  USER_CAMERA_MODE_SWITCH = 144,
  UNCANCELLABLE_LANDING = 142,
  PILOT_STILL_WAITING_TO_FLY = 143,
  BEACON_UPDATE_IN_PROGRESS = 147,
  JOYSTICKS_UPDATE_IN_PROGRESS = 149,
  SHOULD_CONNECT_THROUGH_BEACON = 145,
  SHOULD_CONNECT_THROUGH_JOYSTICKS = 146,
  SHOULD_CONNECT_SKYSTICKS = 280,
  COMMANDING_BEYOND_SAFETY_HEIGHT = 153,
  PHONE_BATTERY_LOW = 27,
  PHONE_BATTERY_CRITICAL = 28,
  BEACON_BATTERY_LOW = 157,
  BEACON_BATTERY_CRITICAL = 167,
  JOYSTICKS_BATTERY_LOW = 158,
  SKYSTICKS_BATTERY_LOW = 354,
  PHONE_BATTERY_LOW_NOT_DISMISSED = 156,
  BEACON_BATTERY_LOW_NOT_DISMISSED = 159,
  JOYSTICKS_BATTERY_LOW_NOT_DISMISSED = 160,
  SKYSTICKS_BATTERY_LOW_NOT_DISMISSED = 355,
  FLIGHT_DECK_OUT_OF_SYNC = 161,
  BEACON_UPDATE_READY = 162,
  JOYSTICKS_UPDATE_READY = 163,
  STUCK_SAFETY_HEIGHT = 164,
  BEACON_GPS_QUALITY_INSUFFICIENT_TRACKING_PAUSED = 165,
  BEACON_GPS_QUALITY_INSUFFICIENT_WITH_VISUAL = 166,
  MIND_THE_GAP = 168,
  MIND_THE_STUCK_SAFETY_HEIGHT = 169,
  BATTERY_MESSAGES_UNRELIABLE = 53,
  BATTERY_RESET_REQUIRED = 170,
  TEGRA_REBOOTED_IN_FLIGHT = 171,
  MAYBE_TEGRA_REBOOTED_IN_FLIGHT = 313,
  BACKTRACK_UNAVAILABLE_TEGRA_REBOOTED = 329,
  TOO_DARK_CRITICAL = 29,
  TOO_DARK_HOVER = 61,
  TOO_DARK_WARNING = 62,
  TOO_DARK_FOR_VISION = 172,
  TOO_DARK_TRY_NIGHT_AUTONOMY = 378,
  TAKEOFF_NEEDS_MOTION = 174,
  HAND_WAVE_CAL_NEEDS_STOP_WAVING = 253,
  HAND_WAVE_UNAVAILABLE = 260,
  TAKEOFF_BLOCKED_BY_POSE_POLISHER = 175,
  NOT_STABLE_FOR_TAKEOFF = 21,
  TOO_FAST_FOR_TAKEOFF = 22,
  TOO_FAST_FOR_TAKEOFF_NIGHT = 176,
  NOT_STABLE_FOR_TAKEOFF_NIGHT = 177,
  TOO_FAST_FOR_TAKEOFF_VESSEL = 258,
  NOT_STABLE_FOR_TAKEOFF_VESSEL = 259,
  TEGRA_REQUIRES_EMERGENCY_LAND = 179,
  IS_FIRST_PILOTED_EMERGENCY_LAND = 368,
  IS_PILOTED_EMERGENCY_LAND = 299,
  COMMANDED_EMERGENCY_LAND = 366,
  EMERGENCY_LAND_MOTOR_ARMING_COMMAND = 180,
  EMERGENCY_LAND_LOST_RC_LINK = 181,
  EMERGENCY_LAND_TEGRA_HEALTH_MONITOR_STALE = 182,
  EMERGENCY_LAND_TEGRA_ATTITUDE_COMMAND_STALE = 183,
  EMERGENCY_LAND_RC_EMERGENCY_LAND_COMMAND = 184,
  EMERGENCY_LAND_SYMPC_CONTROLLER_FAILED = 185,
  EMERGENCY_LAND_NO_STATE_ESTIMATE_AVAILABLE = 186,
  JOYSTICKS_INPUT_PRE_TAKEOFF = 187,
  TRIGGER_OR_ROLLER_INPUT_PRE_TAKEOFF = 219,
  JOYSTICKS_INPUT_ERRATIC = 386,
  MAGNETOMETER_NEEDS_CALIBRATION = 188,
  MAGNETOMETER_NEEDS_GPS = 250,
  ATTI_RTX_REQUIRES_MAG_CAL = 423,
  ATTI_RTX_MAG_REQUIRES_LOCATION = 425,
  USER_CAMERA_PHOTO_CAPTURE = 190,
  COMMANDED_PAST_POOR_GPS_HEIGHT = 191,
  COMMANDED_PAST_MAX_HEIGHT = 192,
  COMMANDED_PAST_INDOOR_POOR_GPS_HEIGHT = 229,
  COMMANDED_PAST_DENSITY_ALTITUDE = 415,
  COMMANDED_PAST_POOR_GPS_HEIGHT_NOT_DISMISSED = 227,
  COMMANDED_PAST_MAX_HEIGHT_NOT_DISMISSED = 228,
  COMMANDED_PAST_INDOOR_POOR_GPS_HEIGHT_NOT_DISMISSED = 230,
  AT_GEOFENCE_EDGE = 276,
  AT_GEOFENCE_EDGE_NOT_DISMISSED = 277,
  RETURNING_TO_GEOFENCE = 278,
  RETURNING_TO_GEOFENCE_NOT_DISMISSED = 279,
  FAR_OUTSIDE_GEOFENCE = 308,
  GEOFENCE_UNRELIABLE = 318,
  VEHICLE_NEEDS_ASSISTNOW = 194,
  SKILLS_LATENCY_HIGH = 195,
  DOCK_NOT_READY_FOR_TAKEOFF = 197,
  WEBRTC_OFFLINE = 199,
  DOCK_DISCONNECTED = 200,
  DOCK_DETECTED = 201,
  VEHICLE_ON_SOME_TAKEOFF_SURFACE = 306,
  DOCK_IN_INSPECTION_MODE = 309,
  DIGITAL_ZOOM_UNAVAILABLE = 202,
  SUPERZOOM_UNAVAILABLE_SUBJECT_TRACKING = 203,
  MOTOR_CURRENT_WARNING = 204,
  MOTOR_CURRENT_CRITICAL = 205,
  MOTOR_IDLE_OVERCURRENT_CRITICAL = 429,
  MOTOR_IDLE_OVERCURRENT_WARNING = 442,
  AIRHAWK_ATTITUDE_CONTROL_REQ_RESTART = 207,
  DEFAULT_WIFI_AP_CREDENTIALS = 211,
  IMPERFECT_CONTACT_WITH_DOCK = 212,
  LOST_CONNECTION_DROP_VIDEO = 217,
  ARM_IMPROPER_POSITION_VISION = 218,
  ARM_IMPROPER_POSITION_CRITICAL = 301,
  ARM_IMPROPER_POSITION_VISION_BLOCK_TAKEOFF = 304,
  VIO_CALIBRATIONS_NOT_CONVERGED = 220,
  AUTO_GPS_INITIALIZATION_FAILED = 221,
  BEACON_BARO_OFFSET_NEEDED = 231,
  DOCK_VEHICLE_PAIRING_MISMATCH = 261,
  RID_KEY_REQUIRED = 255,
  MOTOR_VIBE_CHECK_PENDING = 256,
  EXCESSIVE_MOTOR_VIBE = 257,
  MANUAL_MODE_RECOMMENDED = 262,
  RID_CHECK_STARTED = 263,
  WIFI_NO_CONFIGURED_SSIDS_FOUND = 264,
  WIFI_AUTHENTICATION_ERROR = 265,
  WIFI_CONNECTION_TIMEOUT = 266,
  CONTROLLER_GPS_QUALITY_INSUFFICIENT = 267,
  RID_CONTROLLER_GPS_INOPERATIVE = 291,
  RID_RPIC_GPS_INOPERATIVE = 307,
  DOCK_CRADLE_EXTENDED_PRE_TAKEOFF = 305,
  IS_BASESTATION_TAKEOFF_SURFACE = 445,
  IS_BASESTATION_LANDING_SURFACE = 281,
  LANDED_OUTSIDE_DOCK = 296,
  AIRHAWK_RT_LOSS = 268,
  LIVEKIT_NO_CONFIG = 270,
  LIVEKIT_CONFIG_MISMATCH = 271,
  LIVEKIT_NOT_ENABLED = 272,
  LIVEKIT_CONNECTED = 273,
  LIVEKIT_CONNECTION_FAILED = 274,
  VEHICLE_GPS_QUALITY_BLOCKING_TWILIGHT = 285,
  FLIGHT_TERMINATED = 284,
  NAV_CAMERA_LOGGING_DEGRADED = 288,
  SKILLS_TASKS_PENDING = 292,
  DOCK_COMMS_UNRELIABLE = 295,
  CLOUD_ENV_OUT_OF_SYNC = 376,
  TRACK_TRAJECTORY_STUCK_ON_OBSTACLES = 297,
  SKILLS_TASK_NEEDS_DECRYPTION = 363,
  USER_CAMERA_SLOW_MODE_SWITCH = 300,
  NVU_DISCONNECTED = 310,
  QCU_DISCONNECTED = 311,
  BARO_MEASUREMENT_UNRELIABLE = 314,
  WAYPOINTS_UNRELIABLE = 315,
  WAYPOINT_TASK_STUCK_ON_OBSTACLE = 352,
  WAYPOINT_TASK_STUCK_ON_GEOFENCE = 353,
  DOCK_CLIENT_UNRELIABLE = 317,
  SPURIOUS_VOXELS_DAY = 319,
  SPURIOUS_VOXELS_NIGHT = 356,
  SPURIOUS_VOXELS_DEBUG = 361,
  BAD_GPU_FREQUENCY_QCU = 320,
  CPU_TOO_HIGH_QCU = 321,
  GPU_TOO_HIGH_QCU = 322,
  BASE_PROCESSES_STOPPED_QCU = 323,
  FLIGHT_PROCESSES_STOPPED_QCU = 324,
  MEMORY_LOW_QCU = 325,
  TOO_MANY_PAGE_FAULTS_QCU = 326,
  FLIGHT_DECK_OUT_OF_SYNC_QCU = 327,
  TOF_UNRELIABLE = 328,
  TOF_UNRELIABLE_FOR_FAST_DESCENT_LANDING = 332,
  INFRARED_CAMERA_UNRELIABLE = 330,
  TOO_FAST_NIGHT_AUTONOMY = 331,
  VEHICLE_RADIO_UPDATE_IN_PROGRESS = 333,
  ATTACHMENT_CHANGE_DETECTED = 334,
  MISMATCHED_NIGHTSENSE_ATTACHMENT_DETECTED = 400,
  MIXED_NIGHTSENSE_ATTACHMENTS_DETECTED = 401,
  RTK_PPK_ATTACHMENT_CONNECTED = 402,
  RTK_PPK_ATTACHMENT_CONNECTED_NOT_DISMISSED = 413,
  NIGHTSENSE_ATTACHMENT_DEGRADED = 403,
  NIGHTSENSE_ATTACHMENT_DEGRADED_NOT_DISMISSED = 404,
  NIGHTSENSE_DISABLED_ATTACHMENT_FAILURE = 405,
  NIGHTSENSE_DISABLED_ATTACHMENT_FAILURE_NOT_DISMISSED = 406,
  NIGHTSENSE_DISABLED_ATTACHMENT_DISCONNECTED = 407,
  NIGHTSENSE_DISABLED_ATTACHMENT_DISCONNECTED_NOT_DISMISSED = 408,
  NIGHTSENSE_UNAVAILABLE_ATTACHMENT_MISSING = 409,
  NIGHTSENSE_UNAVAILABLE_ATTACHMENT_MISSING_NOT_DISMISSED = 410,
  FLASH_DRIVE_REQUIRED = 335,
  HOUSEKEEPER_MCU_VERSION_MISMATCH = 336,
  AIRHAWK_MCU_VERSION_MISMATCH = 337,
  GIMBAL_MCU_VERSION_MISMATCH = 338,
  SOC_FLASHPACK_MISMATCH = 339,
  SOC_CONFIG_MISMATCH = 340,
  SOC_SERIAL_NUMBER_MISMATCH = 341,
  OTA_IN_PROGRESS = 342,
  FIRMWARE_MISCONFIGURED = 343,
  AIRHAWK_SERIAL_CONNECTION_LOST = 346,
  AIRHAWK_SERIAL_CONNECTION_LOST_QCU = 347,
  OBSTACLE_MARGINS_CANNOT_EXPAND = 357,
  MOTOR_TAKEOFF_CURRENT_CRITICAL = 359,
  RELATIVE_HEADING_ESTIMATE_UNAVAILABLE = 360,
  IMU_SATURATION_ON_TAKEOFF = 362,
  VEHICLE_GPS_RESET = 364,
  PRE_DOCK_TAKEOFF_VEHICLE_GPS_RESET_UNSUCCESSFUL = 369,
  GIMBAL_FLASH_FAILED = 370,
  INFRARED_CAMERA_POOR_ACCURACY = 371,
  GIMBAL_TYPE_INCOMPATIBLE = 373,
  FRONT_UNRELIABLE = 374,
  MOTOR_OSCILLATION_DETECTED = 377,
  ATTACHMENT_OLD_SIDE_ATTACHMENT_HARDWARE = 388,
  ATTACHMENT_WRONG_CABLE_ORIENTATION = 389,
  ATTACHMENT_ENUMERATION_IN_PROGRESS = 439,
  ATTACHMENT_IN_INVALID_PORT_TOP = 390,
  ATTACHMENT_IN_INVALID_PORT_BOTTOM = 391,
  ATTACHMENT_IN_INVALID_PORT_LEFT = 392,
  ATTACHMENT_IN_INVALID_PORT_RIGHT = 393,
  ATTACHMENT_INSPECT_OR_REPLACE_TOP = 394,
  ATTACHMENT_INSPECT_OR_REPLACE_BOTTOM = 395,
  ATTACHMENT_INSPECT_OR_REPLACE_LEFT = 396,
  ATTACHMENT_INSPECT_OR_REPLACE_RIGHT = 397,
  ATTACHMENT_NIGHTSENSE_VERSION_MISMATCH = 379,
  ATTACHMENT_NIGHTSENSE_UPDATE_FAILED = 384,
  ATTACHMENT_NIGHTSENSE_UPDATE_FAILED_NOT_DISMISSED = 385,
  ATTACHMENT_SPOTLIGHT_BRIGHTNESS_THROTTLING = 398,
  ATTACHMENT_SPOTLIGHT_THERMAL_SHUTDOWN = 416,
  ATTACHMENT_SPOTLIGHT_ABOVE_TOUCH_TEMP = 399,
  ATTACHMENT_PARACHUTE_DEPLOYED = 441,
  ATTACHMENT_RTK_COM_FAILURE = 422,
  ATTACHMENT_SPEAKERMIC_LOW_VOLUME = 440,
  ATTACHMENT_SPOTLIGHT_TWO_SPOTLIGHTS_UNSUPPORTED = 417,
  ATTACHMENT_INVALID_COMBINATION = 427,
  RID_TX_FAILURE = 380,
  PINE_HIGH_INTERFERENCE = 435,
  PINE_SU_PKTS_IN_OFDMA = 436,
  NCPG_SAVE_IN_PROGRESS = 382,
  MISSION_CONDUCTOR_DISABLE_PILOTSHIP = 383,
  GROUND_AVOIDANCE_ACTIVE = 387,
  GPS_SPOOFED = 411,
  EVENT_LOOP_FD_OVERFLOW = 412,
  MICROHARD_DCS_JAMMING_FAULT = 414,
  BATTERY_NOT_ARMED = 418,
  VEHICLE_TOO_HOT_CRITICAL_FINAL = 419,
  IMU_NEAR_SATURATION = 424,
  ATTI_TAKEOFF_AWAITING_USER_INPUT = 426,
  VEHICLE_CLOCK_STALE = 430,
  VEHICLE_CLOCK_STALE_NOT_DISMISSED = 431,
  FLIR_FIRMWARE_UPDATE_IN_PROGRESS = 433,
  FLIR_FIRMWARE_VERSION_MISMATCH = 434,
  NAV_FRAME_UNRELIABLE = 443,
  MISSION_NOT_READY_FOR_TAKEOFF = 444,
  UNEXPECTED_OBSTACLES_HIGH_SPEED = 446,
  APPROACHING_GEOAWARENESS_ZONE = 447,
  ENTERED_GEOAWARENESS_ZONE = 448,
  GEOAWARENESS_UNAVAILABLE_FILE = 449,
  GEOAWARENESS_UNAVAILABLE_GPS = 450,
  YIELD_FOR_NEARBY_DRONES = 451,
  AWAITING_CLOUD_CONFIG_UPDATE = 452,
  MAX_NUM_FAULTS = 500,
  UNRECOGNIZED = -1,
}

export function vehicleFault_EnumFromJSON(object: any): VehicleFault_Enum {
  switch (object) {
    case 0:
    case "INVALID":
      return VehicleFault_Enum.INVALID;
    case 59:
    case "TASK_QUEUE_RUNNING":
      return VehicleFault_Enum.TASK_QUEUE_RUNNING;
    case 316:
    case "LIGHTING_ATTACHMENT_DISCONNECTED":
      return VehicleFault_Enum.LIGHTING_ATTACHMENT_DISCONNECTED;
    case 56:
    case "BATTERY_LOW_LAND":
      return VehicleFault_Enum.BATTERY_LOW_LAND;
    case 4:
    case "PLANNER_UNRELIABLE":
      return VehicleFault_Enum.PLANNER_UNRELIABLE;
    case 5:
    case "POSE_POLISHER_UNRELIABLE":
      return VehicleFault_Enum.POSE_POLISHER_UNRELIABLE;
    case 214:
    case "NIGHT_MODE_HEADING_UNINITIALIZED":
      return VehicleFault_Enum.NIGHT_MODE_HEADING_UNINITIALIZED;
    case 6:
    case "VIO_UNRELIABLE":
      return VehicleFault_Enum.VIO_UNRELIABLE;
    case 148:
    case "VIO_DEGRADED":
      return VehicleFault_Enum.VIO_DEGRADED;
    case 381:
    case "GPS_DEGRADED":
      return VehicleFault_Enum.GPS_DEGRADED;
    case 198:
    case "VIO_UNRELIABLE_REDUCED_OBSTACLES":
      return VehicleFault_Enum.VIO_UNRELIABLE_REDUCED_OBSTACLES;
    case 216:
    case "VIO_STATIC_INITIALIZATION_FAILED":
      return VehicleFault_Enum.VIO_STATIC_INITIALIZATION_FAILED;
    case 248:
    case "VEHICLE_UNCALIBRATED":
      return VehicleFault_Enum.VEHICLE_UNCALIBRATED;
    case 7:
    case "VOXELS_UNRELIABLE":
      return VehicleFault_Enum.VOXELS_UNRELIABLE;
    case 8:
    case "STEREO_UNRELIABLE":
      return VehicleFault_Enum.STEREO_UNRELIABLE;
    case 9:
    case "SUBJECT_UNRELIABLE":
      return VehicleFault_Enum.SUBJECT_UNRELIABLE;
    case 11:
    case "IGNORE_OBSTACLES":
      return VehicleFault_Enum.IGNORE_OBSTACLES;
    case 12:
    case "IGNORE_SOME_FAULTS":
      return VehicleFault_Enum.IGNORE_SOME_FAULTS;
    case 13:
    case "LOGGING_SPACE_LOW":
      return VehicleFault_Enum.LOGGING_SPACE_LOW;
    case 54:
    case "LOGGING_SPACE_CRITICAL":
      return VehicleFault_Enum.LOGGING_SPACE_CRITICAL;
    case 196:
    case "LOGGING_SPACE_LOW_BETA":
      return VehicleFault_Enum.LOGGING_SPACE_LOW_BETA;
    case 293:
    case "EMMC_SPACE_LOW":
      return VehicleFault_Enum.EMMC_SPACE_LOW;
    case 428:
    case "EMMC_SPACE_LOW_QCU":
      return VehicleFault_Enum.EMMC_SPACE_LOW_QCU;
    case 437:
    case "EMMC_SPACE_CRITICAL":
      return VehicleFault_Enum.EMMC_SPACE_CRITICAL;
    case 438:
    case "EMMC_SPACE_CRITICAL_QCU":
      return VehicleFault_Enum.EMMC_SPACE_CRITICAL_QCU;
    case 14:
    case "FLIGHT_LOGGER_UNRELIABLE":
      return VehicleFault_Enum.FLIGHT_LOGGER_UNRELIABLE;
    case 15:
    case "MOTOR_NOT_SPINNING":
      return VehicleFault_Enum.MOTOR_NOT_SPINNING;
    case 16:
    case "LOST_RC":
      return VehicleFault_Enum.LOST_RC;
    case 17:
    case "SUBJECT_CAMERA_UNRELIABLE":
      return VehicleFault_Enum.SUBJECT_CAMERA_UNRELIABLE;
    case 358:
    case "SUBJECT_CAMERA_STOPPED":
      return VehicleFault_Enum.SUBJECT_CAMERA_STOPPED;
    case 18:
    case "BASE_PROCESSES_STOPPED":
      return VehicleFault_Enum.BASE_PROCESSES_STOPPED;
    case 19:
    case "FLIGHT_PROCESSES_STOPPED":
      return VehicleFault_Enum.FLIGHT_PROCESSES_STOPPED;
    case 25:
    case "NOT_ARMED":
      return VehicleFault_Enum.NOT_ARMED;
    case 26:
    case "MOTOR_SYNC_LOST":
      return VehicleFault_Enum.MOTOR_SYNC_LOST;
    case 30:
    case "MISSING_IMAGES":
      return VehicleFault_Enum.MISSING_IMAGES;
    case 31:
    case "VEHICLE_STUCK":
      return VehicleFault_Enum.VEHICLE_STUCK;
    case 32:
    case "IMU_DRIVER_UNRELIABLE":
      return VehicleFault_Enum.IMU_DRIVER_UNRELIABLE;
    case 33:
    case "BARO_DRIVER_UNRELIABLE":
      return VehicleFault_Enum.BARO_DRIVER_UNRELIABLE;
    case 34:
    case "NAV_CAMERAS_UNRELIABLE":
      return VehicleFault_Enum.NAV_CAMERAS_UNRELIABLE;
    case 36:
    case "AMBARELLA_DRIVER_UNRELIABLE":
      return VehicleFault_Enum.AMBARELLA_DRIVER_UNRELIABLE;
    case 110:
    case "SKILLS_UNRELIABLE":
      return VehicleFault_Enum.SKILLS_UNRELIABLE;
    case 38:
    case "HAND_TAKEOFF_OBSTRUCTED":
      return VehicleFault_Enum.HAND_TAKEOFF_OBSTRUCTED;
    case 116:
    case "HAND_TAKEOFF_SHALLOW_OBSTRUCTED":
      return VehicleFault_Enum.HAND_TAKEOFF_SHALLOW_OBSTRUCTED;
    case 118:
    case "HAND_TAKEOFF_VERTICAL_OBSTRUCTED":
      return VehicleFault_Enum.HAND_TAKEOFF_VERTICAL_OBSTRUCTED;
    case 39:
    case "BAD_CPU_FREQUENCY":
      return VehicleFault_Enum.BAD_CPU_FREQUENCY;
    case 40:
    case "BAD_GPU_FREQUENCY":
      return VehicleFault_Enum.BAD_GPU_FREQUENCY;
    case 41:
    case "HIGH_WINDS_WARN":
      return VehicleFault_Enum.HIGH_WINDS_WARN;
    case 103:
    case "HIGH_WINDS_SEVERE":
      return VehicleFault_Enum.HIGH_WINDS_SEVERE;
    case 104:
    case "HIGH_WINDS_LAND":
      return VehicleFault_Enum.HIGH_WINDS_LAND;
    case 23:
    case "DIRTY_CAMERAS_LOW":
      return VehicleFault_Enum.DIRTY_CAMERAS_LOW;
    case 24:
    case "DIRTY_CAMERAS_HIGH":
      return VehicleFault_Enum.DIRTY_CAMERAS_HIGH;
    case 49:
    case "COLLISION":
      return VehicleFault_Enum.COLLISION;
    case 51:
    case "MOTOR_HOT_WARNING":
      return VehicleFault_Enum.MOTOR_HOT_WARNING;
    case 52:
    case "MOTOR_HOT_CRITICAL":
      return VehicleFault_Enum.MOTOR_HOT_CRITICAL;
    case 57:
    case "PLANNER_STATE_UNRELIABLE":
      return VehicleFault_Enum.PLANNER_STATE_UNRELIABLE;
    case 58:
    case "EXECUTIVE_MANAGER_UNRELIABLE":
      return VehicleFault_Enum.EXECUTIVE_MANAGER_UNRELIABLE;
    case 420:
    case "TASK_QUEUE_RUNNING_UNINTERRUPTIBLE":
      return VehicleFault_Enum.TASK_QUEUE_RUNNING_UNINTERRUPTIBLE;
    case 421:
    case "TASK_QUEUE_RUNNING_INTERRUPTIBLE":
      return VehicleFault_Enum.TASK_QUEUE_RUNNING_INTERRUPTIBLE;
    case 60:
    case "AMBARELLA_SD_STILL_MOUNTED":
      return VehicleFault_Enum.AMBARELLA_SD_STILL_MOUNTED;
    case 63:
    case "BATTERY_TOO_COLD":
      return VehicleFault_Enum.BATTERY_TOO_COLD;
    case 344:
    case "BATTERY_NEEDS_HEATING":
      return VehicleFault_Enum.BATTERY_NEEDS_HEATING;
    case 345:
    case "BATTERY_HEATER_ON":
      return VehicleFault_Enum.BATTERY_HEATER_ON;
    case 348:
    case "BATTERY_TOO_COLD_BUT_IS_HEATING":
      return VehicleFault_Enum.BATTERY_TOO_COLD_BUT_IS_HEATING;
    case 349:
    case "BATTERY_TOO_COLD_LIMP_OK":
      return VehicleFault_Enum.BATTERY_TOO_COLD_LIMP_OK;
    case 65:
    case "USBC_IN_USE":
      return VehicleFault_Enum.USBC_IN_USE;
    case 249:
    case "USBC_SECURITY_KEY_IN_USE":
      return VehicleFault_Enum.USBC_SECURITY_KEY_IN_USE;
    case 66:
    case "CPU_TOO_HIGH":
      return VehicleFault_Enum.CPU_TOO_HIGH;
    case 289:
    case "GPU_TOO_HIGH":
      return VehicleFault_Enum.GPU_TOO_HIGH;
    case 48:
    case "VEHICLE_TOO_HOT_CRITICAL":
      return VehicleFault_Enum.VEHICLE_TOO_HOT_CRITICAL;
    case 67:
    case "VEHICLE_TOO_HOT_WARNING":
      return VehicleFault_Enum.VEHICLE_TOO_HOT_WARNING;
    case 68:
    case "AMBARELLA_TOO_HOT":
      return VehicleFault_Enum.AMBARELLA_TOO_HOT;
    case 69:
    case "GPS_UNRELIABLE":
      return VehicleFault_Enum.GPS_UNRELIABLE;
    case 375:
    case "GPS_AWAITING_HEADING":
      return VehicleFault_Enum.GPS_AWAITING_HEADING;
    case 71:
    case "PRE_FLIGHT_TASKS_REQUIRED":
      return VehicleFault_Enum.PRE_FLIGHT_TASKS_REQUIRED;
    case 73:
    case "GAP_TOO_SMALL":
      return VehicleFault_Enum.GAP_TOO_SMALL;
    case 74:
    case "HAND_TAKEOFF_DANGEROUS":
      return VehicleFault_Enum.HAND_TAKEOFF_DANGEROUS;
    case 76:
    case "ENVIRONMENT_DANGEROUS":
      return VehicleFault_Enum.ENVIRONMENT_DANGEROUS;
    case 91:
    case "HAND_TAKEOFF_DANGEROUS_HIGH_GRADIENT":
      return VehicleFault_Enum.HAND_TAKEOFF_DANGEROUS_HIGH_GRADIENT;
    case 97:
    case "HAND_TAKEOFF_DANGEROUS_DARK":
      return VehicleFault_Enum.HAND_TAKEOFF_DANGEROUS_DARK;
    case 77:
    case "MOTOR_FLASH_IN_PROGRESS":
      return VehicleFault_Enum.MOTOR_FLASH_IN_PROGRESS;
    case 78:
    case "MOTOR_FLASH_FAILURE":
      return VehicleFault_Enum.MOTOR_FLASH_FAILURE;
    case 79:
    case "TEGRA_STATE_ESTIMATION_FAILED":
      return VehicleFault_Enum.TEGRA_STATE_ESTIMATION_FAILED;
    case 80:
    case "EMERGENCY_LANDING_AIRHAWK_ONLY":
      return VehicleFault_Enum.EMERGENCY_LANDING_AIRHAWK_ONLY;
    case 81:
    case "EMERGENCY_LANDING_TEGRA_POSES":
      return VehicleFault_Enum.EMERGENCY_LANDING_TEGRA_POSES;
    case 82:
    case "VELOCITY_CONTROL_DIVERGED":
      return VehicleFault_Enum.VELOCITY_CONTROL_DIVERGED;
    case 93:
    case "POSITION_CONTROL_DIVERGED":
      return VehicleFault_Enum.POSITION_CONTROL_DIVERGED;
    case 105:
    case "YAW_CONTROL_DIVERGED":
      return VehicleFault_Enum.YAW_CONTROL_DIVERGED;
    case 83:
    case "PILOT_NOT_READY_TO_FLY":
      return VehicleFault_Enum.PILOT_NOT_READY_TO_FLY;
    case 84:
    case "PILOT_WAITING_TO_FLY":
      return VehicleFault_Enum.PILOT_WAITING_TO_FLY;
    case 85:
    case "PILOT_WAITING_TO_BROWSE":
      return VehicleFault_Enum.PILOT_WAITING_TO_BROWSE;
    case 86:
    case "GPS_UNRELIABLE_HOVER":
      return VehicleFault_Enum.GPS_UNRELIABLE_HOVER;
    case 87:
    case "TEGRA_STATE_ESTIMATION_HOVER":
      return VehicleFault_Enum.TEGRA_STATE_ESTIMATION_HOVER;
    case 90:
    case "MOTOR_QUALITY":
      return VehicleFault_Enum.MOTOR_QUALITY;
    case 240:
    case "GIMBAL_IMU_UNRELIABLE":
      return VehicleFault_Enum.GIMBAL_IMU_UNRELIABLE;
    case 241:
    case "GIMBAL_UNCALIBRATED":
      return VehicleFault_Enum.GIMBAL_UNCALIBRATED;
    case 242:
    case "GIMBAL_CONTROLLER_UNRELIABLE":
      return VehicleFault_Enum.GIMBAL_CONTROLLER_UNRELIABLE;
    case 286:
    case "GIMBAL_ENCODER_UNRELIABLE":
      return VehicleFault_Enum.GIMBAL_ENCODER_UNRELIABLE;
    case 243:
    case "GIMBAL_ESTIMATOR_FAILED":
      return VehicleFault_Enum.GIMBAL_ESTIMATOR_FAILED;
    case 244:
    case "GIMBAL_ESTIMATOR_MISSING_IMU_DATA":
      return VehicleFault_Enum.GIMBAL_ESTIMATOR_MISSING_IMU_DATA;
    case 245:
    case "GIMBAL_ESTIMATOR_ACCEL_NORM_TOO_HIGH":
      return VehicleFault_Enum.GIMBAL_ESTIMATOR_ACCEL_NORM_TOO_HIGH;
    case 246:
    case "GIMBAL_ESTIMATOR_NEEDS_INITIALIZER":
      return VehicleFault_Enum.GIMBAL_ESTIMATOR_NEEDS_INITIALIZER;
    case 247:
    case "GIMBAL_ESTIMATOR_INSUFFICIENT_TIME":
      return VehicleFault_Enum.GIMBAL_ESTIMATOR_INSUFFICIENT_TIME;
    case 269:
    case "GIMBAL_ESTIMATOR_COMPUTING_MOTOR_ANGLES":
      return VehicleFault_Enum.GIMBAL_ESTIMATOR_COMPUTING_MOTOR_ANGLES;
    case 35:
    case "GIMBAL_UNRELIABLE":
      return VehicleFault_Enum.GIMBAL_UNRELIABLE;
    case 88:
    case "FALCON_HOOD":
      return VehicleFault_Enum.FALCON_HOOD;
    case 89:
    case "GIMBAL_OBSTRUCTED":
      return VehicleFault_Enum.GIMBAL_OBSTRUCTED;
    case 102:
    case "GIMBAL_FREAK_OUT":
      return VehicleFault_Enum.GIMBAL_FREAK_OUT;
    case 251:
    case "GIMBAL_EMBEDDED_ESTIMATOR_NOT_INITIALIZED":
      return VehicleFault_Enum.GIMBAL_EMBEDDED_ESTIMATOR_NOT_INITIALIZED;
    case 252:
    case "GIMBAL_EMBEDDED_ESTIMATOR_STALE_REFERENCE":
      return VehicleFault_Enum.GIMBAL_EMBEDDED_ESTIMATOR_STALE_REFERENCE;
    case 94:
    case "GIMBAL_VISION_ESTIMATOR_UNRELIABLE":
      return VehicleFault_Enum.GIMBAL_VISION_ESTIMATOR_UNRELIABLE;
    case 372:
    case "GIMBAL_FLASHLIGHT_TOO_HOT":
      return VehicleFault_Enum.GIMBAL_FLASHLIGHT_TOO_HOT;
    case 95:
    case "EXTERNAL_TORQUE_NORM_TOO_HIGH":
      return VehicleFault_Enum.EXTERNAL_TORQUE_NORM_TOO_HIGH;
    case 96:
    case "ROTOR_SPEED_CONTROL_DIVERGED":
      return VehicleFault_Enum.ROTOR_SPEED_CONTROL_DIVERGED;
    case 99:
    case "AUTOEXPOSURE_HAS_NOT_CONVERGED":
      return VehicleFault_Enum.AUTOEXPOSURE_HAS_NOT_CONVERGED;
    case 100:
    case "MEDIA_TRANSCODE_POSSIBLE":
      return VehicleFault_Enum.MEDIA_TRANSCODE_POSSIBLE;
    case 106:
    case "NOT_ACHIEVING_MOVEMENT_COMMAND":
      return VehicleFault_Enum.NOT_ACHIEVING_MOVEMENT_COMMAND;
    case 107:
    case "CONTACT":
      return VehicleFault_Enum.CONTACT;
    case 108:
    case "BATTERY_TOO_HOT_WARNING":
      return VehicleFault_Enum.BATTERY_TOO_HOT_WARNING;
    case 109:
    case "BATTERY_TOO_HOT_CRITICAL":
      return VehicleFault_Enum.BATTERY_TOO_HOT_CRITICAL;
    case 302:
    case "BATTERY_NOT_CHARGING_TOO_HOT":
      return VehicleFault_Enum.BATTERY_NOT_CHARGING_TOO_HOT;
    case 303:
    case "BATTERY_NOT_CHARGING_TOO_COLD":
      return VehicleFault_Enum.BATTERY_NOT_CHARGING_TOO_COLD;
    case 112:
    case "MEMORY_LOW":
      return VehicleFault_Enum.MEMORY_LOW;
    case 113:
    case "TOO_MANY_PAGE_FAULTS":
      return VehicleFault_Enum.TOO_MANY_PAGE_FAULTS;
    case 114:
    case "BATTERY_GAS_GAUGE_ERROR":
      return VehicleFault_Enum.BATTERY_GAS_GAUGE_ERROR;
    case 350:
    case "BATTERY_GAS_GAUGE_VERSION_MISMATCH":
      return VehicleFault_Enum.BATTERY_GAS_GAUGE_VERSION_MISMATCH;
    case 115:
    case "VEHICLE_IN_HAND":
      return VehicleFault_Enum.VEHICLE_IN_HAND;
    case 119:
    case "FLIGHT_LOGS_NOT_MOUNTED":
      return VehicleFault_Enum.FLIGHT_LOGS_NOT_MOUNTED;
    case 120:
    case "MOTOR_SPINUP_FAILURE":
      return VehicleFault_Enum.MOTOR_SPINUP_FAILURE;
    case 122:
    case "MEDIA_SD_MISSING":
      return VehicleFault_Enum.MEDIA_SD_MISSING;
    case 124:
    case "MEDIA_SD_UNFORMATTED":
      return VehicleFault_Enum.MEDIA_SD_UNFORMATTED;
    case 131:
    case "MEDIA_SD_CORRUPTED":
      return VehicleFault_Enum.MEDIA_SD_CORRUPTED;
    case 126:
    case "MEDIA_SD_SLOW":
      return VehicleFault_Enum.MEDIA_SD_SLOW;
    case 50:
    case "MEDIA_SPACE_LOW":
      return VehicleFault_Enum.MEDIA_SPACE_LOW;
    case 55:
    case "MEDIA_SPACE_CRITICAL":
      return VehicleFault_Enum.MEDIA_SPACE_CRITICAL;
    case 294:
    case "MEDIA_SPACE_FULL":
      return VehicleFault_Enum.MEDIA_SPACE_FULL;
    case 312:
    case "INSUFFICIENT_MEDIA_SD_STORAGE_FOR_MISSION":
      return VehicleFault_Enum.INSUFFICIENT_MEDIA_SD_STORAGE_FOR_MISSION;
    case 224:
    case "MEDIA_SD_NEEDS_TO_AUTODELETE":
      return VehicleFault_Enum.MEDIA_SD_NEEDS_TO_AUTODELETE;
    case 226:
    case "MEDIA_SD_WRONG_ENCRYPTION_KEY":
      return VehicleFault_Enum.MEDIA_SD_WRONG_ENCRYPTION_KEY;
    case 123:
    case "MEDIA_SD_MISSING_NOT_DISMISSED":
      return VehicleFault_Enum.MEDIA_SD_MISSING_NOT_DISMISSED;
    case 125:
    case "MEDIA_SD_UNFORMATTED_NOT_DISMISSED":
      return VehicleFault_Enum.MEDIA_SD_UNFORMATTED_NOT_DISMISSED;
    case 132:
    case "MEDIA_SD_CORRUPTED_NOT_DISMISSED":
      return VehicleFault_Enum.MEDIA_SD_CORRUPTED_NOT_DISMISSED;
    case 127:
    case "MEDIA_SD_SLOW_NOT_DISMISSED":
      return VehicleFault_Enum.MEDIA_SD_SLOW_NOT_DISMISSED;
    case 128:
    case "MEDIA_SPACE_LOW_NOT_DISMISSED":
      return VehicleFault_Enum.MEDIA_SPACE_LOW_NOT_DISMISSED;
    case 298:
    case "MEDIA_SD_LOADING_DTED_FILES":
      return VehicleFault_Enum.MEDIA_SD_LOADING_DTED_FILES;
    case 193:
    case "LOGGING_SD_MISSING":
      return VehicleFault_Enum.LOGGING_SD_MISSING;
    case 151:
    case "LOGGING_SD_WRONG_FILESYSTEM":
      return VehicleFault_Enum.LOGGING_SD_WRONG_FILESYSTEM;
    case 152:
    case "LOGGING_SD_WRONG_FILESYSTEM_IN_USE":
      return VehicleFault_Enum.LOGGING_SD_WRONG_FILESYSTEM_IN_USE;
    case 222:
    case "LOGGING_SD_SLOW":
      return VehicleFault_Enum.LOGGING_SD_SLOW;
    case 225:
    case "LOGGING_SD_NOT_ENCRYPTED":
      return VehicleFault_Enum.LOGGING_SD_NOT_ENCRYPTED;
    case 232:
    case "LOGGING_SD_LOCKED":
      return VehicleFault_Enum.LOGGING_SD_LOCKED;
    case 233:
    case "LOGGING_SD_SECURITY_KEY_REQUIRED":
      return VehicleFault_Enum.LOGGING_SD_SECURITY_KEY_REQUIRED;
    case 234:
    case "LOGGING_SD_SECURITY_ACTIVATION_IN_PROGRESS":
      return VehicleFault_Enum.LOGGING_SD_SECURITY_ACTIVATION_IN_PROGRESS;
    case 235:
    case "LOGGING_SD_SECURITY_ACTIVATION_FAILED":
      return VehicleFault_Enum.LOGGING_SD_SECURITY_ACTIVATION_FAILED;
    case 1:
    case "BATTERY_CRITICAL":
      return VehicleFault_Enum.BATTERY_CRITICAL;
    case 365:
    case "BATTERY_CRITICAL_FORCE_FAST_DESCENT":
      return VehicleFault_Enum.BATTERY_CRITICAL_FORCE_FAST_DESCENT;
    case 70:
    case "BATTERY_LOW":
      return VehicleFault_Enum.BATTERY_LOW;
    case 351:
    case "BATTERY_LOW_RETURN":
      return VehicleFault_Enum.BATTERY_LOW_RETURN;
    case 210:
    case "BATTERY_LOW_RETURN_TO_DOCK":
      return VehicleFault_Enum.BATTERY_LOW_RETURN_TO_DOCK;
    case 206:
    case "BATTERY_LOW_RETURN_TO_LOST_CONNECTION":
      return VehicleFault_Enum.BATTERY_LOW_RETURN_TO_LOST_CONNECTION;
    case 367:
    case "BATTERY_LOW_SAFE_LANDING_UNREACHABLE":
      return VehicleFault_Enum.BATTERY_LOW_SAFE_LANDING_UNREACHABLE;
    case 223:
    case "BATTERY_LOW_DISABLE_AUTONOMOUS":
      return VehicleFault_Enum.BATTERY_LOW_DISABLE_AUTONOMOUS;
    case 282:
    case "BATTERY_VOLTAGE_IMBALANCE":
      return VehicleFault_Enum.BATTERY_VOLTAGE_IMBALANCE;
    case 287:
    case "BATTERY_VOLTAGE_IMBALANCE_LAND":
      return VehicleFault_Enum.BATTERY_VOLTAGE_IMBALANCE_LAND;
    case 154:
    case "BATTERY_HIGH_CURRENT":
      return VehicleFault_Enum.BATTERY_HIGH_CURRENT;
    case 155:
    case "BATTERY_HIGH_CURRENT_CRITICAL":
      return VehicleFault_Enum.BATTERY_HIGH_CURRENT_CRITICAL;
    case 133:
    case "PAGE_ALLOCATION_FAILURE":
      return VehicleFault_Enum.PAGE_ALLOCATION_FAILURE;
    case 2:
    case "LOST_PHONE_COMM_SHORT":
      return VehicleFault_Enum.LOST_PHONE_COMM_SHORT;
    case 432:
    case "LOST_CONNECTION_RETURN":
      return VehicleFault_Enum.LOST_CONNECTION_RETURN;
    case 139:
    case "LOST_CONNECTION_RTH":
      return VehicleFault_Enum.LOST_CONNECTION_RTH;
    case 209:
    case "LOST_CONNECTION_RTD":
      return VehicleFault_Enum.LOST_CONNECTION_RTD;
    case 140:
    case "LOST_CONNECTION_LAND":
      return VehicleFault_Enum.LOST_CONNECTION_LAND;
    case 208:
    case "LOST_CONNECTION_STOP_TRACK":
      return VehicleFault_Enum.LOST_CONNECTION_STOP_TRACK;
    case 134:
    case "PHONE_GPS_QUALITY_INSUFFICIENT":
      return VehicleFault_Enum.PHONE_GPS_QUALITY_INSUFFICIENT;
    case 135:
    case "VEHICLE_GPS_QUALITY_INSUFFICIENT":
      return VehicleFault_Enum.VEHICLE_GPS_QUALITY_INSUFFICIENT;
    case 150:
    case "VEHICLE_GPS_QUALITY_BLOCKING":
      return VehicleFault_Enum.VEHICLE_GPS_QUALITY_BLOCKING;
    case 283:
    case "VEHICLE_GPS_INOPERATIVE":
      return VehicleFault_Enum.VEHICLE_GPS_INOPERATIVE;
    case 136:
    case "BEACON_GPS_QUALITY_BLOCKING":
      return VehicleFault_Enum.BEACON_GPS_QUALITY_BLOCKING;
    case 137:
    case "BEACON_NEEDS_ASSISTNOW":
      return VehicleFault_Enum.BEACON_NEEDS_ASSISTNOW;
    case 141:
    case "BEACON_GPS_QUALITY_INSUFFICIENT":
      return VehicleFault_Enum.BEACON_GPS_QUALITY_INSUFFICIENT;
    case 290:
    case "RID_BEACON_GPS_INOPERATIVE":
      return VehicleFault_Enum.RID_BEACON_GPS_INOPERATIVE;
    case 144:
    case "USER_CAMERA_MODE_SWITCH":
      return VehicleFault_Enum.USER_CAMERA_MODE_SWITCH;
    case 142:
    case "UNCANCELLABLE_LANDING":
      return VehicleFault_Enum.UNCANCELLABLE_LANDING;
    case 143:
    case "PILOT_STILL_WAITING_TO_FLY":
      return VehicleFault_Enum.PILOT_STILL_WAITING_TO_FLY;
    case 147:
    case "BEACON_UPDATE_IN_PROGRESS":
      return VehicleFault_Enum.BEACON_UPDATE_IN_PROGRESS;
    case 149:
    case "JOYSTICKS_UPDATE_IN_PROGRESS":
      return VehicleFault_Enum.JOYSTICKS_UPDATE_IN_PROGRESS;
    case 145:
    case "SHOULD_CONNECT_THROUGH_BEACON":
      return VehicleFault_Enum.SHOULD_CONNECT_THROUGH_BEACON;
    case 146:
    case "SHOULD_CONNECT_THROUGH_JOYSTICKS":
      return VehicleFault_Enum.SHOULD_CONNECT_THROUGH_JOYSTICKS;
    case 280:
    case "SHOULD_CONNECT_SKYSTICKS":
      return VehicleFault_Enum.SHOULD_CONNECT_SKYSTICKS;
    case 153:
    case "COMMANDING_BEYOND_SAFETY_HEIGHT":
      return VehicleFault_Enum.COMMANDING_BEYOND_SAFETY_HEIGHT;
    case 27:
    case "PHONE_BATTERY_LOW":
      return VehicleFault_Enum.PHONE_BATTERY_LOW;
    case 28:
    case "PHONE_BATTERY_CRITICAL":
      return VehicleFault_Enum.PHONE_BATTERY_CRITICAL;
    case 157:
    case "BEACON_BATTERY_LOW":
      return VehicleFault_Enum.BEACON_BATTERY_LOW;
    case 167:
    case "BEACON_BATTERY_CRITICAL":
      return VehicleFault_Enum.BEACON_BATTERY_CRITICAL;
    case 158:
    case "JOYSTICKS_BATTERY_LOW":
      return VehicleFault_Enum.JOYSTICKS_BATTERY_LOW;
    case 354:
    case "SKYSTICKS_BATTERY_LOW":
      return VehicleFault_Enum.SKYSTICKS_BATTERY_LOW;
    case 156:
    case "PHONE_BATTERY_LOW_NOT_DISMISSED":
      return VehicleFault_Enum.PHONE_BATTERY_LOW_NOT_DISMISSED;
    case 159:
    case "BEACON_BATTERY_LOW_NOT_DISMISSED":
      return VehicleFault_Enum.BEACON_BATTERY_LOW_NOT_DISMISSED;
    case 160:
    case "JOYSTICKS_BATTERY_LOW_NOT_DISMISSED":
      return VehicleFault_Enum.JOYSTICKS_BATTERY_LOW_NOT_DISMISSED;
    case 355:
    case "SKYSTICKS_BATTERY_LOW_NOT_DISMISSED":
      return VehicleFault_Enum.SKYSTICKS_BATTERY_LOW_NOT_DISMISSED;
    case 161:
    case "FLIGHT_DECK_OUT_OF_SYNC":
      return VehicleFault_Enum.FLIGHT_DECK_OUT_OF_SYNC;
    case 162:
    case "BEACON_UPDATE_READY":
      return VehicleFault_Enum.BEACON_UPDATE_READY;
    case 163:
    case "JOYSTICKS_UPDATE_READY":
      return VehicleFault_Enum.JOYSTICKS_UPDATE_READY;
    case 164:
    case "STUCK_SAFETY_HEIGHT":
      return VehicleFault_Enum.STUCK_SAFETY_HEIGHT;
    case 165:
    case "BEACON_GPS_QUALITY_INSUFFICIENT_TRACKING_PAUSED":
      return VehicleFault_Enum.BEACON_GPS_QUALITY_INSUFFICIENT_TRACKING_PAUSED;
    case 166:
    case "BEACON_GPS_QUALITY_INSUFFICIENT_WITH_VISUAL":
      return VehicleFault_Enum.BEACON_GPS_QUALITY_INSUFFICIENT_WITH_VISUAL;
    case 168:
    case "MIND_THE_GAP":
      return VehicleFault_Enum.MIND_THE_GAP;
    case 169:
    case "MIND_THE_STUCK_SAFETY_HEIGHT":
      return VehicleFault_Enum.MIND_THE_STUCK_SAFETY_HEIGHT;
    case 53:
    case "BATTERY_MESSAGES_UNRELIABLE":
      return VehicleFault_Enum.BATTERY_MESSAGES_UNRELIABLE;
    case 170:
    case "BATTERY_RESET_REQUIRED":
      return VehicleFault_Enum.BATTERY_RESET_REQUIRED;
    case 171:
    case "TEGRA_REBOOTED_IN_FLIGHT":
      return VehicleFault_Enum.TEGRA_REBOOTED_IN_FLIGHT;
    case 313:
    case "MAYBE_TEGRA_REBOOTED_IN_FLIGHT":
      return VehicleFault_Enum.MAYBE_TEGRA_REBOOTED_IN_FLIGHT;
    case 329:
    case "BACKTRACK_UNAVAILABLE_TEGRA_REBOOTED":
      return VehicleFault_Enum.BACKTRACK_UNAVAILABLE_TEGRA_REBOOTED;
    case 29:
    case "TOO_DARK_CRITICAL":
      return VehicleFault_Enum.TOO_DARK_CRITICAL;
    case 61:
    case "TOO_DARK_HOVER":
      return VehicleFault_Enum.TOO_DARK_HOVER;
    case 62:
    case "TOO_DARK_WARNING":
      return VehicleFault_Enum.TOO_DARK_WARNING;
    case 172:
    case "TOO_DARK_FOR_VISION":
      return VehicleFault_Enum.TOO_DARK_FOR_VISION;
    case 378:
    case "TOO_DARK_TRY_NIGHT_AUTONOMY":
      return VehicleFault_Enum.TOO_DARK_TRY_NIGHT_AUTONOMY;
    case 174:
    case "TAKEOFF_NEEDS_MOTION":
      return VehicleFault_Enum.TAKEOFF_NEEDS_MOTION;
    case 253:
    case "HAND_WAVE_CAL_NEEDS_STOP_WAVING":
      return VehicleFault_Enum.HAND_WAVE_CAL_NEEDS_STOP_WAVING;
    case 260:
    case "HAND_WAVE_UNAVAILABLE":
      return VehicleFault_Enum.HAND_WAVE_UNAVAILABLE;
    case 175:
    case "TAKEOFF_BLOCKED_BY_POSE_POLISHER":
      return VehicleFault_Enum.TAKEOFF_BLOCKED_BY_POSE_POLISHER;
    case 21:
    case "NOT_STABLE_FOR_TAKEOFF":
      return VehicleFault_Enum.NOT_STABLE_FOR_TAKEOFF;
    case 22:
    case "TOO_FAST_FOR_TAKEOFF":
      return VehicleFault_Enum.TOO_FAST_FOR_TAKEOFF;
    case 176:
    case "TOO_FAST_FOR_TAKEOFF_NIGHT":
      return VehicleFault_Enum.TOO_FAST_FOR_TAKEOFF_NIGHT;
    case 177:
    case "NOT_STABLE_FOR_TAKEOFF_NIGHT":
      return VehicleFault_Enum.NOT_STABLE_FOR_TAKEOFF_NIGHT;
    case 258:
    case "TOO_FAST_FOR_TAKEOFF_VESSEL":
      return VehicleFault_Enum.TOO_FAST_FOR_TAKEOFF_VESSEL;
    case 259:
    case "NOT_STABLE_FOR_TAKEOFF_VESSEL":
      return VehicleFault_Enum.NOT_STABLE_FOR_TAKEOFF_VESSEL;
    case 179:
    case "TEGRA_REQUIRES_EMERGENCY_LAND":
      return VehicleFault_Enum.TEGRA_REQUIRES_EMERGENCY_LAND;
    case 368:
    case "IS_FIRST_PILOTED_EMERGENCY_LAND":
      return VehicleFault_Enum.IS_FIRST_PILOTED_EMERGENCY_LAND;
    case 299:
    case "IS_PILOTED_EMERGENCY_LAND":
      return VehicleFault_Enum.IS_PILOTED_EMERGENCY_LAND;
    case 366:
    case "COMMANDED_EMERGENCY_LAND":
      return VehicleFault_Enum.COMMANDED_EMERGENCY_LAND;
    case 180:
    case "EMERGENCY_LAND_MOTOR_ARMING_COMMAND":
      return VehicleFault_Enum.EMERGENCY_LAND_MOTOR_ARMING_COMMAND;
    case 181:
    case "EMERGENCY_LAND_LOST_RC_LINK":
      return VehicleFault_Enum.EMERGENCY_LAND_LOST_RC_LINK;
    case 182:
    case "EMERGENCY_LAND_TEGRA_HEALTH_MONITOR_STALE":
      return VehicleFault_Enum.EMERGENCY_LAND_TEGRA_HEALTH_MONITOR_STALE;
    case 183:
    case "EMERGENCY_LAND_TEGRA_ATTITUDE_COMMAND_STALE":
      return VehicleFault_Enum.EMERGENCY_LAND_TEGRA_ATTITUDE_COMMAND_STALE;
    case 184:
    case "EMERGENCY_LAND_RC_EMERGENCY_LAND_COMMAND":
      return VehicleFault_Enum.EMERGENCY_LAND_RC_EMERGENCY_LAND_COMMAND;
    case 185:
    case "EMERGENCY_LAND_SYMPC_CONTROLLER_FAILED":
      return VehicleFault_Enum.EMERGENCY_LAND_SYMPC_CONTROLLER_FAILED;
    case 186:
    case "EMERGENCY_LAND_NO_STATE_ESTIMATE_AVAILABLE":
      return VehicleFault_Enum.EMERGENCY_LAND_NO_STATE_ESTIMATE_AVAILABLE;
    case 187:
    case "JOYSTICKS_INPUT_PRE_TAKEOFF":
      return VehicleFault_Enum.JOYSTICKS_INPUT_PRE_TAKEOFF;
    case 219:
    case "TRIGGER_OR_ROLLER_INPUT_PRE_TAKEOFF":
      return VehicleFault_Enum.TRIGGER_OR_ROLLER_INPUT_PRE_TAKEOFF;
    case 386:
    case "JOYSTICKS_INPUT_ERRATIC":
      return VehicleFault_Enum.JOYSTICKS_INPUT_ERRATIC;
    case 188:
    case "MAGNETOMETER_NEEDS_CALIBRATION":
      return VehicleFault_Enum.MAGNETOMETER_NEEDS_CALIBRATION;
    case 250:
    case "MAGNETOMETER_NEEDS_GPS":
      return VehicleFault_Enum.MAGNETOMETER_NEEDS_GPS;
    case 423:
    case "ATTI_RTX_REQUIRES_MAG_CAL":
      return VehicleFault_Enum.ATTI_RTX_REQUIRES_MAG_CAL;
    case 425:
    case "ATTI_RTX_MAG_REQUIRES_LOCATION":
      return VehicleFault_Enum.ATTI_RTX_MAG_REQUIRES_LOCATION;
    case 190:
    case "USER_CAMERA_PHOTO_CAPTURE":
      return VehicleFault_Enum.USER_CAMERA_PHOTO_CAPTURE;
    case 191:
    case "COMMANDED_PAST_POOR_GPS_HEIGHT":
      return VehicleFault_Enum.COMMANDED_PAST_POOR_GPS_HEIGHT;
    case 192:
    case "COMMANDED_PAST_MAX_HEIGHT":
      return VehicleFault_Enum.COMMANDED_PAST_MAX_HEIGHT;
    case 229:
    case "COMMANDED_PAST_INDOOR_POOR_GPS_HEIGHT":
      return VehicleFault_Enum.COMMANDED_PAST_INDOOR_POOR_GPS_HEIGHT;
    case 415:
    case "COMMANDED_PAST_DENSITY_ALTITUDE":
      return VehicleFault_Enum.COMMANDED_PAST_DENSITY_ALTITUDE;
    case 227:
    case "COMMANDED_PAST_POOR_GPS_HEIGHT_NOT_DISMISSED":
      return VehicleFault_Enum.COMMANDED_PAST_POOR_GPS_HEIGHT_NOT_DISMISSED;
    case 228:
    case "COMMANDED_PAST_MAX_HEIGHT_NOT_DISMISSED":
      return VehicleFault_Enum.COMMANDED_PAST_MAX_HEIGHT_NOT_DISMISSED;
    case 230:
    case "COMMANDED_PAST_INDOOR_POOR_GPS_HEIGHT_NOT_DISMISSED":
      return VehicleFault_Enum.COMMANDED_PAST_INDOOR_POOR_GPS_HEIGHT_NOT_DISMISSED;
    case 276:
    case "AT_GEOFENCE_EDGE":
      return VehicleFault_Enum.AT_GEOFENCE_EDGE;
    case 277:
    case "AT_GEOFENCE_EDGE_NOT_DISMISSED":
      return VehicleFault_Enum.AT_GEOFENCE_EDGE_NOT_DISMISSED;
    case 278:
    case "RETURNING_TO_GEOFENCE":
      return VehicleFault_Enum.RETURNING_TO_GEOFENCE;
    case 279:
    case "RETURNING_TO_GEOFENCE_NOT_DISMISSED":
      return VehicleFault_Enum.RETURNING_TO_GEOFENCE_NOT_DISMISSED;
    case 308:
    case "FAR_OUTSIDE_GEOFENCE":
      return VehicleFault_Enum.FAR_OUTSIDE_GEOFENCE;
    case 318:
    case "GEOFENCE_UNRELIABLE":
      return VehicleFault_Enum.GEOFENCE_UNRELIABLE;
    case 194:
    case "VEHICLE_NEEDS_ASSISTNOW":
      return VehicleFault_Enum.VEHICLE_NEEDS_ASSISTNOW;
    case 195:
    case "SKILLS_LATENCY_HIGH":
      return VehicleFault_Enum.SKILLS_LATENCY_HIGH;
    case 197:
    case "DOCK_NOT_READY_FOR_TAKEOFF":
      return VehicleFault_Enum.DOCK_NOT_READY_FOR_TAKEOFF;
    case 199:
    case "WEBRTC_OFFLINE":
      return VehicleFault_Enum.WEBRTC_OFFLINE;
    case 200:
    case "DOCK_DISCONNECTED":
      return VehicleFault_Enum.DOCK_DISCONNECTED;
    case 201:
    case "DOCK_DETECTED":
      return VehicleFault_Enum.DOCK_DETECTED;
    case 306:
    case "VEHICLE_ON_SOME_TAKEOFF_SURFACE":
      return VehicleFault_Enum.VEHICLE_ON_SOME_TAKEOFF_SURFACE;
    case 309:
    case "DOCK_IN_INSPECTION_MODE":
      return VehicleFault_Enum.DOCK_IN_INSPECTION_MODE;
    case 202:
    case "DIGITAL_ZOOM_UNAVAILABLE":
      return VehicleFault_Enum.DIGITAL_ZOOM_UNAVAILABLE;
    case 203:
    case "SUPERZOOM_UNAVAILABLE_SUBJECT_TRACKING":
      return VehicleFault_Enum.SUPERZOOM_UNAVAILABLE_SUBJECT_TRACKING;
    case 204:
    case "MOTOR_CURRENT_WARNING":
      return VehicleFault_Enum.MOTOR_CURRENT_WARNING;
    case 205:
    case "MOTOR_CURRENT_CRITICAL":
      return VehicleFault_Enum.MOTOR_CURRENT_CRITICAL;
    case 429:
    case "MOTOR_IDLE_OVERCURRENT_CRITICAL":
      return VehicleFault_Enum.MOTOR_IDLE_OVERCURRENT_CRITICAL;
    case 442:
    case "MOTOR_IDLE_OVERCURRENT_WARNING":
      return VehicleFault_Enum.MOTOR_IDLE_OVERCURRENT_WARNING;
    case 207:
    case "AIRHAWK_ATTITUDE_CONTROL_REQ_RESTART":
      return VehicleFault_Enum.AIRHAWK_ATTITUDE_CONTROL_REQ_RESTART;
    case 211:
    case "DEFAULT_WIFI_AP_CREDENTIALS":
      return VehicleFault_Enum.DEFAULT_WIFI_AP_CREDENTIALS;
    case 212:
    case "IMPERFECT_CONTACT_WITH_DOCK":
      return VehicleFault_Enum.IMPERFECT_CONTACT_WITH_DOCK;
    case 217:
    case "LOST_CONNECTION_DROP_VIDEO":
      return VehicleFault_Enum.LOST_CONNECTION_DROP_VIDEO;
    case 218:
    case "ARM_IMPROPER_POSITION_VISION":
      return VehicleFault_Enum.ARM_IMPROPER_POSITION_VISION;
    case 301:
    case "ARM_IMPROPER_POSITION_CRITICAL":
      return VehicleFault_Enum.ARM_IMPROPER_POSITION_CRITICAL;
    case 304:
    case "ARM_IMPROPER_POSITION_VISION_BLOCK_TAKEOFF":
      return VehicleFault_Enum.ARM_IMPROPER_POSITION_VISION_BLOCK_TAKEOFF;
    case 220:
    case "VIO_CALIBRATIONS_NOT_CONVERGED":
      return VehicleFault_Enum.VIO_CALIBRATIONS_NOT_CONVERGED;
    case 221:
    case "AUTO_GPS_INITIALIZATION_FAILED":
      return VehicleFault_Enum.AUTO_GPS_INITIALIZATION_FAILED;
    case 231:
    case "BEACON_BARO_OFFSET_NEEDED":
      return VehicleFault_Enum.BEACON_BARO_OFFSET_NEEDED;
    case 261:
    case "DOCK_VEHICLE_PAIRING_MISMATCH":
      return VehicleFault_Enum.DOCK_VEHICLE_PAIRING_MISMATCH;
    case 255:
    case "RID_KEY_REQUIRED":
      return VehicleFault_Enum.RID_KEY_REQUIRED;
    case 256:
    case "MOTOR_VIBE_CHECK_PENDING":
      return VehicleFault_Enum.MOTOR_VIBE_CHECK_PENDING;
    case 257:
    case "EXCESSIVE_MOTOR_VIBE":
      return VehicleFault_Enum.EXCESSIVE_MOTOR_VIBE;
    case 262:
    case "MANUAL_MODE_RECOMMENDED":
      return VehicleFault_Enum.MANUAL_MODE_RECOMMENDED;
    case 263:
    case "RID_CHECK_STARTED":
      return VehicleFault_Enum.RID_CHECK_STARTED;
    case 264:
    case "WIFI_NO_CONFIGURED_SSIDS_FOUND":
      return VehicleFault_Enum.WIFI_NO_CONFIGURED_SSIDS_FOUND;
    case 265:
    case "WIFI_AUTHENTICATION_ERROR":
      return VehicleFault_Enum.WIFI_AUTHENTICATION_ERROR;
    case 266:
    case "WIFI_CONNECTION_TIMEOUT":
      return VehicleFault_Enum.WIFI_CONNECTION_TIMEOUT;
    case 267:
    case "CONTROLLER_GPS_QUALITY_INSUFFICIENT":
      return VehicleFault_Enum.CONTROLLER_GPS_QUALITY_INSUFFICIENT;
    case 291:
    case "RID_CONTROLLER_GPS_INOPERATIVE":
      return VehicleFault_Enum.RID_CONTROLLER_GPS_INOPERATIVE;
    case 307:
    case "RID_RPIC_GPS_INOPERATIVE":
      return VehicleFault_Enum.RID_RPIC_GPS_INOPERATIVE;
    case 305:
    case "DOCK_CRADLE_EXTENDED_PRE_TAKEOFF":
      return VehicleFault_Enum.DOCK_CRADLE_EXTENDED_PRE_TAKEOFF;
    case 445:
    case "IS_BASESTATION_TAKEOFF_SURFACE":
      return VehicleFault_Enum.IS_BASESTATION_TAKEOFF_SURFACE;
    case 281:
    case "IS_BASESTATION_LANDING_SURFACE":
      return VehicleFault_Enum.IS_BASESTATION_LANDING_SURFACE;
    case 296:
    case "LANDED_OUTSIDE_DOCK":
      return VehicleFault_Enum.LANDED_OUTSIDE_DOCK;
    case 268:
    case "AIRHAWK_RT_LOSS":
      return VehicleFault_Enum.AIRHAWK_RT_LOSS;
    case 270:
    case "LIVEKIT_NO_CONFIG":
      return VehicleFault_Enum.LIVEKIT_NO_CONFIG;
    case 271:
    case "LIVEKIT_CONFIG_MISMATCH":
      return VehicleFault_Enum.LIVEKIT_CONFIG_MISMATCH;
    case 272:
    case "LIVEKIT_NOT_ENABLED":
      return VehicleFault_Enum.LIVEKIT_NOT_ENABLED;
    case 273:
    case "LIVEKIT_CONNECTED":
      return VehicleFault_Enum.LIVEKIT_CONNECTED;
    case 274:
    case "LIVEKIT_CONNECTION_FAILED":
      return VehicleFault_Enum.LIVEKIT_CONNECTION_FAILED;
    case 285:
    case "VEHICLE_GPS_QUALITY_BLOCKING_TWILIGHT":
      return VehicleFault_Enum.VEHICLE_GPS_QUALITY_BLOCKING_TWILIGHT;
    case 284:
    case "FLIGHT_TERMINATED":
      return VehicleFault_Enum.FLIGHT_TERMINATED;
    case 288:
    case "NAV_CAMERA_LOGGING_DEGRADED":
      return VehicleFault_Enum.NAV_CAMERA_LOGGING_DEGRADED;
    case 292:
    case "SKILLS_TASKS_PENDING":
      return VehicleFault_Enum.SKILLS_TASKS_PENDING;
    case 295:
    case "DOCK_COMMS_UNRELIABLE":
      return VehicleFault_Enum.DOCK_COMMS_UNRELIABLE;
    case 376:
    case "CLOUD_ENV_OUT_OF_SYNC":
      return VehicleFault_Enum.CLOUD_ENV_OUT_OF_SYNC;
    case 297:
    case "TRACK_TRAJECTORY_STUCK_ON_OBSTACLES":
      return VehicleFault_Enum.TRACK_TRAJECTORY_STUCK_ON_OBSTACLES;
    case 363:
    case "SKILLS_TASK_NEEDS_DECRYPTION":
      return VehicleFault_Enum.SKILLS_TASK_NEEDS_DECRYPTION;
    case 300:
    case "USER_CAMERA_SLOW_MODE_SWITCH":
      return VehicleFault_Enum.USER_CAMERA_SLOW_MODE_SWITCH;
    case 310:
    case "NVU_DISCONNECTED":
      return VehicleFault_Enum.NVU_DISCONNECTED;
    case 311:
    case "QCU_DISCONNECTED":
      return VehicleFault_Enum.QCU_DISCONNECTED;
    case 314:
    case "BARO_MEASUREMENT_UNRELIABLE":
      return VehicleFault_Enum.BARO_MEASUREMENT_UNRELIABLE;
    case 315:
    case "WAYPOINTS_UNRELIABLE":
      return VehicleFault_Enum.WAYPOINTS_UNRELIABLE;
    case 352:
    case "WAYPOINT_TASK_STUCK_ON_OBSTACLE":
      return VehicleFault_Enum.WAYPOINT_TASK_STUCK_ON_OBSTACLE;
    case 353:
    case "WAYPOINT_TASK_STUCK_ON_GEOFENCE":
      return VehicleFault_Enum.WAYPOINT_TASK_STUCK_ON_GEOFENCE;
    case 317:
    case "DOCK_CLIENT_UNRELIABLE":
      return VehicleFault_Enum.DOCK_CLIENT_UNRELIABLE;
    case 319:
    case "SPURIOUS_VOXELS_DAY":
      return VehicleFault_Enum.SPURIOUS_VOXELS_DAY;
    case 356:
    case "SPURIOUS_VOXELS_NIGHT":
      return VehicleFault_Enum.SPURIOUS_VOXELS_NIGHT;
    case 361:
    case "SPURIOUS_VOXELS_DEBUG":
      return VehicleFault_Enum.SPURIOUS_VOXELS_DEBUG;
    case 320:
    case "BAD_GPU_FREQUENCY_QCU":
      return VehicleFault_Enum.BAD_GPU_FREQUENCY_QCU;
    case 321:
    case "CPU_TOO_HIGH_QCU":
      return VehicleFault_Enum.CPU_TOO_HIGH_QCU;
    case 322:
    case "GPU_TOO_HIGH_QCU":
      return VehicleFault_Enum.GPU_TOO_HIGH_QCU;
    case 323:
    case "BASE_PROCESSES_STOPPED_QCU":
      return VehicleFault_Enum.BASE_PROCESSES_STOPPED_QCU;
    case 324:
    case "FLIGHT_PROCESSES_STOPPED_QCU":
      return VehicleFault_Enum.FLIGHT_PROCESSES_STOPPED_QCU;
    case 325:
    case "MEMORY_LOW_QCU":
      return VehicleFault_Enum.MEMORY_LOW_QCU;
    case 326:
    case "TOO_MANY_PAGE_FAULTS_QCU":
      return VehicleFault_Enum.TOO_MANY_PAGE_FAULTS_QCU;
    case 327:
    case "FLIGHT_DECK_OUT_OF_SYNC_QCU":
      return VehicleFault_Enum.FLIGHT_DECK_OUT_OF_SYNC_QCU;
    case 328:
    case "TOF_UNRELIABLE":
      return VehicleFault_Enum.TOF_UNRELIABLE;
    case 332:
    case "TOF_UNRELIABLE_FOR_FAST_DESCENT_LANDING":
      return VehicleFault_Enum.TOF_UNRELIABLE_FOR_FAST_DESCENT_LANDING;
    case 330:
    case "INFRARED_CAMERA_UNRELIABLE":
      return VehicleFault_Enum.INFRARED_CAMERA_UNRELIABLE;
    case 331:
    case "TOO_FAST_NIGHT_AUTONOMY":
      return VehicleFault_Enum.TOO_FAST_NIGHT_AUTONOMY;
    case 333:
    case "VEHICLE_RADIO_UPDATE_IN_PROGRESS":
      return VehicleFault_Enum.VEHICLE_RADIO_UPDATE_IN_PROGRESS;
    case 334:
    case "ATTACHMENT_CHANGE_DETECTED":
      return VehicleFault_Enum.ATTACHMENT_CHANGE_DETECTED;
    case 400:
    case "MISMATCHED_NIGHTSENSE_ATTACHMENT_DETECTED":
      return VehicleFault_Enum.MISMATCHED_NIGHTSENSE_ATTACHMENT_DETECTED;
    case 401:
    case "MIXED_NIGHTSENSE_ATTACHMENTS_DETECTED":
      return VehicleFault_Enum.MIXED_NIGHTSENSE_ATTACHMENTS_DETECTED;
    case 402:
    case "RTK_PPK_ATTACHMENT_CONNECTED":
      return VehicleFault_Enum.RTK_PPK_ATTACHMENT_CONNECTED;
    case 413:
    case "RTK_PPK_ATTACHMENT_CONNECTED_NOT_DISMISSED":
      return VehicleFault_Enum.RTK_PPK_ATTACHMENT_CONNECTED_NOT_DISMISSED;
    case 403:
    case "NIGHTSENSE_ATTACHMENT_DEGRADED":
      return VehicleFault_Enum.NIGHTSENSE_ATTACHMENT_DEGRADED;
    case 404:
    case "NIGHTSENSE_ATTACHMENT_DEGRADED_NOT_DISMISSED":
      return VehicleFault_Enum.NIGHTSENSE_ATTACHMENT_DEGRADED_NOT_DISMISSED;
    case 405:
    case "NIGHTSENSE_DISABLED_ATTACHMENT_FAILURE":
      return VehicleFault_Enum.NIGHTSENSE_DISABLED_ATTACHMENT_FAILURE;
    case 406:
    case "NIGHTSENSE_DISABLED_ATTACHMENT_FAILURE_NOT_DISMISSED":
      return VehicleFault_Enum.NIGHTSENSE_DISABLED_ATTACHMENT_FAILURE_NOT_DISMISSED;
    case 407:
    case "NIGHTSENSE_DISABLED_ATTACHMENT_DISCONNECTED":
      return VehicleFault_Enum.NIGHTSENSE_DISABLED_ATTACHMENT_DISCONNECTED;
    case 408:
    case "NIGHTSENSE_DISABLED_ATTACHMENT_DISCONNECTED_NOT_DISMISSED":
      return VehicleFault_Enum.NIGHTSENSE_DISABLED_ATTACHMENT_DISCONNECTED_NOT_DISMISSED;
    case 409:
    case "NIGHTSENSE_UNAVAILABLE_ATTACHMENT_MISSING":
      return VehicleFault_Enum.NIGHTSENSE_UNAVAILABLE_ATTACHMENT_MISSING;
    case 410:
    case "NIGHTSENSE_UNAVAILABLE_ATTACHMENT_MISSING_NOT_DISMISSED":
      return VehicleFault_Enum.NIGHTSENSE_UNAVAILABLE_ATTACHMENT_MISSING_NOT_DISMISSED;
    case 335:
    case "FLASH_DRIVE_REQUIRED":
      return VehicleFault_Enum.FLASH_DRIVE_REQUIRED;
    case 336:
    case "HOUSEKEEPER_MCU_VERSION_MISMATCH":
      return VehicleFault_Enum.HOUSEKEEPER_MCU_VERSION_MISMATCH;
    case 337:
    case "AIRHAWK_MCU_VERSION_MISMATCH":
      return VehicleFault_Enum.AIRHAWK_MCU_VERSION_MISMATCH;
    case 338:
    case "GIMBAL_MCU_VERSION_MISMATCH":
      return VehicleFault_Enum.GIMBAL_MCU_VERSION_MISMATCH;
    case 339:
    case "SOC_FLASHPACK_MISMATCH":
      return VehicleFault_Enum.SOC_FLASHPACK_MISMATCH;
    case 340:
    case "SOC_CONFIG_MISMATCH":
      return VehicleFault_Enum.SOC_CONFIG_MISMATCH;
    case 341:
    case "SOC_SERIAL_NUMBER_MISMATCH":
      return VehicleFault_Enum.SOC_SERIAL_NUMBER_MISMATCH;
    case 342:
    case "OTA_IN_PROGRESS":
      return VehicleFault_Enum.OTA_IN_PROGRESS;
    case 343:
    case "FIRMWARE_MISCONFIGURED":
      return VehicleFault_Enum.FIRMWARE_MISCONFIGURED;
    case 346:
    case "AIRHAWK_SERIAL_CONNECTION_LOST":
      return VehicleFault_Enum.AIRHAWK_SERIAL_CONNECTION_LOST;
    case 347:
    case "AIRHAWK_SERIAL_CONNECTION_LOST_QCU":
      return VehicleFault_Enum.AIRHAWK_SERIAL_CONNECTION_LOST_QCU;
    case 357:
    case "OBSTACLE_MARGINS_CANNOT_EXPAND":
      return VehicleFault_Enum.OBSTACLE_MARGINS_CANNOT_EXPAND;
    case 359:
    case "MOTOR_TAKEOFF_CURRENT_CRITICAL":
      return VehicleFault_Enum.MOTOR_TAKEOFF_CURRENT_CRITICAL;
    case 360:
    case "RELATIVE_HEADING_ESTIMATE_UNAVAILABLE":
      return VehicleFault_Enum.RELATIVE_HEADING_ESTIMATE_UNAVAILABLE;
    case 362:
    case "IMU_SATURATION_ON_TAKEOFF":
      return VehicleFault_Enum.IMU_SATURATION_ON_TAKEOFF;
    case 364:
    case "VEHICLE_GPS_RESET":
      return VehicleFault_Enum.VEHICLE_GPS_RESET;
    case 369:
    case "PRE_DOCK_TAKEOFF_VEHICLE_GPS_RESET_UNSUCCESSFUL":
      return VehicleFault_Enum.PRE_DOCK_TAKEOFF_VEHICLE_GPS_RESET_UNSUCCESSFUL;
    case 370:
    case "GIMBAL_FLASH_FAILED":
      return VehicleFault_Enum.GIMBAL_FLASH_FAILED;
    case 371:
    case "INFRARED_CAMERA_POOR_ACCURACY":
      return VehicleFault_Enum.INFRARED_CAMERA_POOR_ACCURACY;
    case 373:
    case "GIMBAL_TYPE_INCOMPATIBLE":
      return VehicleFault_Enum.GIMBAL_TYPE_INCOMPATIBLE;
    case 374:
    case "FRONT_UNRELIABLE":
      return VehicleFault_Enum.FRONT_UNRELIABLE;
    case 377:
    case "MOTOR_OSCILLATION_DETECTED":
      return VehicleFault_Enum.MOTOR_OSCILLATION_DETECTED;
    case 388:
    case "ATTACHMENT_OLD_SIDE_ATTACHMENT_HARDWARE":
      return VehicleFault_Enum.ATTACHMENT_OLD_SIDE_ATTACHMENT_HARDWARE;
    case 389:
    case "ATTACHMENT_WRONG_CABLE_ORIENTATION":
      return VehicleFault_Enum.ATTACHMENT_WRONG_CABLE_ORIENTATION;
    case 439:
    case "ATTACHMENT_ENUMERATION_IN_PROGRESS":
      return VehicleFault_Enum.ATTACHMENT_ENUMERATION_IN_PROGRESS;
    case 390:
    case "ATTACHMENT_IN_INVALID_PORT_TOP":
      return VehicleFault_Enum.ATTACHMENT_IN_INVALID_PORT_TOP;
    case 391:
    case "ATTACHMENT_IN_INVALID_PORT_BOTTOM":
      return VehicleFault_Enum.ATTACHMENT_IN_INVALID_PORT_BOTTOM;
    case 392:
    case "ATTACHMENT_IN_INVALID_PORT_LEFT":
      return VehicleFault_Enum.ATTACHMENT_IN_INVALID_PORT_LEFT;
    case 393:
    case "ATTACHMENT_IN_INVALID_PORT_RIGHT":
      return VehicleFault_Enum.ATTACHMENT_IN_INVALID_PORT_RIGHT;
    case 394:
    case "ATTACHMENT_INSPECT_OR_REPLACE_TOP":
      return VehicleFault_Enum.ATTACHMENT_INSPECT_OR_REPLACE_TOP;
    case 395:
    case "ATTACHMENT_INSPECT_OR_REPLACE_BOTTOM":
      return VehicleFault_Enum.ATTACHMENT_INSPECT_OR_REPLACE_BOTTOM;
    case 396:
    case "ATTACHMENT_INSPECT_OR_REPLACE_LEFT":
      return VehicleFault_Enum.ATTACHMENT_INSPECT_OR_REPLACE_LEFT;
    case 397:
    case "ATTACHMENT_INSPECT_OR_REPLACE_RIGHT":
      return VehicleFault_Enum.ATTACHMENT_INSPECT_OR_REPLACE_RIGHT;
    case 379:
    case "ATTACHMENT_NIGHTSENSE_VERSION_MISMATCH":
      return VehicleFault_Enum.ATTACHMENT_NIGHTSENSE_VERSION_MISMATCH;
    case 384:
    case "ATTACHMENT_NIGHTSENSE_UPDATE_FAILED":
      return VehicleFault_Enum.ATTACHMENT_NIGHTSENSE_UPDATE_FAILED;
    case 385:
    case "ATTACHMENT_NIGHTSENSE_UPDATE_FAILED_NOT_DISMISSED":
      return VehicleFault_Enum.ATTACHMENT_NIGHTSENSE_UPDATE_FAILED_NOT_DISMISSED;
    case 398:
    case "ATTACHMENT_SPOTLIGHT_BRIGHTNESS_THROTTLING":
      return VehicleFault_Enum.ATTACHMENT_SPOTLIGHT_BRIGHTNESS_THROTTLING;
    case 416:
    case "ATTACHMENT_SPOTLIGHT_THERMAL_SHUTDOWN":
      return VehicleFault_Enum.ATTACHMENT_SPOTLIGHT_THERMAL_SHUTDOWN;
    case 399:
    case "ATTACHMENT_SPOTLIGHT_ABOVE_TOUCH_TEMP":
      return VehicleFault_Enum.ATTACHMENT_SPOTLIGHT_ABOVE_TOUCH_TEMP;
    case 441:
    case "ATTACHMENT_PARACHUTE_DEPLOYED":
      return VehicleFault_Enum.ATTACHMENT_PARACHUTE_DEPLOYED;
    case 422:
    case "ATTACHMENT_RTK_COM_FAILURE":
      return VehicleFault_Enum.ATTACHMENT_RTK_COM_FAILURE;
    case 440:
    case "ATTACHMENT_SPEAKERMIC_LOW_VOLUME":
      return VehicleFault_Enum.ATTACHMENT_SPEAKERMIC_LOW_VOLUME;
    case 417:
    case "ATTACHMENT_SPOTLIGHT_TWO_SPOTLIGHTS_UNSUPPORTED":
      return VehicleFault_Enum.ATTACHMENT_SPOTLIGHT_TWO_SPOTLIGHTS_UNSUPPORTED;
    case 427:
    case "ATTACHMENT_INVALID_COMBINATION":
      return VehicleFault_Enum.ATTACHMENT_INVALID_COMBINATION;
    case 380:
    case "RID_TX_FAILURE":
      return VehicleFault_Enum.RID_TX_FAILURE;
    case 435:
    case "PINE_HIGH_INTERFERENCE":
      return VehicleFault_Enum.PINE_HIGH_INTERFERENCE;
    case 436:
    case "PINE_SU_PKTS_IN_OFDMA":
      return VehicleFault_Enum.PINE_SU_PKTS_IN_OFDMA;
    case 382:
    case "NCPG_SAVE_IN_PROGRESS":
      return VehicleFault_Enum.NCPG_SAVE_IN_PROGRESS;
    case 383:
    case "MISSION_CONDUCTOR_DISABLE_PILOTSHIP":
      return VehicleFault_Enum.MISSION_CONDUCTOR_DISABLE_PILOTSHIP;
    case 387:
    case "GROUND_AVOIDANCE_ACTIVE":
      return VehicleFault_Enum.GROUND_AVOIDANCE_ACTIVE;
    case 411:
    case "GPS_SPOOFED":
      return VehicleFault_Enum.GPS_SPOOFED;
    case 412:
    case "EVENT_LOOP_FD_OVERFLOW":
      return VehicleFault_Enum.EVENT_LOOP_FD_OVERFLOW;
    case 414:
    case "MICROHARD_DCS_JAMMING_FAULT":
      return VehicleFault_Enum.MICROHARD_DCS_JAMMING_FAULT;
    case 418:
    case "BATTERY_NOT_ARMED":
      return VehicleFault_Enum.BATTERY_NOT_ARMED;
    case 419:
    case "VEHICLE_TOO_HOT_CRITICAL_FINAL":
      return VehicleFault_Enum.VEHICLE_TOO_HOT_CRITICAL_FINAL;
    case 424:
    case "IMU_NEAR_SATURATION":
      return VehicleFault_Enum.IMU_NEAR_SATURATION;
    case 426:
    case "ATTI_TAKEOFF_AWAITING_USER_INPUT":
      return VehicleFault_Enum.ATTI_TAKEOFF_AWAITING_USER_INPUT;
    case 430:
    case "VEHICLE_CLOCK_STALE":
      return VehicleFault_Enum.VEHICLE_CLOCK_STALE;
    case 431:
    case "VEHICLE_CLOCK_STALE_NOT_DISMISSED":
      return VehicleFault_Enum.VEHICLE_CLOCK_STALE_NOT_DISMISSED;
    case 433:
    case "FLIR_FIRMWARE_UPDATE_IN_PROGRESS":
      return VehicleFault_Enum.FLIR_FIRMWARE_UPDATE_IN_PROGRESS;
    case 434:
    case "FLIR_FIRMWARE_VERSION_MISMATCH":
      return VehicleFault_Enum.FLIR_FIRMWARE_VERSION_MISMATCH;
    case 443:
    case "NAV_FRAME_UNRELIABLE":
      return VehicleFault_Enum.NAV_FRAME_UNRELIABLE;
    case 444:
    case "MISSION_NOT_READY_FOR_TAKEOFF":
      return VehicleFault_Enum.MISSION_NOT_READY_FOR_TAKEOFF;
    case 446:
    case "UNEXPECTED_OBSTACLES_HIGH_SPEED":
      return VehicleFault_Enum.UNEXPECTED_OBSTACLES_HIGH_SPEED;
    case 447:
    case "APPROACHING_GEOAWARENESS_ZONE":
      return VehicleFault_Enum.APPROACHING_GEOAWARENESS_ZONE;
    case 448:
    case "ENTERED_GEOAWARENESS_ZONE":
      return VehicleFault_Enum.ENTERED_GEOAWARENESS_ZONE;
    case 449:
    case "GEOAWARENESS_UNAVAILABLE_FILE":
      return VehicleFault_Enum.GEOAWARENESS_UNAVAILABLE_FILE;
    case 450:
    case "GEOAWARENESS_UNAVAILABLE_GPS":
      return VehicleFault_Enum.GEOAWARENESS_UNAVAILABLE_GPS;
    case 451:
    case "YIELD_FOR_NEARBY_DRONES":
      return VehicleFault_Enum.YIELD_FOR_NEARBY_DRONES;
    case 452:
    case "AWAITING_CLOUD_CONFIG_UPDATE":
      return VehicleFault_Enum.AWAITING_CLOUD_CONFIG_UPDATE;
    case 500:
    case "MAX_NUM_FAULTS":
      return VehicleFault_Enum.MAX_NUM_FAULTS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return VehicleFault_Enum.UNRECOGNIZED;
  }
}

export function vehicleFault_EnumToJSON(object: VehicleFault_Enum): string {
  switch (object) {
    case VehicleFault_Enum.INVALID:
      return "INVALID";
    case VehicleFault_Enum.TASK_QUEUE_RUNNING:
      return "TASK_QUEUE_RUNNING";
    case VehicleFault_Enum.LIGHTING_ATTACHMENT_DISCONNECTED:
      return "LIGHTING_ATTACHMENT_DISCONNECTED";
    case VehicleFault_Enum.BATTERY_LOW_LAND:
      return "BATTERY_LOW_LAND";
    case VehicleFault_Enum.PLANNER_UNRELIABLE:
      return "PLANNER_UNRELIABLE";
    case VehicleFault_Enum.POSE_POLISHER_UNRELIABLE:
      return "POSE_POLISHER_UNRELIABLE";
    case VehicleFault_Enum.NIGHT_MODE_HEADING_UNINITIALIZED:
      return "NIGHT_MODE_HEADING_UNINITIALIZED";
    case VehicleFault_Enum.VIO_UNRELIABLE:
      return "VIO_UNRELIABLE";
    case VehicleFault_Enum.VIO_DEGRADED:
      return "VIO_DEGRADED";
    case VehicleFault_Enum.GPS_DEGRADED:
      return "GPS_DEGRADED";
    case VehicleFault_Enum.VIO_UNRELIABLE_REDUCED_OBSTACLES:
      return "VIO_UNRELIABLE_REDUCED_OBSTACLES";
    case VehicleFault_Enum.VIO_STATIC_INITIALIZATION_FAILED:
      return "VIO_STATIC_INITIALIZATION_FAILED";
    case VehicleFault_Enum.VEHICLE_UNCALIBRATED:
      return "VEHICLE_UNCALIBRATED";
    case VehicleFault_Enum.VOXELS_UNRELIABLE:
      return "VOXELS_UNRELIABLE";
    case VehicleFault_Enum.STEREO_UNRELIABLE:
      return "STEREO_UNRELIABLE";
    case VehicleFault_Enum.SUBJECT_UNRELIABLE:
      return "SUBJECT_UNRELIABLE";
    case VehicleFault_Enum.IGNORE_OBSTACLES:
      return "IGNORE_OBSTACLES";
    case VehicleFault_Enum.IGNORE_SOME_FAULTS:
      return "IGNORE_SOME_FAULTS";
    case VehicleFault_Enum.LOGGING_SPACE_LOW:
      return "LOGGING_SPACE_LOW";
    case VehicleFault_Enum.LOGGING_SPACE_CRITICAL:
      return "LOGGING_SPACE_CRITICAL";
    case VehicleFault_Enum.LOGGING_SPACE_LOW_BETA:
      return "LOGGING_SPACE_LOW_BETA";
    case VehicleFault_Enum.EMMC_SPACE_LOW:
      return "EMMC_SPACE_LOW";
    case VehicleFault_Enum.EMMC_SPACE_LOW_QCU:
      return "EMMC_SPACE_LOW_QCU";
    case VehicleFault_Enum.EMMC_SPACE_CRITICAL:
      return "EMMC_SPACE_CRITICAL";
    case VehicleFault_Enum.EMMC_SPACE_CRITICAL_QCU:
      return "EMMC_SPACE_CRITICAL_QCU";
    case VehicleFault_Enum.FLIGHT_LOGGER_UNRELIABLE:
      return "FLIGHT_LOGGER_UNRELIABLE";
    case VehicleFault_Enum.MOTOR_NOT_SPINNING:
      return "MOTOR_NOT_SPINNING";
    case VehicleFault_Enum.LOST_RC:
      return "LOST_RC";
    case VehicleFault_Enum.SUBJECT_CAMERA_UNRELIABLE:
      return "SUBJECT_CAMERA_UNRELIABLE";
    case VehicleFault_Enum.SUBJECT_CAMERA_STOPPED:
      return "SUBJECT_CAMERA_STOPPED";
    case VehicleFault_Enum.BASE_PROCESSES_STOPPED:
      return "BASE_PROCESSES_STOPPED";
    case VehicleFault_Enum.FLIGHT_PROCESSES_STOPPED:
      return "FLIGHT_PROCESSES_STOPPED";
    case VehicleFault_Enum.NOT_ARMED:
      return "NOT_ARMED";
    case VehicleFault_Enum.MOTOR_SYNC_LOST:
      return "MOTOR_SYNC_LOST";
    case VehicleFault_Enum.MISSING_IMAGES:
      return "MISSING_IMAGES";
    case VehicleFault_Enum.VEHICLE_STUCK:
      return "VEHICLE_STUCK";
    case VehicleFault_Enum.IMU_DRIVER_UNRELIABLE:
      return "IMU_DRIVER_UNRELIABLE";
    case VehicleFault_Enum.BARO_DRIVER_UNRELIABLE:
      return "BARO_DRIVER_UNRELIABLE";
    case VehicleFault_Enum.NAV_CAMERAS_UNRELIABLE:
      return "NAV_CAMERAS_UNRELIABLE";
    case VehicleFault_Enum.AMBARELLA_DRIVER_UNRELIABLE:
      return "AMBARELLA_DRIVER_UNRELIABLE";
    case VehicleFault_Enum.SKILLS_UNRELIABLE:
      return "SKILLS_UNRELIABLE";
    case VehicleFault_Enum.HAND_TAKEOFF_OBSTRUCTED:
      return "HAND_TAKEOFF_OBSTRUCTED";
    case VehicleFault_Enum.HAND_TAKEOFF_SHALLOW_OBSTRUCTED:
      return "HAND_TAKEOFF_SHALLOW_OBSTRUCTED";
    case VehicleFault_Enum.HAND_TAKEOFF_VERTICAL_OBSTRUCTED:
      return "HAND_TAKEOFF_VERTICAL_OBSTRUCTED";
    case VehicleFault_Enum.BAD_CPU_FREQUENCY:
      return "BAD_CPU_FREQUENCY";
    case VehicleFault_Enum.BAD_GPU_FREQUENCY:
      return "BAD_GPU_FREQUENCY";
    case VehicleFault_Enum.HIGH_WINDS_WARN:
      return "HIGH_WINDS_WARN";
    case VehicleFault_Enum.HIGH_WINDS_SEVERE:
      return "HIGH_WINDS_SEVERE";
    case VehicleFault_Enum.HIGH_WINDS_LAND:
      return "HIGH_WINDS_LAND";
    case VehicleFault_Enum.DIRTY_CAMERAS_LOW:
      return "DIRTY_CAMERAS_LOW";
    case VehicleFault_Enum.DIRTY_CAMERAS_HIGH:
      return "DIRTY_CAMERAS_HIGH";
    case VehicleFault_Enum.COLLISION:
      return "COLLISION";
    case VehicleFault_Enum.MOTOR_HOT_WARNING:
      return "MOTOR_HOT_WARNING";
    case VehicleFault_Enum.MOTOR_HOT_CRITICAL:
      return "MOTOR_HOT_CRITICAL";
    case VehicleFault_Enum.PLANNER_STATE_UNRELIABLE:
      return "PLANNER_STATE_UNRELIABLE";
    case VehicleFault_Enum.EXECUTIVE_MANAGER_UNRELIABLE:
      return "EXECUTIVE_MANAGER_UNRELIABLE";
    case VehicleFault_Enum.TASK_QUEUE_RUNNING_UNINTERRUPTIBLE:
      return "TASK_QUEUE_RUNNING_UNINTERRUPTIBLE";
    case VehicleFault_Enum.TASK_QUEUE_RUNNING_INTERRUPTIBLE:
      return "TASK_QUEUE_RUNNING_INTERRUPTIBLE";
    case VehicleFault_Enum.AMBARELLA_SD_STILL_MOUNTED:
      return "AMBARELLA_SD_STILL_MOUNTED";
    case VehicleFault_Enum.BATTERY_TOO_COLD:
      return "BATTERY_TOO_COLD";
    case VehicleFault_Enum.BATTERY_NEEDS_HEATING:
      return "BATTERY_NEEDS_HEATING";
    case VehicleFault_Enum.BATTERY_HEATER_ON:
      return "BATTERY_HEATER_ON";
    case VehicleFault_Enum.BATTERY_TOO_COLD_BUT_IS_HEATING:
      return "BATTERY_TOO_COLD_BUT_IS_HEATING";
    case VehicleFault_Enum.BATTERY_TOO_COLD_LIMP_OK:
      return "BATTERY_TOO_COLD_LIMP_OK";
    case VehicleFault_Enum.USBC_IN_USE:
      return "USBC_IN_USE";
    case VehicleFault_Enum.USBC_SECURITY_KEY_IN_USE:
      return "USBC_SECURITY_KEY_IN_USE";
    case VehicleFault_Enum.CPU_TOO_HIGH:
      return "CPU_TOO_HIGH";
    case VehicleFault_Enum.GPU_TOO_HIGH:
      return "GPU_TOO_HIGH";
    case VehicleFault_Enum.VEHICLE_TOO_HOT_CRITICAL:
      return "VEHICLE_TOO_HOT_CRITICAL";
    case VehicleFault_Enum.VEHICLE_TOO_HOT_WARNING:
      return "VEHICLE_TOO_HOT_WARNING";
    case VehicleFault_Enum.AMBARELLA_TOO_HOT:
      return "AMBARELLA_TOO_HOT";
    case VehicleFault_Enum.GPS_UNRELIABLE:
      return "GPS_UNRELIABLE";
    case VehicleFault_Enum.GPS_AWAITING_HEADING:
      return "GPS_AWAITING_HEADING";
    case VehicleFault_Enum.PRE_FLIGHT_TASKS_REQUIRED:
      return "PRE_FLIGHT_TASKS_REQUIRED";
    case VehicleFault_Enum.GAP_TOO_SMALL:
      return "GAP_TOO_SMALL";
    case VehicleFault_Enum.HAND_TAKEOFF_DANGEROUS:
      return "HAND_TAKEOFF_DANGEROUS";
    case VehicleFault_Enum.ENVIRONMENT_DANGEROUS:
      return "ENVIRONMENT_DANGEROUS";
    case VehicleFault_Enum.HAND_TAKEOFF_DANGEROUS_HIGH_GRADIENT:
      return "HAND_TAKEOFF_DANGEROUS_HIGH_GRADIENT";
    case VehicleFault_Enum.HAND_TAKEOFF_DANGEROUS_DARK:
      return "HAND_TAKEOFF_DANGEROUS_DARK";
    case VehicleFault_Enum.MOTOR_FLASH_IN_PROGRESS:
      return "MOTOR_FLASH_IN_PROGRESS";
    case VehicleFault_Enum.MOTOR_FLASH_FAILURE:
      return "MOTOR_FLASH_FAILURE";
    case VehicleFault_Enum.TEGRA_STATE_ESTIMATION_FAILED:
      return "TEGRA_STATE_ESTIMATION_FAILED";
    case VehicleFault_Enum.EMERGENCY_LANDING_AIRHAWK_ONLY:
      return "EMERGENCY_LANDING_AIRHAWK_ONLY";
    case VehicleFault_Enum.EMERGENCY_LANDING_TEGRA_POSES:
      return "EMERGENCY_LANDING_TEGRA_POSES";
    case VehicleFault_Enum.VELOCITY_CONTROL_DIVERGED:
      return "VELOCITY_CONTROL_DIVERGED";
    case VehicleFault_Enum.POSITION_CONTROL_DIVERGED:
      return "POSITION_CONTROL_DIVERGED";
    case VehicleFault_Enum.YAW_CONTROL_DIVERGED:
      return "YAW_CONTROL_DIVERGED";
    case VehicleFault_Enum.PILOT_NOT_READY_TO_FLY:
      return "PILOT_NOT_READY_TO_FLY";
    case VehicleFault_Enum.PILOT_WAITING_TO_FLY:
      return "PILOT_WAITING_TO_FLY";
    case VehicleFault_Enum.PILOT_WAITING_TO_BROWSE:
      return "PILOT_WAITING_TO_BROWSE";
    case VehicleFault_Enum.GPS_UNRELIABLE_HOVER:
      return "GPS_UNRELIABLE_HOVER";
    case VehicleFault_Enum.TEGRA_STATE_ESTIMATION_HOVER:
      return "TEGRA_STATE_ESTIMATION_HOVER";
    case VehicleFault_Enum.MOTOR_QUALITY:
      return "MOTOR_QUALITY";
    case VehicleFault_Enum.GIMBAL_IMU_UNRELIABLE:
      return "GIMBAL_IMU_UNRELIABLE";
    case VehicleFault_Enum.GIMBAL_UNCALIBRATED:
      return "GIMBAL_UNCALIBRATED";
    case VehicleFault_Enum.GIMBAL_CONTROLLER_UNRELIABLE:
      return "GIMBAL_CONTROLLER_UNRELIABLE";
    case VehicleFault_Enum.GIMBAL_ENCODER_UNRELIABLE:
      return "GIMBAL_ENCODER_UNRELIABLE";
    case VehicleFault_Enum.GIMBAL_ESTIMATOR_FAILED:
      return "GIMBAL_ESTIMATOR_FAILED";
    case VehicleFault_Enum.GIMBAL_ESTIMATOR_MISSING_IMU_DATA:
      return "GIMBAL_ESTIMATOR_MISSING_IMU_DATA";
    case VehicleFault_Enum.GIMBAL_ESTIMATOR_ACCEL_NORM_TOO_HIGH:
      return "GIMBAL_ESTIMATOR_ACCEL_NORM_TOO_HIGH";
    case VehicleFault_Enum.GIMBAL_ESTIMATOR_NEEDS_INITIALIZER:
      return "GIMBAL_ESTIMATOR_NEEDS_INITIALIZER";
    case VehicleFault_Enum.GIMBAL_ESTIMATOR_INSUFFICIENT_TIME:
      return "GIMBAL_ESTIMATOR_INSUFFICIENT_TIME";
    case VehicleFault_Enum.GIMBAL_ESTIMATOR_COMPUTING_MOTOR_ANGLES:
      return "GIMBAL_ESTIMATOR_COMPUTING_MOTOR_ANGLES";
    case VehicleFault_Enum.GIMBAL_UNRELIABLE:
      return "GIMBAL_UNRELIABLE";
    case VehicleFault_Enum.FALCON_HOOD:
      return "FALCON_HOOD";
    case VehicleFault_Enum.GIMBAL_OBSTRUCTED:
      return "GIMBAL_OBSTRUCTED";
    case VehicleFault_Enum.GIMBAL_FREAK_OUT:
      return "GIMBAL_FREAK_OUT";
    case VehicleFault_Enum.GIMBAL_EMBEDDED_ESTIMATOR_NOT_INITIALIZED:
      return "GIMBAL_EMBEDDED_ESTIMATOR_NOT_INITIALIZED";
    case VehicleFault_Enum.GIMBAL_EMBEDDED_ESTIMATOR_STALE_REFERENCE:
      return "GIMBAL_EMBEDDED_ESTIMATOR_STALE_REFERENCE";
    case VehicleFault_Enum.GIMBAL_VISION_ESTIMATOR_UNRELIABLE:
      return "GIMBAL_VISION_ESTIMATOR_UNRELIABLE";
    case VehicleFault_Enum.GIMBAL_FLASHLIGHT_TOO_HOT:
      return "GIMBAL_FLASHLIGHT_TOO_HOT";
    case VehicleFault_Enum.EXTERNAL_TORQUE_NORM_TOO_HIGH:
      return "EXTERNAL_TORQUE_NORM_TOO_HIGH";
    case VehicleFault_Enum.ROTOR_SPEED_CONTROL_DIVERGED:
      return "ROTOR_SPEED_CONTROL_DIVERGED";
    case VehicleFault_Enum.AUTOEXPOSURE_HAS_NOT_CONVERGED:
      return "AUTOEXPOSURE_HAS_NOT_CONVERGED";
    case VehicleFault_Enum.MEDIA_TRANSCODE_POSSIBLE:
      return "MEDIA_TRANSCODE_POSSIBLE";
    case VehicleFault_Enum.NOT_ACHIEVING_MOVEMENT_COMMAND:
      return "NOT_ACHIEVING_MOVEMENT_COMMAND";
    case VehicleFault_Enum.CONTACT:
      return "CONTACT";
    case VehicleFault_Enum.BATTERY_TOO_HOT_WARNING:
      return "BATTERY_TOO_HOT_WARNING";
    case VehicleFault_Enum.BATTERY_TOO_HOT_CRITICAL:
      return "BATTERY_TOO_HOT_CRITICAL";
    case VehicleFault_Enum.BATTERY_NOT_CHARGING_TOO_HOT:
      return "BATTERY_NOT_CHARGING_TOO_HOT";
    case VehicleFault_Enum.BATTERY_NOT_CHARGING_TOO_COLD:
      return "BATTERY_NOT_CHARGING_TOO_COLD";
    case VehicleFault_Enum.MEMORY_LOW:
      return "MEMORY_LOW";
    case VehicleFault_Enum.TOO_MANY_PAGE_FAULTS:
      return "TOO_MANY_PAGE_FAULTS";
    case VehicleFault_Enum.BATTERY_GAS_GAUGE_ERROR:
      return "BATTERY_GAS_GAUGE_ERROR";
    case VehicleFault_Enum.BATTERY_GAS_GAUGE_VERSION_MISMATCH:
      return "BATTERY_GAS_GAUGE_VERSION_MISMATCH";
    case VehicleFault_Enum.VEHICLE_IN_HAND:
      return "VEHICLE_IN_HAND";
    case VehicleFault_Enum.FLIGHT_LOGS_NOT_MOUNTED:
      return "FLIGHT_LOGS_NOT_MOUNTED";
    case VehicleFault_Enum.MOTOR_SPINUP_FAILURE:
      return "MOTOR_SPINUP_FAILURE";
    case VehicleFault_Enum.MEDIA_SD_MISSING:
      return "MEDIA_SD_MISSING";
    case VehicleFault_Enum.MEDIA_SD_UNFORMATTED:
      return "MEDIA_SD_UNFORMATTED";
    case VehicleFault_Enum.MEDIA_SD_CORRUPTED:
      return "MEDIA_SD_CORRUPTED";
    case VehicleFault_Enum.MEDIA_SD_SLOW:
      return "MEDIA_SD_SLOW";
    case VehicleFault_Enum.MEDIA_SPACE_LOW:
      return "MEDIA_SPACE_LOW";
    case VehicleFault_Enum.MEDIA_SPACE_CRITICAL:
      return "MEDIA_SPACE_CRITICAL";
    case VehicleFault_Enum.MEDIA_SPACE_FULL:
      return "MEDIA_SPACE_FULL";
    case VehicleFault_Enum.INSUFFICIENT_MEDIA_SD_STORAGE_FOR_MISSION:
      return "INSUFFICIENT_MEDIA_SD_STORAGE_FOR_MISSION";
    case VehicleFault_Enum.MEDIA_SD_NEEDS_TO_AUTODELETE:
      return "MEDIA_SD_NEEDS_TO_AUTODELETE";
    case VehicleFault_Enum.MEDIA_SD_WRONG_ENCRYPTION_KEY:
      return "MEDIA_SD_WRONG_ENCRYPTION_KEY";
    case VehicleFault_Enum.MEDIA_SD_MISSING_NOT_DISMISSED:
      return "MEDIA_SD_MISSING_NOT_DISMISSED";
    case VehicleFault_Enum.MEDIA_SD_UNFORMATTED_NOT_DISMISSED:
      return "MEDIA_SD_UNFORMATTED_NOT_DISMISSED";
    case VehicleFault_Enum.MEDIA_SD_CORRUPTED_NOT_DISMISSED:
      return "MEDIA_SD_CORRUPTED_NOT_DISMISSED";
    case VehicleFault_Enum.MEDIA_SD_SLOW_NOT_DISMISSED:
      return "MEDIA_SD_SLOW_NOT_DISMISSED";
    case VehicleFault_Enum.MEDIA_SPACE_LOW_NOT_DISMISSED:
      return "MEDIA_SPACE_LOW_NOT_DISMISSED";
    case VehicleFault_Enum.MEDIA_SD_LOADING_DTED_FILES:
      return "MEDIA_SD_LOADING_DTED_FILES";
    case VehicleFault_Enum.LOGGING_SD_MISSING:
      return "LOGGING_SD_MISSING";
    case VehicleFault_Enum.LOGGING_SD_WRONG_FILESYSTEM:
      return "LOGGING_SD_WRONG_FILESYSTEM";
    case VehicleFault_Enum.LOGGING_SD_WRONG_FILESYSTEM_IN_USE:
      return "LOGGING_SD_WRONG_FILESYSTEM_IN_USE";
    case VehicleFault_Enum.LOGGING_SD_SLOW:
      return "LOGGING_SD_SLOW";
    case VehicleFault_Enum.LOGGING_SD_NOT_ENCRYPTED:
      return "LOGGING_SD_NOT_ENCRYPTED";
    case VehicleFault_Enum.LOGGING_SD_LOCKED:
      return "LOGGING_SD_LOCKED";
    case VehicleFault_Enum.LOGGING_SD_SECURITY_KEY_REQUIRED:
      return "LOGGING_SD_SECURITY_KEY_REQUIRED";
    case VehicleFault_Enum.LOGGING_SD_SECURITY_ACTIVATION_IN_PROGRESS:
      return "LOGGING_SD_SECURITY_ACTIVATION_IN_PROGRESS";
    case VehicleFault_Enum.LOGGING_SD_SECURITY_ACTIVATION_FAILED:
      return "LOGGING_SD_SECURITY_ACTIVATION_FAILED";
    case VehicleFault_Enum.BATTERY_CRITICAL:
      return "BATTERY_CRITICAL";
    case VehicleFault_Enum.BATTERY_CRITICAL_FORCE_FAST_DESCENT:
      return "BATTERY_CRITICAL_FORCE_FAST_DESCENT";
    case VehicleFault_Enum.BATTERY_LOW:
      return "BATTERY_LOW";
    case VehicleFault_Enum.BATTERY_LOW_RETURN:
      return "BATTERY_LOW_RETURN";
    case VehicleFault_Enum.BATTERY_LOW_RETURN_TO_DOCK:
      return "BATTERY_LOW_RETURN_TO_DOCK";
    case VehicleFault_Enum.BATTERY_LOW_RETURN_TO_LOST_CONNECTION:
      return "BATTERY_LOW_RETURN_TO_LOST_CONNECTION";
    case VehicleFault_Enum.BATTERY_LOW_SAFE_LANDING_UNREACHABLE:
      return "BATTERY_LOW_SAFE_LANDING_UNREACHABLE";
    case VehicleFault_Enum.BATTERY_LOW_DISABLE_AUTONOMOUS:
      return "BATTERY_LOW_DISABLE_AUTONOMOUS";
    case VehicleFault_Enum.BATTERY_VOLTAGE_IMBALANCE:
      return "BATTERY_VOLTAGE_IMBALANCE";
    case VehicleFault_Enum.BATTERY_VOLTAGE_IMBALANCE_LAND:
      return "BATTERY_VOLTAGE_IMBALANCE_LAND";
    case VehicleFault_Enum.BATTERY_HIGH_CURRENT:
      return "BATTERY_HIGH_CURRENT";
    case VehicleFault_Enum.BATTERY_HIGH_CURRENT_CRITICAL:
      return "BATTERY_HIGH_CURRENT_CRITICAL";
    case VehicleFault_Enum.PAGE_ALLOCATION_FAILURE:
      return "PAGE_ALLOCATION_FAILURE";
    case VehicleFault_Enum.LOST_PHONE_COMM_SHORT:
      return "LOST_PHONE_COMM_SHORT";
    case VehicleFault_Enum.LOST_CONNECTION_RETURN:
      return "LOST_CONNECTION_RETURN";
    case VehicleFault_Enum.LOST_CONNECTION_RTH:
      return "LOST_CONNECTION_RTH";
    case VehicleFault_Enum.LOST_CONNECTION_RTD:
      return "LOST_CONNECTION_RTD";
    case VehicleFault_Enum.LOST_CONNECTION_LAND:
      return "LOST_CONNECTION_LAND";
    case VehicleFault_Enum.LOST_CONNECTION_STOP_TRACK:
      return "LOST_CONNECTION_STOP_TRACK";
    case VehicleFault_Enum.PHONE_GPS_QUALITY_INSUFFICIENT:
      return "PHONE_GPS_QUALITY_INSUFFICIENT";
    case VehicleFault_Enum.VEHICLE_GPS_QUALITY_INSUFFICIENT:
      return "VEHICLE_GPS_QUALITY_INSUFFICIENT";
    case VehicleFault_Enum.VEHICLE_GPS_QUALITY_BLOCKING:
      return "VEHICLE_GPS_QUALITY_BLOCKING";
    case VehicleFault_Enum.VEHICLE_GPS_INOPERATIVE:
      return "VEHICLE_GPS_INOPERATIVE";
    case VehicleFault_Enum.BEACON_GPS_QUALITY_BLOCKING:
      return "BEACON_GPS_QUALITY_BLOCKING";
    case VehicleFault_Enum.BEACON_NEEDS_ASSISTNOW:
      return "BEACON_NEEDS_ASSISTNOW";
    case VehicleFault_Enum.BEACON_GPS_QUALITY_INSUFFICIENT:
      return "BEACON_GPS_QUALITY_INSUFFICIENT";
    case VehicleFault_Enum.RID_BEACON_GPS_INOPERATIVE:
      return "RID_BEACON_GPS_INOPERATIVE";
    case VehicleFault_Enum.USER_CAMERA_MODE_SWITCH:
      return "USER_CAMERA_MODE_SWITCH";
    case VehicleFault_Enum.UNCANCELLABLE_LANDING:
      return "UNCANCELLABLE_LANDING";
    case VehicleFault_Enum.PILOT_STILL_WAITING_TO_FLY:
      return "PILOT_STILL_WAITING_TO_FLY";
    case VehicleFault_Enum.BEACON_UPDATE_IN_PROGRESS:
      return "BEACON_UPDATE_IN_PROGRESS";
    case VehicleFault_Enum.JOYSTICKS_UPDATE_IN_PROGRESS:
      return "JOYSTICKS_UPDATE_IN_PROGRESS";
    case VehicleFault_Enum.SHOULD_CONNECT_THROUGH_BEACON:
      return "SHOULD_CONNECT_THROUGH_BEACON";
    case VehicleFault_Enum.SHOULD_CONNECT_THROUGH_JOYSTICKS:
      return "SHOULD_CONNECT_THROUGH_JOYSTICKS";
    case VehicleFault_Enum.SHOULD_CONNECT_SKYSTICKS:
      return "SHOULD_CONNECT_SKYSTICKS";
    case VehicleFault_Enum.COMMANDING_BEYOND_SAFETY_HEIGHT:
      return "COMMANDING_BEYOND_SAFETY_HEIGHT";
    case VehicleFault_Enum.PHONE_BATTERY_LOW:
      return "PHONE_BATTERY_LOW";
    case VehicleFault_Enum.PHONE_BATTERY_CRITICAL:
      return "PHONE_BATTERY_CRITICAL";
    case VehicleFault_Enum.BEACON_BATTERY_LOW:
      return "BEACON_BATTERY_LOW";
    case VehicleFault_Enum.BEACON_BATTERY_CRITICAL:
      return "BEACON_BATTERY_CRITICAL";
    case VehicleFault_Enum.JOYSTICKS_BATTERY_LOW:
      return "JOYSTICKS_BATTERY_LOW";
    case VehicleFault_Enum.SKYSTICKS_BATTERY_LOW:
      return "SKYSTICKS_BATTERY_LOW";
    case VehicleFault_Enum.PHONE_BATTERY_LOW_NOT_DISMISSED:
      return "PHONE_BATTERY_LOW_NOT_DISMISSED";
    case VehicleFault_Enum.BEACON_BATTERY_LOW_NOT_DISMISSED:
      return "BEACON_BATTERY_LOW_NOT_DISMISSED";
    case VehicleFault_Enum.JOYSTICKS_BATTERY_LOW_NOT_DISMISSED:
      return "JOYSTICKS_BATTERY_LOW_NOT_DISMISSED";
    case VehicleFault_Enum.SKYSTICKS_BATTERY_LOW_NOT_DISMISSED:
      return "SKYSTICKS_BATTERY_LOW_NOT_DISMISSED";
    case VehicleFault_Enum.FLIGHT_DECK_OUT_OF_SYNC:
      return "FLIGHT_DECK_OUT_OF_SYNC";
    case VehicleFault_Enum.BEACON_UPDATE_READY:
      return "BEACON_UPDATE_READY";
    case VehicleFault_Enum.JOYSTICKS_UPDATE_READY:
      return "JOYSTICKS_UPDATE_READY";
    case VehicleFault_Enum.STUCK_SAFETY_HEIGHT:
      return "STUCK_SAFETY_HEIGHT";
    case VehicleFault_Enum.BEACON_GPS_QUALITY_INSUFFICIENT_TRACKING_PAUSED:
      return "BEACON_GPS_QUALITY_INSUFFICIENT_TRACKING_PAUSED";
    case VehicleFault_Enum.BEACON_GPS_QUALITY_INSUFFICIENT_WITH_VISUAL:
      return "BEACON_GPS_QUALITY_INSUFFICIENT_WITH_VISUAL";
    case VehicleFault_Enum.MIND_THE_GAP:
      return "MIND_THE_GAP";
    case VehicleFault_Enum.MIND_THE_STUCK_SAFETY_HEIGHT:
      return "MIND_THE_STUCK_SAFETY_HEIGHT";
    case VehicleFault_Enum.BATTERY_MESSAGES_UNRELIABLE:
      return "BATTERY_MESSAGES_UNRELIABLE";
    case VehicleFault_Enum.BATTERY_RESET_REQUIRED:
      return "BATTERY_RESET_REQUIRED";
    case VehicleFault_Enum.TEGRA_REBOOTED_IN_FLIGHT:
      return "TEGRA_REBOOTED_IN_FLIGHT";
    case VehicleFault_Enum.MAYBE_TEGRA_REBOOTED_IN_FLIGHT:
      return "MAYBE_TEGRA_REBOOTED_IN_FLIGHT";
    case VehicleFault_Enum.BACKTRACK_UNAVAILABLE_TEGRA_REBOOTED:
      return "BACKTRACK_UNAVAILABLE_TEGRA_REBOOTED";
    case VehicleFault_Enum.TOO_DARK_CRITICAL:
      return "TOO_DARK_CRITICAL";
    case VehicleFault_Enum.TOO_DARK_HOVER:
      return "TOO_DARK_HOVER";
    case VehicleFault_Enum.TOO_DARK_WARNING:
      return "TOO_DARK_WARNING";
    case VehicleFault_Enum.TOO_DARK_FOR_VISION:
      return "TOO_DARK_FOR_VISION";
    case VehicleFault_Enum.TOO_DARK_TRY_NIGHT_AUTONOMY:
      return "TOO_DARK_TRY_NIGHT_AUTONOMY";
    case VehicleFault_Enum.TAKEOFF_NEEDS_MOTION:
      return "TAKEOFF_NEEDS_MOTION";
    case VehicleFault_Enum.HAND_WAVE_CAL_NEEDS_STOP_WAVING:
      return "HAND_WAVE_CAL_NEEDS_STOP_WAVING";
    case VehicleFault_Enum.HAND_WAVE_UNAVAILABLE:
      return "HAND_WAVE_UNAVAILABLE";
    case VehicleFault_Enum.TAKEOFF_BLOCKED_BY_POSE_POLISHER:
      return "TAKEOFF_BLOCKED_BY_POSE_POLISHER";
    case VehicleFault_Enum.NOT_STABLE_FOR_TAKEOFF:
      return "NOT_STABLE_FOR_TAKEOFF";
    case VehicleFault_Enum.TOO_FAST_FOR_TAKEOFF:
      return "TOO_FAST_FOR_TAKEOFF";
    case VehicleFault_Enum.TOO_FAST_FOR_TAKEOFF_NIGHT:
      return "TOO_FAST_FOR_TAKEOFF_NIGHT";
    case VehicleFault_Enum.NOT_STABLE_FOR_TAKEOFF_NIGHT:
      return "NOT_STABLE_FOR_TAKEOFF_NIGHT";
    case VehicleFault_Enum.TOO_FAST_FOR_TAKEOFF_VESSEL:
      return "TOO_FAST_FOR_TAKEOFF_VESSEL";
    case VehicleFault_Enum.NOT_STABLE_FOR_TAKEOFF_VESSEL:
      return "NOT_STABLE_FOR_TAKEOFF_VESSEL";
    case VehicleFault_Enum.TEGRA_REQUIRES_EMERGENCY_LAND:
      return "TEGRA_REQUIRES_EMERGENCY_LAND";
    case VehicleFault_Enum.IS_FIRST_PILOTED_EMERGENCY_LAND:
      return "IS_FIRST_PILOTED_EMERGENCY_LAND";
    case VehicleFault_Enum.IS_PILOTED_EMERGENCY_LAND:
      return "IS_PILOTED_EMERGENCY_LAND";
    case VehicleFault_Enum.COMMANDED_EMERGENCY_LAND:
      return "COMMANDED_EMERGENCY_LAND";
    case VehicleFault_Enum.EMERGENCY_LAND_MOTOR_ARMING_COMMAND:
      return "EMERGENCY_LAND_MOTOR_ARMING_COMMAND";
    case VehicleFault_Enum.EMERGENCY_LAND_LOST_RC_LINK:
      return "EMERGENCY_LAND_LOST_RC_LINK";
    case VehicleFault_Enum.EMERGENCY_LAND_TEGRA_HEALTH_MONITOR_STALE:
      return "EMERGENCY_LAND_TEGRA_HEALTH_MONITOR_STALE";
    case VehicleFault_Enum.EMERGENCY_LAND_TEGRA_ATTITUDE_COMMAND_STALE:
      return "EMERGENCY_LAND_TEGRA_ATTITUDE_COMMAND_STALE";
    case VehicleFault_Enum.EMERGENCY_LAND_RC_EMERGENCY_LAND_COMMAND:
      return "EMERGENCY_LAND_RC_EMERGENCY_LAND_COMMAND";
    case VehicleFault_Enum.EMERGENCY_LAND_SYMPC_CONTROLLER_FAILED:
      return "EMERGENCY_LAND_SYMPC_CONTROLLER_FAILED";
    case VehicleFault_Enum.EMERGENCY_LAND_NO_STATE_ESTIMATE_AVAILABLE:
      return "EMERGENCY_LAND_NO_STATE_ESTIMATE_AVAILABLE";
    case VehicleFault_Enum.JOYSTICKS_INPUT_PRE_TAKEOFF:
      return "JOYSTICKS_INPUT_PRE_TAKEOFF";
    case VehicleFault_Enum.TRIGGER_OR_ROLLER_INPUT_PRE_TAKEOFF:
      return "TRIGGER_OR_ROLLER_INPUT_PRE_TAKEOFF";
    case VehicleFault_Enum.JOYSTICKS_INPUT_ERRATIC:
      return "JOYSTICKS_INPUT_ERRATIC";
    case VehicleFault_Enum.MAGNETOMETER_NEEDS_CALIBRATION:
      return "MAGNETOMETER_NEEDS_CALIBRATION";
    case VehicleFault_Enum.MAGNETOMETER_NEEDS_GPS:
      return "MAGNETOMETER_NEEDS_GPS";
    case VehicleFault_Enum.ATTI_RTX_REQUIRES_MAG_CAL:
      return "ATTI_RTX_REQUIRES_MAG_CAL";
    case VehicleFault_Enum.ATTI_RTX_MAG_REQUIRES_LOCATION:
      return "ATTI_RTX_MAG_REQUIRES_LOCATION";
    case VehicleFault_Enum.USER_CAMERA_PHOTO_CAPTURE:
      return "USER_CAMERA_PHOTO_CAPTURE";
    case VehicleFault_Enum.COMMANDED_PAST_POOR_GPS_HEIGHT:
      return "COMMANDED_PAST_POOR_GPS_HEIGHT";
    case VehicleFault_Enum.COMMANDED_PAST_MAX_HEIGHT:
      return "COMMANDED_PAST_MAX_HEIGHT";
    case VehicleFault_Enum.COMMANDED_PAST_INDOOR_POOR_GPS_HEIGHT:
      return "COMMANDED_PAST_INDOOR_POOR_GPS_HEIGHT";
    case VehicleFault_Enum.COMMANDED_PAST_DENSITY_ALTITUDE:
      return "COMMANDED_PAST_DENSITY_ALTITUDE";
    case VehicleFault_Enum.COMMANDED_PAST_POOR_GPS_HEIGHT_NOT_DISMISSED:
      return "COMMANDED_PAST_POOR_GPS_HEIGHT_NOT_DISMISSED";
    case VehicleFault_Enum.COMMANDED_PAST_MAX_HEIGHT_NOT_DISMISSED:
      return "COMMANDED_PAST_MAX_HEIGHT_NOT_DISMISSED";
    case VehicleFault_Enum.COMMANDED_PAST_INDOOR_POOR_GPS_HEIGHT_NOT_DISMISSED:
      return "COMMANDED_PAST_INDOOR_POOR_GPS_HEIGHT_NOT_DISMISSED";
    case VehicleFault_Enum.AT_GEOFENCE_EDGE:
      return "AT_GEOFENCE_EDGE";
    case VehicleFault_Enum.AT_GEOFENCE_EDGE_NOT_DISMISSED:
      return "AT_GEOFENCE_EDGE_NOT_DISMISSED";
    case VehicleFault_Enum.RETURNING_TO_GEOFENCE:
      return "RETURNING_TO_GEOFENCE";
    case VehicleFault_Enum.RETURNING_TO_GEOFENCE_NOT_DISMISSED:
      return "RETURNING_TO_GEOFENCE_NOT_DISMISSED";
    case VehicleFault_Enum.FAR_OUTSIDE_GEOFENCE:
      return "FAR_OUTSIDE_GEOFENCE";
    case VehicleFault_Enum.GEOFENCE_UNRELIABLE:
      return "GEOFENCE_UNRELIABLE";
    case VehicleFault_Enum.VEHICLE_NEEDS_ASSISTNOW:
      return "VEHICLE_NEEDS_ASSISTNOW";
    case VehicleFault_Enum.SKILLS_LATENCY_HIGH:
      return "SKILLS_LATENCY_HIGH";
    case VehicleFault_Enum.DOCK_NOT_READY_FOR_TAKEOFF:
      return "DOCK_NOT_READY_FOR_TAKEOFF";
    case VehicleFault_Enum.WEBRTC_OFFLINE:
      return "WEBRTC_OFFLINE";
    case VehicleFault_Enum.DOCK_DISCONNECTED:
      return "DOCK_DISCONNECTED";
    case VehicleFault_Enum.DOCK_DETECTED:
      return "DOCK_DETECTED";
    case VehicleFault_Enum.VEHICLE_ON_SOME_TAKEOFF_SURFACE:
      return "VEHICLE_ON_SOME_TAKEOFF_SURFACE";
    case VehicleFault_Enum.DOCK_IN_INSPECTION_MODE:
      return "DOCK_IN_INSPECTION_MODE";
    case VehicleFault_Enum.DIGITAL_ZOOM_UNAVAILABLE:
      return "DIGITAL_ZOOM_UNAVAILABLE";
    case VehicleFault_Enum.SUPERZOOM_UNAVAILABLE_SUBJECT_TRACKING:
      return "SUPERZOOM_UNAVAILABLE_SUBJECT_TRACKING";
    case VehicleFault_Enum.MOTOR_CURRENT_WARNING:
      return "MOTOR_CURRENT_WARNING";
    case VehicleFault_Enum.MOTOR_CURRENT_CRITICAL:
      return "MOTOR_CURRENT_CRITICAL";
    case VehicleFault_Enum.MOTOR_IDLE_OVERCURRENT_CRITICAL:
      return "MOTOR_IDLE_OVERCURRENT_CRITICAL";
    case VehicleFault_Enum.MOTOR_IDLE_OVERCURRENT_WARNING:
      return "MOTOR_IDLE_OVERCURRENT_WARNING";
    case VehicleFault_Enum.AIRHAWK_ATTITUDE_CONTROL_REQ_RESTART:
      return "AIRHAWK_ATTITUDE_CONTROL_REQ_RESTART";
    case VehicleFault_Enum.DEFAULT_WIFI_AP_CREDENTIALS:
      return "DEFAULT_WIFI_AP_CREDENTIALS";
    case VehicleFault_Enum.IMPERFECT_CONTACT_WITH_DOCK:
      return "IMPERFECT_CONTACT_WITH_DOCK";
    case VehicleFault_Enum.LOST_CONNECTION_DROP_VIDEO:
      return "LOST_CONNECTION_DROP_VIDEO";
    case VehicleFault_Enum.ARM_IMPROPER_POSITION_VISION:
      return "ARM_IMPROPER_POSITION_VISION";
    case VehicleFault_Enum.ARM_IMPROPER_POSITION_CRITICAL:
      return "ARM_IMPROPER_POSITION_CRITICAL";
    case VehicleFault_Enum.ARM_IMPROPER_POSITION_VISION_BLOCK_TAKEOFF:
      return "ARM_IMPROPER_POSITION_VISION_BLOCK_TAKEOFF";
    case VehicleFault_Enum.VIO_CALIBRATIONS_NOT_CONVERGED:
      return "VIO_CALIBRATIONS_NOT_CONVERGED";
    case VehicleFault_Enum.AUTO_GPS_INITIALIZATION_FAILED:
      return "AUTO_GPS_INITIALIZATION_FAILED";
    case VehicleFault_Enum.BEACON_BARO_OFFSET_NEEDED:
      return "BEACON_BARO_OFFSET_NEEDED";
    case VehicleFault_Enum.DOCK_VEHICLE_PAIRING_MISMATCH:
      return "DOCK_VEHICLE_PAIRING_MISMATCH";
    case VehicleFault_Enum.RID_KEY_REQUIRED:
      return "RID_KEY_REQUIRED";
    case VehicleFault_Enum.MOTOR_VIBE_CHECK_PENDING:
      return "MOTOR_VIBE_CHECK_PENDING";
    case VehicleFault_Enum.EXCESSIVE_MOTOR_VIBE:
      return "EXCESSIVE_MOTOR_VIBE";
    case VehicleFault_Enum.MANUAL_MODE_RECOMMENDED:
      return "MANUAL_MODE_RECOMMENDED";
    case VehicleFault_Enum.RID_CHECK_STARTED:
      return "RID_CHECK_STARTED";
    case VehicleFault_Enum.WIFI_NO_CONFIGURED_SSIDS_FOUND:
      return "WIFI_NO_CONFIGURED_SSIDS_FOUND";
    case VehicleFault_Enum.WIFI_AUTHENTICATION_ERROR:
      return "WIFI_AUTHENTICATION_ERROR";
    case VehicleFault_Enum.WIFI_CONNECTION_TIMEOUT:
      return "WIFI_CONNECTION_TIMEOUT";
    case VehicleFault_Enum.CONTROLLER_GPS_QUALITY_INSUFFICIENT:
      return "CONTROLLER_GPS_QUALITY_INSUFFICIENT";
    case VehicleFault_Enum.RID_CONTROLLER_GPS_INOPERATIVE:
      return "RID_CONTROLLER_GPS_INOPERATIVE";
    case VehicleFault_Enum.RID_RPIC_GPS_INOPERATIVE:
      return "RID_RPIC_GPS_INOPERATIVE";
    case VehicleFault_Enum.DOCK_CRADLE_EXTENDED_PRE_TAKEOFF:
      return "DOCK_CRADLE_EXTENDED_PRE_TAKEOFF";
    case VehicleFault_Enum.IS_BASESTATION_TAKEOFF_SURFACE:
      return "IS_BASESTATION_TAKEOFF_SURFACE";
    case VehicleFault_Enum.IS_BASESTATION_LANDING_SURFACE:
      return "IS_BASESTATION_LANDING_SURFACE";
    case VehicleFault_Enum.LANDED_OUTSIDE_DOCK:
      return "LANDED_OUTSIDE_DOCK";
    case VehicleFault_Enum.AIRHAWK_RT_LOSS:
      return "AIRHAWK_RT_LOSS";
    case VehicleFault_Enum.LIVEKIT_NO_CONFIG:
      return "LIVEKIT_NO_CONFIG";
    case VehicleFault_Enum.LIVEKIT_CONFIG_MISMATCH:
      return "LIVEKIT_CONFIG_MISMATCH";
    case VehicleFault_Enum.LIVEKIT_NOT_ENABLED:
      return "LIVEKIT_NOT_ENABLED";
    case VehicleFault_Enum.LIVEKIT_CONNECTED:
      return "LIVEKIT_CONNECTED";
    case VehicleFault_Enum.LIVEKIT_CONNECTION_FAILED:
      return "LIVEKIT_CONNECTION_FAILED";
    case VehicleFault_Enum.VEHICLE_GPS_QUALITY_BLOCKING_TWILIGHT:
      return "VEHICLE_GPS_QUALITY_BLOCKING_TWILIGHT";
    case VehicleFault_Enum.FLIGHT_TERMINATED:
      return "FLIGHT_TERMINATED";
    case VehicleFault_Enum.NAV_CAMERA_LOGGING_DEGRADED:
      return "NAV_CAMERA_LOGGING_DEGRADED";
    case VehicleFault_Enum.SKILLS_TASKS_PENDING:
      return "SKILLS_TASKS_PENDING";
    case VehicleFault_Enum.DOCK_COMMS_UNRELIABLE:
      return "DOCK_COMMS_UNRELIABLE";
    case VehicleFault_Enum.CLOUD_ENV_OUT_OF_SYNC:
      return "CLOUD_ENV_OUT_OF_SYNC";
    case VehicleFault_Enum.TRACK_TRAJECTORY_STUCK_ON_OBSTACLES:
      return "TRACK_TRAJECTORY_STUCK_ON_OBSTACLES";
    case VehicleFault_Enum.SKILLS_TASK_NEEDS_DECRYPTION:
      return "SKILLS_TASK_NEEDS_DECRYPTION";
    case VehicleFault_Enum.USER_CAMERA_SLOW_MODE_SWITCH:
      return "USER_CAMERA_SLOW_MODE_SWITCH";
    case VehicleFault_Enum.NVU_DISCONNECTED:
      return "NVU_DISCONNECTED";
    case VehicleFault_Enum.QCU_DISCONNECTED:
      return "QCU_DISCONNECTED";
    case VehicleFault_Enum.BARO_MEASUREMENT_UNRELIABLE:
      return "BARO_MEASUREMENT_UNRELIABLE";
    case VehicleFault_Enum.WAYPOINTS_UNRELIABLE:
      return "WAYPOINTS_UNRELIABLE";
    case VehicleFault_Enum.WAYPOINT_TASK_STUCK_ON_OBSTACLE:
      return "WAYPOINT_TASK_STUCK_ON_OBSTACLE";
    case VehicleFault_Enum.WAYPOINT_TASK_STUCK_ON_GEOFENCE:
      return "WAYPOINT_TASK_STUCK_ON_GEOFENCE";
    case VehicleFault_Enum.DOCK_CLIENT_UNRELIABLE:
      return "DOCK_CLIENT_UNRELIABLE";
    case VehicleFault_Enum.SPURIOUS_VOXELS_DAY:
      return "SPURIOUS_VOXELS_DAY";
    case VehicleFault_Enum.SPURIOUS_VOXELS_NIGHT:
      return "SPURIOUS_VOXELS_NIGHT";
    case VehicleFault_Enum.SPURIOUS_VOXELS_DEBUG:
      return "SPURIOUS_VOXELS_DEBUG";
    case VehicleFault_Enum.BAD_GPU_FREQUENCY_QCU:
      return "BAD_GPU_FREQUENCY_QCU";
    case VehicleFault_Enum.CPU_TOO_HIGH_QCU:
      return "CPU_TOO_HIGH_QCU";
    case VehicleFault_Enum.GPU_TOO_HIGH_QCU:
      return "GPU_TOO_HIGH_QCU";
    case VehicleFault_Enum.BASE_PROCESSES_STOPPED_QCU:
      return "BASE_PROCESSES_STOPPED_QCU";
    case VehicleFault_Enum.FLIGHT_PROCESSES_STOPPED_QCU:
      return "FLIGHT_PROCESSES_STOPPED_QCU";
    case VehicleFault_Enum.MEMORY_LOW_QCU:
      return "MEMORY_LOW_QCU";
    case VehicleFault_Enum.TOO_MANY_PAGE_FAULTS_QCU:
      return "TOO_MANY_PAGE_FAULTS_QCU";
    case VehicleFault_Enum.FLIGHT_DECK_OUT_OF_SYNC_QCU:
      return "FLIGHT_DECK_OUT_OF_SYNC_QCU";
    case VehicleFault_Enum.TOF_UNRELIABLE:
      return "TOF_UNRELIABLE";
    case VehicleFault_Enum.TOF_UNRELIABLE_FOR_FAST_DESCENT_LANDING:
      return "TOF_UNRELIABLE_FOR_FAST_DESCENT_LANDING";
    case VehicleFault_Enum.INFRARED_CAMERA_UNRELIABLE:
      return "INFRARED_CAMERA_UNRELIABLE";
    case VehicleFault_Enum.TOO_FAST_NIGHT_AUTONOMY:
      return "TOO_FAST_NIGHT_AUTONOMY";
    case VehicleFault_Enum.VEHICLE_RADIO_UPDATE_IN_PROGRESS:
      return "VEHICLE_RADIO_UPDATE_IN_PROGRESS";
    case VehicleFault_Enum.ATTACHMENT_CHANGE_DETECTED:
      return "ATTACHMENT_CHANGE_DETECTED";
    case VehicleFault_Enum.MISMATCHED_NIGHTSENSE_ATTACHMENT_DETECTED:
      return "MISMATCHED_NIGHTSENSE_ATTACHMENT_DETECTED";
    case VehicleFault_Enum.MIXED_NIGHTSENSE_ATTACHMENTS_DETECTED:
      return "MIXED_NIGHTSENSE_ATTACHMENTS_DETECTED";
    case VehicleFault_Enum.RTK_PPK_ATTACHMENT_CONNECTED:
      return "RTK_PPK_ATTACHMENT_CONNECTED";
    case VehicleFault_Enum.RTK_PPK_ATTACHMENT_CONNECTED_NOT_DISMISSED:
      return "RTK_PPK_ATTACHMENT_CONNECTED_NOT_DISMISSED";
    case VehicleFault_Enum.NIGHTSENSE_ATTACHMENT_DEGRADED:
      return "NIGHTSENSE_ATTACHMENT_DEGRADED";
    case VehicleFault_Enum.NIGHTSENSE_ATTACHMENT_DEGRADED_NOT_DISMISSED:
      return "NIGHTSENSE_ATTACHMENT_DEGRADED_NOT_DISMISSED";
    case VehicleFault_Enum.NIGHTSENSE_DISABLED_ATTACHMENT_FAILURE:
      return "NIGHTSENSE_DISABLED_ATTACHMENT_FAILURE";
    case VehicleFault_Enum.NIGHTSENSE_DISABLED_ATTACHMENT_FAILURE_NOT_DISMISSED:
      return "NIGHTSENSE_DISABLED_ATTACHMENT_FAILURE_NOT_DISMISSED";
    case VehicleFault_Enum.NIGHTSENSE_DISABLED_ATTACHMENT_DISCONNECTED:
      return "NIGHTSENSE_DISABLED_ATTACHMENT_DISCONNECTED";
    case VehicleFault_Enum.NIGHTSENSE_DISABLED_ATTACHMENT_DISCONNECTED_NOT_DISMISSED:
      return "NIGHTSENSE_DISABLED_ATTACHMENT_DISCONNECTED_NOT_DISMISSED";
    case VehicleFault_Enum.NIGHTSENSE_UNAVAILABLE_ATTACHMENT_MISSING:
      return "NIGHTSENSE_UNAVAILABLE_ATTACHMENT_MISSING";
    case VehicleFault_Enum.NIGHTSENSE_UNAVAILABLE_ATTACHMENT_MISSING_NOT_DISMISSED:
      return "NIGHTSENSE_UNAVAILABLE_ATTACHMENT_MISSING_NOT_DISMISSED";
    case VehicleFault_Enum.FLASH_DRIVE_REQUIRED:
      return "FLASH_DRIVE_REQUIRED";
    case VehicleFault_Enum.HOUSEKEEPER_MCU_VERSION_MISMATCH:
      return "HOUSEKEEPER_MCU_VERSION_MISMATCH";
    case VehicleFault_Enum.AIRHAWK_MCU_VERSION_MISMATCH:
      return "AIRHAWK_MCU_VERSION_MISMATCH";
    case VehicleFault_Enum.GIMBAL_MCU_VERSION_MISMATCH:
      return "GIMBAL_MCU_VERSION_MISMATCH";
    case VehicleFault_Enum.SOC_FLASHPACK_MISMATCH:
      return "SOC_FLASHPACK_MISMATCH";
    case VehicleFault_Enum.SOC_CONFIG_MISMATCH:
      return "SOC_CONFIG_MISMATCH";
    case VehicleFault_Enum.SOC_SERIAL_NUMBER_MISMATCH:
      return "SOC_SERIAL_NUMBER_MISMATCH";
    case VehicleFault_Enum.OTA_IN_PROGRESS:
      return "OTA_IN_PROGRESS";
    case VehicleFault_Enum.FIRMWARE_MISCONFIGURED:
      return "FIRMWARE_MISCONFIGURED";
    case VehicleFault_Enum.AIRHAWK_SERIAL_CONNECTION_LOST:
      return "AIRHAWK_SERIAL_CONNECTION_LOST";
    case VehicleFault_Enum.AIRHAWK_SERIAL_CONNECTION_LOST_QCU:
      return "AIRHAWK_SERIAL_CONNECTION_LOST_QCU";
    case VehicleFault_Enum.OBSTACLE_MARGINS_CANNOT_EXPAND:
      return "OBSTACLE_MARGINS_CANNOT_EXPAND";
    case VehicleFault_Enum.MOTOR_TAKEOFF_CURRENT_CRITICAL:
      return "MOTOR_TAKEOFF_CURRENT_CRITICAL";
    case VehicleFault_Enum.RELATIVE_HEADING_ESTIMATE_UNAVAILABLE:
      return "RELATIVE_HEADING_ESTIMATE_UNAVAILABLE";
    case VehicleFault_Enum.IMU_SATURATION_ON_TAKEOFF:
      return "IMU_SATURATION_ON_TAKEOFF";
    case VehicleFault_Enum.VEHICLE_GPS_RESET:
      return "VEHICLE_GPS_RESET";
    case VehicleFault_Enum.PRE_DOCK_TAKEOFF_VEHICLE_GPS_RESET_UNSUCCESSFUL:
      return "PRE_DOCK_TAKEOFF_VEHICLE_GPS_RESET_UNSUCCESSFUL";
    case VehicleFault_Enum.GIMBAL_FLASH_FAILED:
      return "GIMBAL_FLASH_FAILED";
    case VehicleFault_Enum.INFRARED_CAMERA_POOR_ACCURACY:
      return "INFRARED_CAMERA_POOR_ACCURACY";
    case VehicleFault_Enum.GIMBAL_TYPE_INCOMPATIBLE:
      return "GIMBAL_TYPE_INCOMPATIBLE";
    case VehicleFault_Enum.FRONT_UNRELIABLE:
      return "FRONT_UNRELIABLE";
    case VehicleFault_Enum.MOTOR_OSCILLATION_DETECTED:
      return "MOTOR_OSCILLATION_DETECTED";
    case VehicleFault_Enum.ATTACHMENT_OLD_SIDE_ATTACHMENT_HARDWARE:
      return "ATTACHMENT_OLD_SIDE_ATTACHMENT_HARDWARE";
    case VehicleFault_Enum.ATTACHMENT_WRONG_CABLE_ORIENTATION:
      return "ATTACHMENT_WRONG_CABLE_ORIENTATION";
    case VehicleFault_Enum.ATTACHMENT_ENUMERATION_IN_PROGRESS:
      return "ATTACHMENT_ENUMERATION_IN_PROGRESS";
    case VehicleFault_Enum.ATTACHMENT_IN_INVALID_PORT_TOP:
      return "ATTACHMENT_IN_INVALID_PORT_TOP";
    case VehicleFault_Enum.ATTACHMENT_IN_INVALID_PORT_BOTTOM:
      return "ATTACHMENT_IN_INVALID_PORT_BOTTOM";
    case VehicleFault_Enum.ATTACHMENT_IN_INVALID_PORT_LEFT:
      return "ATTACHMENT_IN_INVALID_PORT_LEFT";
    case VehicleFault_Enum.ATTACHMENT_IN_INVALID_PORT_RIGHT:
      return "ATTACHMENT_IN_INVALID_PORT_RIGHT";
    case VehicleFault_Enum.ATTACHMENT_INSPECT_OR_REPLACE_TOP:
      return "ATTACHMENT_INSPECT_OR_REPLACE_TOP";
    case VehicleFault_Enum.ATTACHMENT_INSPECT_OR_REPLACE_BOTTOM:
      return "ATTACHMENT_INSPECT_OR_REPLACE_BOTTOM";
    case VehicleFault_Enum.ATTACHMENT_INSPECT_OR_REPLACE_LEFT:
      return "ATTACHMENT_INSPECT_OR_REPLACE_LEFT";
    case VehicleFault_Enum.ATTACHMENT_INSPECT_OR_REPLACE_RIGHT:
      return "ATTACHMENT_INSPECT_OR_REPLACE_RIGHT";
    case VehicleFault_Enum.ATTACHMENT_NIGHTSENSE_VERSION_MISMATCH:
      return "ATTACHMENT_NIGHTSENSE_VERSION_MISMATCH";
    case VehicleFault_Enum.ATTACHMENT_NIGHTSENSE_UPDATE_FAILED:
      return "ATTACHMENT_NIGHTSENSE_UPDATE_FAILED";
    case VehicleFault_Enum.ATTACHMENT_NIGHTSENSE_UPDATE_FAILED_NOT_DISMISSED:
      return "ATTACHMENT_NIGHTSENSE_UPDATE_FAILED_NOT_DISMISSED";
    case VehicleFault_Enum.ATTACHMENT_SPOTLIGHT_BRIGHTNESS_THROTTLING:
      return "ATTACHMENT_SPOTLIGHT_BRIGHTNESS_THROTTLING";
    case VehicleFault_Enum.ATTACHMENT_SPOTLIGHT_THERMAL_SHUTDOWN:
      return "ATTACHMENT_SPOTLIGHT_THERMAL_SHUTDOWN";
    case VehicleFault_Enum.ATTACHMENT_SPOTLIGHT_ABOVE_TOUCH_TEMP:
      return "ATTACHMENT_SPOTLIGHT_ABOVE_TOUCH_TEMP";
    case VehicleFault_Enum.ATTACHMENT_PARACHUTE_DEPLOYED:
      return "ATTACHMENT_PARACHUTE_DEPLOYED";
    case VehicleFault_Enum.ATTACHMENT_RTK_COM_FAILURE:
      return "ATTACHMENT_RTK_COM_FAILURE";
    case VehicleFault_Enum.ATTACHMENT_SPEAKERMIC_LOW_VOLUME:
      return "ATTACHMENT_SPEAKERMIC_LOW_VOLUME";
    case VehicleFault_Enum.ATTACHMENT_SPOTLIGHT_TWO_SPOTLIGHTS_UNSUPPORTED:
      return "ATTACHMENT_SPOTLIGHT_TWO_SPOTLIGHTS_UNSUPPORTED";
    case VehicleFault_Enum.ATTACHMENT_INVALID_COMBINATION:
      return "ATTACHMENT_INVALID_COMBINATION";
    case VehicleFault_Enum.RID_TX_FAILURE:
      return "RID_TX_FAILURE";
    case VehicleFault_Enum.PINE_HIGH_INTERFERENCE:
      return "PINE_HIGH_INTERFERENCE";
    case VehicleFault_Enum.PINE_SU_PKTS_IN_OFDMA:
      return "PINE_SU_PKTS_IN_OFDMA";
    case VehicleFault_Enum.NCPG_SAVE_IN_PROGRESS:
      return "NCPG_SAVE_IN_PROGRESS";
    case VehicleFault_Enum.MISSION_CONDUCTOR_DISABLE_PILOTSHIP:
      return "MISSION_CONDUCTOR_DISABLE_PILOTSHIP";
    case VehicleFault_Enum.GROUND_AVOIDANCE_ACTIVE:
      return "GROUND_AVOIDANCE_ACTIVE";
    case VehicleFault_Enum.GPS_SPOOFED:
      return "GPS_SPOOFED";
    case VehicleFault_Enum.EVENT_LOOP_FD_OVERFLOW:
      return "EVENT_LOOP_FD_OVERFLOW";
    case VehicleFault_Enum.MICROHARD_DCS_JAMMING_FAULT:
      return "MICROHARD_DCS_JAMMING_FAULT";
    case VehicleFault_Enum.BATTERY_NOT_ARMED:
      return "BATTERY_NOT_ARMED";
    case VehicleFault_Enum.VEHICLE_TOO_HOT_CRITICAL_FINAL:
      return "VEHICLE_TOO_HOT_CRITICAL_FINAL";
    case VehicleFault_Enum.IMU_NEAR_SATURATION:
      return "IMU_NEAR_SATURATION";
    case VehicleFault_Enum.ATTI_TAKEOFF_AWAITING_USER_INPUT:
      return "ATTI_TAKEOFF_AWAITING_USER_INPUT";
    case VehicleFault_Enum.VEHICLE_CLOCK_STALE:
      return "VEHICLE_CLOCK_STALE";
    case VehicleFault_Enum.VEHICLE_CLOCK_STALE_NOT_DISMISSED:
      return "VEHICLE_CLOCK_STALE_NOT_DISMISSED";
    case VehicleFault_Enum.FLIR_FIRMWARE_UPDATE_IN_PROGRESS:
      return "FLIR_FIRMWARE_UPDATE_IN_PROGRESS";
    case VehicleFault_Enum.FLIR_FIRMWARE_VERSION_MISMATCH:
      return "FLIR_FIRMWARE_VERSION_MISMATCH";
    case VehicleFault_Enum.NAV_FRAME_UNRELIABLE:
      return "NAV_FRAME_UNRELIABLE";
    case VehicleFault_Enum.MISSION_NOT_READY_FOR_TAKEOFF:
      return "MISSION_NOT_READY_FOR_TAKEOFF";
    case VehicleFault_Enum.UNEXPECTED_OBSTACLES_HIGH_SPEED:
      return "UNEXPECTED_OBSTACLES_HIGH_SPEED";
    case VehicleFault_Enum.APPROACHING_GEOAWARENESS_ZONE:
      return "APPROACHING_GEOAWARENESS_ZONE";
    case VehicleFault_Enum.ENTERED_GEOAWARENESS_ZONE:
      return "ENTERED_GEOAWARENESS_ZONE";
    case VehicleFault_Enum.GEOAWARENESS_UNAVAILABLE_FILE:
      return "GEOAWARENESS_UNAVAILABLE_FILE";
    case VehicleFault_Enum.GEOAWARENESS_UNAVAILABLE_GPS:
      return "GEOAWARENESS_UNAVAILABLE_GPS";
    case VehicleFault_Enum.YIELD_FOR_NEARBY_DRONES:
      return "YIELD_FOR_NEARBY_DRONES";
    case VehicleFault_Enum.AWAITING_CLOUD_CONFIG_UPDATE:
      return "AWAITING_CLOUD_CONFIG_UPDATE";
    case VehicleFault_Enum.MAX_NUM_FAULTS:
      return "MAX_NUM_FAULTS";
    case VehicleFault_Enum.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

function createBaseVehicleFault(): VehicleFault {
  return {};
}

export const VehicleFault = {
  encode(_: VehicleFault, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VehicleFault {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVehicleFault();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): VehicleFault {
    return {};
  },

  toJSON(_: VehicleFault): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<VehicleFault>, I>>(base?: I): VehicleFault {
    return VehicleFault.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VehicleFault>, I>>(_: I): VehicleFault {
    const message = createBaseVehicleFault();
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };
