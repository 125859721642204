import { viewport } from "@mapbox/geo-viewport";
import _ from "lodash";
import * as THREE from "three";

import { degToRad } from "@skydio/math";

import type { GeoViewport } from "@mapbox/geo-viewport";
import type { MinimalGps } from "@skydio/pbtypes/pbtypes/vehicle/ambassador/ambassador_pb";

// This is the absolute value of the dot product between the camera's forward unit vector and the
// nav vertical unit vector (gravity aligned) above which we consider the camera to look up or down.
// The value doesn't really matter as long as it is not too close to 0 or 1.
const THRESHOLD_UP_OR_DOWN = 0.5;
/**
 * A zoom to use when the viewport() function returns `Infinity` or `NaN` for the zoom.
 *
 * Read from https://docs.mapbox.com/help/glossary/zoom-level/
 */
const FALLBACK_ZOOM_LEVEL = 15;

export const latLngArrayFromGps = (gpsPoint: MinimalGps.AsObject) =>
  [gpsPoint.latitude, gpsPoint.longitude] as [number, number];
export const latLngFromArray = ([latitude, longitude]: [number, number]) => ({
  latitude,
  longitude,
});

export const getBoundsForData = (points: [number, number][]): [number, number, number, number] => {
  if (!points.length) {
    return [0, 0, 0, 0];
  }
  return [
    _.minBy(points, 1)![1], // W
    _.minBy(points, 0)![0], // S
    _.maxBy(points, 1)![1], // E
    _.maxBy(points, 0)![0], // N
  ];
};

export function getDefaultViewportForData<T>(
  data: T[],
  viewportDims: [number, number],
  mapper?: (point: T) => [number, number],
  zoomFactor?: number
): GeoViewport;
export function getDefaultViewportForData(
  data: [number, number][],
  viewportDims: [number, number]
): GeoViewport;
export function getDefaultViewportForData(
  data: any[],
  viewportDims: [number, number],
  mapper: (point: any) => [number, number] = x => x,
  zoomFactor = 0.95
) {
  let { center, zoom } = viewport(getBoundsForData(data.map(mapper)), viewportDims);
  // Handle this case since sometimes zoom is reported as `NaN` by the function
  if (zoom === Infinity || Number.isNaN(zoom)) {
    zoom = FALLBACK_ZOOM_LEVEL;
  }

  return { center, zoom: zoom * zoomFactor }; // zoom out a bit so everything will be visible
}

export enum MapStyle {
  STREETS = 0,
  SATELLITE = 1,
  SATELLITE_MUTED = 2,
}

export const mapStyleLabel = (style: MapStyle) => {
  switch (style) {
    case MapStyle.STREETS:
      return "Streets";
    case MapStyle.SATELLITE:
      return "Satellite";
    case MapStyle.SATELLITE_MUTED:
      return "Satellite (Muted)";
  }
};

/**
 * Groups of map styles available.
 *
 * @remarks
 * A "group" can be intended as a filter to decide whether to show a specific map irrespectively
 * of the style.
 *
 * Add new groups here, then update the dictionaries below with their URLs.
 */
export enum MapStyleGroups {
  default = 0,
  customer = 1,
  faa = 2,
  operations_area = 3,
  customer2 = 4,
  operations_area_internal = 5,
  internal_mapbox_styles_3 = 6,
  city_customer = 7,
}

/**
 * Groups map style groups of map styles **with** labels together.
 */
const MAP_STYLES_GROUPS = {
  [MapStyleGroups.default]: {
    [MapStyle.STREETS]: "mapbox://styles/mapbox/light-v9",
    [MapStyle.SATELLITE]: "mapbox://styles/skydio-team/cl6v3y7o5000014pslz5d7pnf",
    [MapStyle.SATELLITE_MUTED]: "mapbox://styles/skydio-team/cl6v40p6c000014plrmrtohtc",
  },
  [MapStyleGroups.customer]: {
    /**
     * No need to change it.
     */
    [MapStyle.STREETS]: "mapbox://styles/mapbox/light-v9",
    /**
     * Name: Satellite Streets basic 2022-08-with-BNSF-orthomap
     *
     * Url: https://studio.mapbox.com/styles/skydio-team/cla2rkfug000015ladwxia0qy/
     */
    [MapStyle.SATELLITE]: "mapbox://styles/skydio-team/cla2rkfug000015ladwxia0qy",
    /**
     * Unused, but kept for typing purposes, otherwise TypeScript complains
     */
    [MapStyle.SATELLITE_MUTED]: "mapbox://styles/skydio-team/cl6v40p6c000014plrmrtohtc",
  },
  [MapStyleGroups.faa]: {
    /**
     * FAA Light V9
     *
     * https://studio.mapbox.com/styles/skydio-team/clndctita004s01ps5q2zch0o/
     */
    [MapStyle.STREETS]: "mapbox://styles/skydio-team/clndctita004s01ps5q2zch0o",
    /**
     * FAA Satellite Streets
     *
     * https://studio.mapbox.com/styles/skydio-team/clnajx3oi002h01psg4gi4zyq/
     */
    [MapStyle.SATELLITE]: "mapbox://styles/skydio-team/clnajx3oi002h01psg4gi4zyq",
    /**
     * Unused, but kept for typing purposes, otherwise TypeScript complains
     */
    [MapStyle.SATELLITE_MUTED]: "mapbox://styles/skydio-team/cl6v40p6c000014plrmrtohtc",
  },
  [MapStyleGroups.operations_area]: {
    /**
     * Name: NYPD Operation Area - Satellite Streets Light V9
     *
     * Url: https://studio.mapbox.com/styles/skydio-team/clzru1hlb00ch01r54mmzhu43/
     */
    [MapStyle.STREETS]: "mapbox://styles/skydio-team/clzru1hlb00ch01r54mmzhu43",
    /**
     * NYPD Operation Area - Satellite Streets
     *
     * https://studio.mapbox.com/styles/skydio-team/clzru1ej100by01pz0haibfhg/
     */
    [MapStyle.SATELLITE]: "mapbox://styles/skydio-team/clzru1ej100by01pz0haibfhg",
    /**
     * Unused, but kept for typing purposes, otherwise TypeScript complains
     */
    [MapStyle.SATELLITE_MUTED]: "mapbox://styles/skydio-team/cl6v40p6c000014plrmrtohtc",
  },
  [MapStyleGroups.customer2]: {
    /**
     * LNG Canada - Satellite Streets Light V9
     *
     * Url: https://studio.mapbox.com/styles/skydio-team/clxf7fvtu003901o72o4i21n8/
     */
    [MapStyle.STREETS]: "mapbox://styles/skydio-team/clxf7fvtu003901o72o4i21n8",
    /**
     * LNG Canada - Satellite Streets
     *
     * https://studio.mapbox.com/styles/skydio-team/clxf7pbwm003801puab629ymy/
     */
    [MapStyle.SATELLITE]: "mapbox://styles/skydio-team/clxf7pbwm003801puab629ymy",
    /**
     * Unused, but kept for typing purposes, otherwise TypeScript complains
     */
    [MapStyle.SATELLITE_MUTED]: "mapbox://styles/skydio-team/cl6v40p6c000014plrmrtohtc",
  },
  [MapStyleGroups.operations_area_internal]: {
    /**
     * Name: NYPD Operation Area internal - Satellite Streets Light V9
     *
     * Url: https://studio.mapbox.com/styles/skydio-team/clzhhvkf300lc01rc16p0hank/
     */
    [MapStyle.STREETS]: "mapbox://styles/skydio-team/clzhhvkf300lc01rc16p0hank",
    /**
     * Name: NYPD Operation Area internal - Satellite Streets
     *
     * Url: https://studio.mapbox.com/styles/skydio-team/clzhhvf5600ki01r48wql8ps6/
     */
    [MapStyle.SATELLITE]: "mapbox://styles/skydio-team/clzhhvf5600ki01r48wql8ps6",
    /**
     * Unused, but kept for typing purposes, otherwise TypeScript complains
     */
    [MapStyle.SATELLITE_MUTED]: "mapbox://styles/skydio-team/cl6v40p6c000014plrmrtohtc",
  },
  [MapStyleGroups.internal_mapbox_styles_3]: {
    /**
     * Name: LVMPD Operational Area Internal - Satellite Streets Light V9
     *
     * Url: https://studio.mapbox.com/styles/skydio-team/clza2eyu6007101ohe2k3dd7h/
     */
    [MapStyle.STREETS]: "mapbox://styles/skydio-team/clza2eyu6007101ohe2k3dd7h",
    /**
     * Name: LVMPD Operational Area Internal - Satellite Streets
     *
     * Url: https://studio.mapbox.com/styles/skydio-team/clza2evtk006y01r4efiu7ra5/
     */
    [MapStyle.SATELLITE]: "mapbox://styles/skydio-team/clza2evtk006y01r4efiu7ra5",
    /**
     * Unused, but kept for typing purposes, otherwise TypeScript complains
     */
    [MapStyle.SATELLITE_MUTED]: "mapbox://styles/skydio-team/cl6v40p6c000014plrmrtohtc",
  },
  [MapStyleGroups.city_customer]: {
    /**
     * Name: SFPD Operational Area North Beach - Satellite Streets Light V9
     *
     * Url: https://studio.mapbox.com/styles/skydio-team/cm0o0xdbu023g01qv46gc85gq/
     */
    [MapStyle.STREETS]: "mapbox://styles/skydio-team/cm0o0xdbu023g01qv46gc85gq",
    /**
     * Name: SFPD Operational Area North Beach - Satellite Streets
     *
     * Url: https://studio.mapbox.com/styles/skydio-team/cm0o0xa3q02e501qqa4031wq1/
     */
    [MapStyle.SATELLITE]: "mapbox://styles/skydio-team/cm0o0xa3q02e501qqa4031wq1",
    /**
     * Unused, but kept for typing purposes, otherwise TypeScript complains
     */
    [MapStyle.SATELLITE_MUTED]: "mapbox://styles/skydio-team/cl6v40p6c000014plrmrtohtc",
  },
};

/**
 * Groups map style groups of map styles **without** labels together.
 *
 * @remarks
 * Used in the minimap.
 * NOTE(sam): the labels are distracting on the tiny corner minimap so we use these
 */
const MAP_STYLE_GROUPS_NO_LABELS = {
  [MapStyleGroups.default]: {
    /**
     * Name: Light V9 No Labels
     *
     * Url: https://studio.mapbox.com/styles/skydio-team/clndea6lz00bl01pu0kv752rg/
     */
    [MapStyle.STREETS]: "mapbox://styles/skydio-team/clndea6lz00bl01pu0kv752rg",
    /**
     * NOTE(sam): this satellite style has no labels out of the box
     */
    [MapStyle.SATELLITE]: "mapbox://styles/mapbox/satellite-v9",
    [MapStyle.SATELLITE_MUTED]: "mapbox://styles/skydio-team/cl6v40p6c000014plrmrtohtc",
  },
  [MapStyleGroups.customer]: {
    /**
     * Name: Light V9 No Labels
     *
     * Url: https://studio.mapbox.com/styles/skydio-team/clndea6lz00bl01pu0kv752rg/
     */
    [MapStyle.STREETS]: "mapbox://styles/skydio-team/clndea6lz00bl01pu0kv752rg",
    /**
     * Name: Satellite Streets basic 2022-08-with-BNSF-orthomap-NO-LABELS
     *
     * Url: https://studio.mapbox.com/styles/skydio-team/clndfek9s07qt01ma9en0241f/
     */
    [MapStyle.SATELLITE]: "mapbox://styles/skydio-team/clndfek9s07qt01ma9en0241f",
    /**
     * Unused, but kept for typing purposes, otherwise TypeScript complains
     */
    [MapStyle.SATELLITE_MUTED]: "mapbox://styles/skydio-team/cl6v40p6c000014plrmrtohtc",
  },
  /**
   * These are the same as the ones in the default group on purpose:
   * these are shown in the bottom left minimap button, so we don't need to show the FAA layers in
   * there.
   */
  [MapStyleGroups.faa]: {
    /**
     * Name: Light V9 No Labels
     *
     * Url: https://studio.mapbox.com/styles/skydio-team/clndea6lz00bl01pu0kv752rg/
     */
    [MapStyle.STREETS]: "mapbox://styles/skydio-team/clndea6lz00bl01pu0kv752rg",
    /**
     * NOTE(sam): this satellite style has no labels out of the box
     */
    [MapStyle.SATELLITE]: "mapbox://styles/mapbox/satellite-v9",
    [MapStyle.SATELLITE_MUTED]: "mapbox://styles/skydio-team/cl6v40p6c000014plrmrtohtc",
  },
  [MapStyleGroups.operations_area]: {
    /**
     * Name: NYPD Operation Area - Light V9 No Labels
     *
     * Url: https://studio.mapbox.com/styles/skydio-team/clzru0zc800d401r3ch6v59an/
     */
    [MapStyle.STREETS]: "mapbox://styles/skydio-team/clzru0zc800d401r3ch6v59an",
    /**
     * Name: NYPD Operation Area - Satellite NO-LABELS
     *
     * Url: https://studio.mapbox.com/styles/skydio-team/clzru1m5b00ci01r5a6aj9fqh/
     */
    [MapStyle.SATELLITE]: "mapbox://styles/skydio-team/clzru1m5b00ci01r5a6aj9fqh",
    /**
     * Unused, but kept for typing purposes, otherwise TypeScript complains
     */
    [MapStyle.SATELLITE_MUTED]: "mapbox://styles/skydio-team/cl6v40p6c000014plrmrtohtc",
  },
  [MapStyleGroups.customer2]: {
    /**
     * Name: LNG Canada - Light V9 No Labels
     *
     * Url: https://studio.mapbox.com/styles/skydio-team/clxf7vq9j002p01obcr27540n/
     */
    [MapStyle.STREETS]: "mapbox://styles/skydio-team/clxf7vq9j002p01obcr27540n",
    /**
     * Name: NYPD Operation Area - Satellite NO-LABELS
     *
     * Url: https://studio.mapbox.com/styles/skydio-team/clxf7t32m003b01poeoyz594n/
     */
    [MapStyle.SATELLITE]: "mapbox://styles/skydio-team/clxf7t32m003b01poeoyz594n",
    /**
     * Unused, but kept for typing purposes, otherwise TypeScript complains
     */
    [MapStyle.SATELLITE_MUTED]: "mapbox://styles/skydio-team/cl6v40p6c000014plrmrtohtc",
  },
  [MapStyleGroups.operations_area_internal]: {
    /**
     * Name: NYPD Operation Area internal - Light V9 No Labels
     *
     * Url: https://studio.mapbox.com/styles/skydio-team/clzhhv8sk00jr01oh68rjg5p7/
     */
    [MapStyle.STREETS]: "mapbox://styles/skydio-team/clzhhv8sk00jr01oh68rjg5p7",
    /**
     * Name: NYPD Operation Area internal - Satellite NO-LABELS
     *
     * Url: https://studio.mapbox.com/styles/skydio-team/clzhhvn1o00ld01rc70mngecd/
     */
    [MapStyle.SATELLITE]: "mapbox://styles/skydio-team/clzhhvn1o00ld01rc70mngecd",
    /**
     * Unused, but kept for typing purposes, otherwise TypeScript complains
     */
    [MapStyle.SATELLITE_MUTED]: "mapbox://styles/skydio-team/cl6v40p6c000014plrmrtohtc",
  },
  [MapStyleGroups.internal_mapbox_styles_3]: {
    /**
     * Name: LVMPD Operational Area Internal - Light V9 No Labels
     *
     * Url: https://studio.mapbox.com/styles/skydio-team/clza29zhl007601r286d124wq/
     */
    [MapStyle.STREETS]: "mapbox://styles/skydio-team/clza29zhl007601r286d124wq",
    /**
     * Name: LVMPD Operational Area Internal - Satellite NO-LABELS
     *
     * Url: https://studio.mapbox.com/styles/skydio-team/clza2f1jw006z01r4dilqf025/
     */
    [MapStyle.SATELLITE]: "mapbox://styles/skydio-team/clza2f1jw006z01r4dilqf025",
    /**
     * Unused, but kept for typing purposes, otherwise TypeScript complains
     */
    [MapStyle.SATELLITE_MUTED]: "mapbox://styles/skydio-team/cl6v40p6c000014plrmrtohtc",
  },
  [MapStyleGroups.city_customer]: {
    /**
     * Name: SFPD Operational Area North Beach - Light V9 No Labels
     *
     * Url: https://studio.mapbox.com/styles/skydio-team/cm0o0x3c502ge01qresha1ga1/
     */
    [MapStyle.STREETS]: "mapbox://styles/skydio-team/cm0o0x3c502ge01qresha1ga1",
    /**
     * Name: SFPD Operational Area North Beach - Satellite NO-LABELS
     *
     * Url: https://studio.mapbox.com/styles/skydio-team/cm0o0xfsm001v01p90ewz23pc/
     */
    [MapStyle.SATELLITE]: "mapbox://styles/skydio-team/cm0o0xfsm001v01p90ewz23pc",
    /**
     * Unused, but kept for typing purposes, otherwise TypeScript complains
     */
    [MapStyle.SATELLITE_MUTED]: "mapbox://styles/skydio-team/cl6v40p6c000014plrmrtohtc",
  },
};

export const getNextMapStyle = (style: MapStyle) => (1 - style) as MapStyle;
export const getMapStyleUrl = (style: MapStyle, group: MapStyleGroups = MapStyleGroups.default) => {
  return MAP_STYLES_GROUPS[group][style];
};
export const getNextMapStyleUrl = (
  style: MapStyle,
  group: MapStyleGroups = MapStyleGroups.default
) => MAP_STYLE_GROUPS_NO_LABELS[group][(1 - style) as MapStyle];

// Distance between two lat/lng points in meters, uses haversine formula which should work well
// over small distances
// http://www.movable-type.co.uk/scripts/latlong.html
// https://en.wikipedia.org/wiki/Haversine_formula
export const distanceFromLatLng = (
  [lat1, lng1]: [number, number],
  [lat2, lng2]: [number, number]
) => {
  const EARTH_RADIUS = 6371e3; // meters
  const dLat = degToRad(lat2 - lat1);
  const dLng = degToRad(lng2 - lng1);
  const halfChordLength =
    Math.pow(Math.sin(dLat / 2), 2) +
    Math.cos(degToRad(lat1)) * Math.cos(degToRad(lat2)) * Math.pow(Math.sin(dLng / 2), 2);
  const angularDistance =
    2 * Math.atan2(Math.sqrt(halfChordLength), Math.sqrt(1 - halfChordLength));
  return EARTH_RADIUS * angularDistance;
};

export const getTotalFlightPathDistance = (flightPath: [number, number][]) =>
  flightPath.reduce((total, current, index, path) => {
    if (index === 0) return total;
    return total + distanceFromLatLng(current, path[index - 1]!);
  }, 0);

export const getCurrentCameraHeadingAngle = (
  gimbalNavTransformOrientation: number[],
  globalYawNav: number
) => {
  // gimbal_nav_transform.orientation.xyzw  is a quaternion, which is an array of 4 values (x,y,z,w)
  // e.g. const gimbal_orientation = new THREE.Quaternion(0.25, 0.4, -0.3, 0.1);
  const gimbalOrientation = new THREE.Quaternion(...gimbalNavTransformOrientation);

  // See https://www.notion.so/skydio/a0711600a7bf46ffa7a0fd9452bba1cd?pvs=4#bd4a13d8daec4ddf8482bf3f04fa3825
  // for frames explanations
  const navTCameraForward = new THREE.Vector3(0, 0, 1).applyQuaternion(gimbalOrientation);

  // In the general case, the camera forward axis shows the heading. In other terms, the angle
  // between nav X axis and the camera's forward vector is the heading.
  let gimbalYawNav = Math.atan2(navTCameraForward.y, navTCameraForward.x);

  // When close to the look up or down cases, the above will be noisy so we use an alternative
  if (Math.abs(navTCameraForward.z) > THRESHOLD_UP_OR_DOWN) {
    // In the look down case, the camera's up vector shows the heading. In other terms, the angle
    // between nav X axis and the camera's up vector is the heading.
    // In the look up case, it's the opposite of camera's up vector that shows the heading.
    const navTCameraUp = new THREE.Vector3(0, -1, 0).applyQuaternion(gimbalOrientation);
    const looksDown = navTCameraForward.z < 0;
    const navTCameraHeadingAxis = looksDown ? navTCameraUp : navTCameraUp.multiplyScalar(-1);
    gimbalYawNav = Math.atan2(navTCameraHeadingAxis.y, navTCameraHeadingAxis.x);
  }

  // add the global yaw nav offset to get the gimbal yaw angle in the global frame
  const gimbalYawGlobal = gimbalYawNav + globalYawNav;

  // convert to north facing bearing in degrees (if needed for map layout)
  return -(gimbalYawGlobal - Math.PI / 2) * (180 / Math.PI);
};
