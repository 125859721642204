import type { AlertInstance } from "./slice";

export type AlertLevel = "warning" | "caution" | "advisory";
type AudioConfig = { path: string; repeat?: boolean };
export type AlertType = {
  title: string;
  description?: string;
  level: AlertLevel;
  audio?: AudioConfig;
  priority: number;
};
export type ActiveAlertDetails = AlertInstance & AlertType;

/**
 * Defines the audio paths for all alerts. We define them in this array to ensure we cache all of
 * them when the alert manager is initialized
 */
const TRAFFIC_AUDIO_PATH = "/audio/traffic.mp3";
const ELAND_AUDIO_PATH = "/audio/e-land.mp3";
const LINK_LOSS_AUDIO_PATH = "/audio/link-loss.mp3";
const CAUTION_BEEP_AUDIO_PATH = "/audio/caution-beep.mp3";
const WARNING_BEEP_AUDIO_PATH = "/audio/warning-beep.mp3";
export const ALERT_AUDIO_PATHS = [
  TRAFFIC_AUDIO_PATH,
  ELAND_AUDIO_PATH,
  LINK_LOSS_AUDIO_PATH,
  CAUTION_BEEP_AUDIO_PATH,
  WARNING_BEEP_AUDIO_PATH,
];
/**
 * Defines all the alerts that are managed by the alert manager
 * We define them here to ensure there is not overlapping of priority
 * number between alerts
 */
export const ALERT_TYPES = {
  ADSB_TRAFFIC: (variant?: string): AlertType => ({
    level: "warning",
    priority: 0,
    title: "Air Traffic Alert",
    description: variant ? variant : "Execute safe state maneuver and monitor the map",
    audio: { path: TRAFFIC_AUDIO_PATH, repeat: true },
  }),
  EMERGENCY_LANDING: (variant?: string): AlertType => ({
    level: "warning",
    priority: 1,
    title: "Emergency Landing",
    description: variant ? variant : "Control aircraft to safe landing",
    audio: { path: ELAND_AUDIO_PATH, repeat: true },
  }),
  LOST_LINK: (variant?: string): AlertType => ({
    level: "warning",
    priority: 2,
    title: "Connection to Aircraft Lost",
    description: variant
      ? variant
      : "Vehicle will execute planned return after the connection timeout",
    audio: { path: LINK_LOSS_AUDIO_PATH, repeat: true },
  }),
  // Same alert as above but with caution level. Using same priority is fine because they will never be enabled together (only one of them will be enabled at a time depending on a feature flag)
  LOST_LINK_CAUTION: (variant?: string): AlertType => ({
    level: "caution",
    priority: 2,
    title: "Connection to Aircraft Lost",
    description: variant
      ? variant
      : "Vehicle will execute planned return after the connection timeout",
    audio: { path: CAUTION_BEEP_AUDIO_PATH },
  }),
  REMOTE_ID_FAULT: (variant?: string): AlertType => ({
    level: "caution",
    priority: 3,
    title: "Remote ID Inoperative",
    description: variant
      ? variant
      : "Check GPS function and connection of operator location source",
    audio: { path: CAUTION_BEEP_AUDIO_PATH },
  }),
  DEGRADED_LINK: (variant?: string): AlertType => ({
    level: "caution",
    priority: 4,
    title: "Weak Connection",
    description: variant ? variant : "",
    audio: { path: CAUTION_BEEP_AUDIO_PATH },
  }),
  SAFE_LANDING: (variant?: string): AlertType => ({
    level: "caution",
    priority: 5,
    title: "Safe Landing",
    description: variant ? variant : "Vehicle is returning to safe landing point",
    audio: { path: CAUTION_BEEP_AUDIO_PATH },
  }),
  GIMBAL_FLASHLIGHT_TOO_HOT: (variant?: string): AlertType => ({
    level: "advisory",
    priority: 6,
    title: "Flashlight Too Hot",
    description: variant ? variant : "The flashlight is being turned off due to overheating",
  }),
};

export type AlertId = keyof typeof ALERT_TYPES;
