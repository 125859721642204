// source: pbtypes/vehicle/infrastructure/flight_deck/settings.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_gen_user_camera_anti_banding_mode_pb = require('../../../../pbtypes/gen/user_camera/anti_banding_mode_pb.js');
goog.object.extend(proto, pbtypes_gen_user_camera_anti_banding_mode_pb);
var pbtypes_gen_user_camera_awb_mode_pb = require('../../../../pbtypes/gen/user_camera/awb_mode_pb.js');
goog.object.extend(proto, pbtypes_gen_user_camera_awb_mode_pb);
var pbtypes_gen_user_camera_auto_region_request_pb = require('../../../../pbtypes/gen/user_camera/auto_region_request_pb.js');
goog.object.extend(proto, pbtypes_gen_user_camera_auto_region_request_pb);
var pbtypes_gen_user_camera_autofocus_mode_pb = require('../../../../pbtypes/gen/user_camera/autofocus_mode_pb.js');
goog.object.extend(proto, pbtypes_gen_user_camera_autofocus_mode_pb);
var pbtypes_gen_user_camera_color_mode_pb = require('../../../../pbtypes/gen/user_camera/color_mode_pb.js');
goog.object.extend(proto, pbtypes_gen_user_camera_color_mode_pb);
var pbtypes_gen_user_camera_compensation_mode_pb = require('../../../../pbtypes/gen/user_camera/compensation_mode_pb.js');
goog.object.extend(proto, pbtypes_gen_user_camera_compensation_mode_pb);
var pbtypes_gen_user_camera_exposure_mode_pb = require('../../../../pbtypes/gen/user_camera/exposure_mode_pb.js');
goog.object.extend(proto, pbtypes_gen_user_camera_exposure_mode_pb);
var pbtypes_gen_user_camera_iso_mode_pb = require('../../../../pbtypes/gen/user_camera/iso_mode_pb.js');
goog.object.extend(proto, pbtypes_gen_user_camera_iso_mode_pb);
var pbtypes_gen_user_camera_photo_format_pb = require('../../../../pbtypes/gen/user_camera/photo_format_pb.js');
goog.object.extend(proto, pbtypes_gen_user_camera_photo_format_pb);
var pbtypes_gen_user_camera_recording_mode_pb = require('../../../../pbtypes/gen/user_camera/recording_mode_pb.js');
goog.object.extend(proto, pbtypes_gen_user_camera_recording_mode_pb);
var pbtypes_gen_user_camera_encoder_mode_pb = require('../../../../pbtypes/gen/user_camera/encoder_mode_pb.js');
goog.object.extend(proto, pbtypes_gen_user_camera_encoder_mode_pb);
var pbtypes_gen_user_camera_recording_bitrate_preset_pb = require('../../../../pbtypes/gen/user_camera/recording_bitrate_preset_pb.js');
goog.object.extend(proto, pbtypes_gen_user_camera_recording_bitrate_preset_pb);
var pbtypes_gen_flight_deck_rgb_led_mode_pb = require('../../../../pbtypes/gen/flight_deck/rgb_led_mode_pb.js');
goog.object.extend(proto, pbtypes_gen_flight_deck_rgb_led_mode_pb);
var pbtypes_gen_joysticks_joysticks_settings_pb = require('../../../../pbtypes/gen/joysticks/joysticks_settings_pb.js');
goog.object.extend(proto, pbtypes_gen_joysticks_joysticks_settings_pb);
var pbtypes_gen_phone_display_units_pb = require('../../../../pbtypes/gen/phone/display_units_pb.js');
goog.object.extend(proto, pbtypes_gen_phone_display_units_pb);
var pbtypes_gen_user_camera_photo_interval_mode_pb = require('../../../../pbtypes/gen/user_camera/photo_interval_mode_pb.js');
goog.object.extend(proto, pbtypes_gen_user_camera_photo_interval_mode_pb);
var pbtypes_gen_flir_boson_driver_agc_settings_pb = require('../../../../pbtypes/gen/flir_boson_driver/agc_settings_pb.js');
goog.object.extend(proto, pbtypes_gen_flir_boson_driver_agc_settings_pb);
var pbtypes_gen_flir_boson_driver_boson_settings_pb = require('../../../../pbtypes/gen/flir_boson_driver/boson_settings_pb.js');
goog.object.extend(proto, pbtypes_gen_flir_boson_driver_boson_settings_pb);
var pbtypes_gen_flir_boson_driver_colorlut_pb = require('../../../../pbtypes/gen/flir_boson_driver/colorlut_pb.js');
goog.object.extend(proto, pbtypes_gen_flir_boson_driver_colorlut_pb);
var pbtypes_gen_flir_boson_driver_isotherm_settings_pb = require('../../../../pbtypes/gen/flir_boson_driver/isotherm_settings_pb.js');
goog.object.extend(proto, pbtypes_gen_flir_boson_driver_isotherm_settings_pb);
var pbtypes_gen_flir_boson_driver_thermal_params_pb = require('../../../../pbtypes/gen/flir_boson_driver/thermal_params_pb.js');
goog.object.extend(proto, pbtypes_gen_flir_boson_driver_thermal_params_pb);
var pbtypes_gen_flir_boson_driver_roi_settings_pb = require('../../../../pbtypes/gen/flir_boson_driver/roi_settings_pb.js');
goog.object.extend(proto, pbtypes_gen_flir_boson_driver_roi_settings_pb);
var pbtypes_infrastructure_wifi_wifi_pb = require('../../../../pbtypes/infrastructure/wifi/wifi_pb.js');
goog.object.extend(proto, pbtypes_infrastructure_wifi_wifi_pb);
var pbtypes_util_geofence_geofence_pb = require('../../../../pbtypes/util/geofence/geofence_pb.js');
goog.object.extend(proto, pbtypes_util_geofence_geofence_pb);
var pbtypes_vehicle_health_monitor_fault_detectors_pb = require('../../../../pbtypes/vehicle/health_monitor/fault_detectors_pb.js');
goog.object.extend(proto, pbtypes_vehicle_health_monitor_fault_detectors_pb);
var pbtypes_vehicle_infrastructure_flight_deck_navigation_mode_pb = require('../../../../pbtypes/vehicle/infrastructure/flight_deck/navigation_mode_pb.js');
goog.object.extend(proto, pbtypes_vehicle_infrastructure_flight_deck_navigation_mode_pb);
var pbtypes_vehicle_infrastructure_flight_deck_return_settings_pb = require('../../../../pbtypes/vehicle/infrastructure/flight_deck/return_settings_pb.js');
goog.object.extend(proto, pbtypes_vehicle_infrastructure_flight_deck_return_settings_pb);
goog.exportSymbol('proto.flight_deck.AllSettings', null, global);
goog.exportSymbol('proto.flight_deck.BeaconSettings', null, global);
goog.exportSymbol('proto.flight_deck.CaptureSettings', null, global);
goog.exportSymbol('proto.flight_deck.CaptureSettings.CaptureSettingsSource', null, global);
goog.exportSymbol('proto.flight_deck.IrAuxillaryPersistedSettings', null, global);
goog.exportSymbol('proto.flight_deck.IrCaptureSettings', null, global);
goog.exportSymbol('proto.flight_deck.MavlinkClientSettings', null, global);
goog.exportSymbol('proto.flight_deck.MavlinkClientSettings.MavlinkGeofenceAction', null, global);
goog.exportSymbol('proto.flight_deck.RemoteIDAuthSettings', null, global);
goog.exportSymbol('proto.flight_deck.SettingsPreviewSource', null, global);
goog.exportSymbol('proto.flight_deck.SettingsUpdateResponse', null, global);
goog.exportSymbol('proto.flight_deck.VehicleSettings', null, global);
goog.exportSymbol('proto.flight_deck.VehicleSettings.FlightMode', null, global);
goog.exportSymbol('proto.flight_deck.VehicleSettings.IlluminationMode', null, global);
goog.exportSymbol('proto.flight_deck.VehicleSettings.LandingLedMode', null, global);
goog.exportSymbol('proto.flight_deck.VehicleSettings.ObstacleMarginMode', null, global);
goog.exportSymbol('proto.flight_deck.VehicleSettings.WifiBand', null, global);
goog.exportSymbol('proto.flight_deck.VehicleSettingsOverrides', null, global);
goog.exportSymbol('proto.flight_deck.VehicleSettingsOverrides.BooleanOverride', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flight_deck.AllSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flight_deck.AllSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flight_deck.AllSettings.displayName = 'proto.flight_deck.AllSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flight_deck.BeaconSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.flight_deck.BeaconSettings.repeatedFields_, null);
};
goog.inherits(proto.flight_deck.BeaconSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flight_deck.BeaconSettings.displayName = 'proto.flight_deck.BeaconSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flight_deck.CaptureSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flight_deck.CaptureSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flight_deck.CaptureSettings.displayName = 'proto.flight_deck.CaptureSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flight_deck.IrCaptureSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flight_deck.IrCaptureSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flight_deck.IrCaptureSettings.displayName = 'proto.flight_deck.IrCaptureSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flight_deck.IrAuxillaryPersistedSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flight_deck.IrAuxillaryPersistedSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flight_deck.IrAuxillaryPersistedSettings.displayName = 'proto.flight_deck.IrAuxillaryPersistedSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flight_deck.RemoteIDAuthSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flight_deck.RemoteIDAuthSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flight_deck.RemoteIDAuthSettings.displayName = 'proto.flight_deck.RemoteIDAuthSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flight_deck.MavlinkClientSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flight_deck.MavlinkClientSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flight_deck.MavlinkClientSettings.displayName = 'proto.flight_deck.MavlinkClientSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flight_deck.VehicleSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.flight_deck.VehicleSettings.repeatedFields_, null);
};
goog.inherits(proto.flight_deck.VehicleSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flight_deck.VehicleSettings.displayName = 'proto.flight_deck.VehicleSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flight_deck.VehicleSettingsOverrides = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.flight_deck.VehicleSettingsOverrides.repeatedFields_, null);
};
goog.inherits(proto.flight_deck.VehicleSettingsOverrides, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flight_deck.VehicleSettingsOverrides.displayName = 'proto.flight_deck.VehicleSettingsOverrides';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flight_deck.SettingsUpdateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flight_deck.SettingsUpdateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flight_deck.SettingsUpdateResponse.displayName = 'proto.flight_deck.SettingsUpdateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flight_deck.SettingsPreviewSource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flight_deck.SettingsPreviewSource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flight_deck.SettingsPreviewSource.displayName = 'proto.flight_deck.SettingsPreviewSource';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flight_deck.AllSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.flight_deck.AllSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flight_deck.AllSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.AllSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
beaconSettings: (f = msg.getBeaconSettings()) && proto.flight_deck.BeaconSettings.toObject(includeInstance, f),
joysticksSettings: (f = msg.getJoysticksSettings()) && pbtypes_gen_joysticks_joysticks_settings_pb.JoysticksSettings.toObject(includeInstance, f),
captureSettings: (f = msg.getCaptureSettings()) && proto.flight_deck.CaptureSettings.toObject(includeInstance, f),
vehicleSettings: (f = msg.getVehicleSettings()) && proto.flight_deck.VehicleSettings.toObject(includeInstance, f),
irCaptureSettings: (f = msg.getIrCaptureSettings()) && proto.flight_deck.IrCaptureSettings.toObject(includeInstance, f),
remoteidAuthSettings: (f = msg.getRemoteidAuthSettings()) && proto.flight_deck.RemoteIDAuthSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flight_deck.AllSettings}
 */
proto.flight_deck.AllSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flight_deck.AllSettings;
  return proto.flight_deck.AllSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flight_deck.AllSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flight_deck.AllSettings}
 */
proto.flight_deck.AllSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.flight_deck.BeaconSettings;
      reader.readMessage(value,proto.flight_deck.BeaconSettings.deserializeBinaryFromReader);
      msg.setBeaconSettings(value);
      break;
    case 2:
      var value = new pbtypes_gen_joysticks_joysticks_settings_pb.JoysticksSettings;
      reader.readMessage(value,pbtypes_gen_joysticks_joysticks_settings_pb.JoysticksSettings.deserializeBinaryFromReader);
      msg.setJoysticksSettings(value);
      break;
    case 3:
      var value = new proto.flight_deck.CaptureSettings;
      reader.readMessage(value,proto.flight_deck.CaptureSettings.deserializeBinaryFromReader);
      msg.setCaptureSettings(value);
      break;
    case 4:
      var value = new proto.flight_deck.VehicleSettings;
      reader.readMessage(value,proto.flight_deck.VehicleSettings.deserializeBinaryFromReader);
      msg.setVehicleSettings(value);
      break;
    case 5:
      var value = new proto.flight_deck.IrCaptureSettings;
      reader.readMessage(value,proto.flight_deck.IrCaptureSettings.deserializeBinaryFromReader);
      msg.setIrCaptureSettings(value);
      break;
    case 6:
      var value = new proto.flight_deck.RemoteIDAuthSettings;
      reader.readMessage(value,proto.flight_deck.RemoteIDAuthSettings.deserializeBinaryFromReader);
      msg.setRemoteidAuthSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flight_deck.AllSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flight_deck.AllSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flight_deck.AllSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.AllSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBeaconSettings();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.flight_deck.BeaconSettings.serializeBinaryToWriter
    );
  }
  f = message.getJoysticksSettings();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_gen_joysticks_joysticks_settings_pb.JoysticksSettings.serializeBinaryToWriter
    );
  }
  f = message.getCaptureSettings();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.flight_deck.CaptureSettings.serializeBinaryToWriter
    );
  }
  f = message.getVehicleSettings();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.flight_deck.VehicleSettings.serializeBinaryToWriter
    );
  }
  f = message.getIrCaptureSettings();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.flight_deck.IrCaptureSettings.serializeBinaryToWriter
    );
  }
  f = message.getRemoteidAuthSettings();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.flight_deck.RemoteIDAuthSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional BeaconSettings beacon_settings = 1;
 * @return {?proto.flight_deck.BeaconSettings}
 */
proto.flight_deck.AllSettings.prototype.getBeaconSettings = function() {
  return /** @type{?proto.flight_deck.BeaconSettings} */ (
    jspb.Message.getWrapperField(this, proto.flight_deck.BeaconSettings, 1));
};


/**
 * @param {?proto.flight_deck.BeaconSettings|undefined} value
 * @return {!proto.flight_deck.AllSettings} returns this
*/
proto.flight_deck.AllSettings.prototype.setBeaconSettings = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flight_deck.AllSettings} returns this
 */
proto.flight_deck.AllSettings.prototype.clearBeaconSettings = function() {
  return this.setBeaconSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flight_deck.AllSettings.prototype.hasBeaconSettings = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional joysticks.JoysticksSettings joysticks_settings = 2;
 * @return {?proto.joysticks.JoysticksSettings}
 */
proto.flight_deck.AllSettings.prototype.getJoysticksSettings = function() {
  return /** @type{?proto.joysticks.JoysticksSettings} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_joysticks_joysticks_settings_pb.JoysticksSettings, 2));
};


/**
 * @param {?proto.joysticks.JoysticksSettings|undefined} value
 * @return {!proto.flight_deck.AllSettings} returns this
*/
proto.flight_deck.AllSettings.prototype.setJoysticksSettings = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flight_deck.AllSettings} returns this
 */
proto.flight_deck.AllSettings.prototype.clearJoysticksSettings = function() {
  return this.setJoysticksSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flight_deck.AllSettings.prototype.hasJoysticksSettings = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional CaptureSettings capture_settings = 3;
 * @return {?proto.flight_deck.CaptureSettings}
 */
proto.flight_deck.AllSettings.prototype.getCaptureSettings = function() {
  return /** @type{?proto.flight_deck.CaptureSettings} */ (
    jspb.Message.getWrapperField(this, proto.flight_deck.CaptureSettings, 3));
};


/**
 * @param {?proto.flight_deck.CaptureSettings|undefined} value
 * @return {!proto.flight_deck.AllSettings} returns this
*/
proto.flight_deck.AllSettings.prototype.setCaptureSettings = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flight_deck.AllSettings} returns this
 */
proto.flight_deck.AllSettings.prototype.clearCaptureSettings = function() {
  return this.setCaptureSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flight_deck.AllSettings.prototype.hasCaptureSettings = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional VehicleSettings vehicle_settings = 4;
 * @return {?proto.flight_deck.VehicleSettings}
 */
proto.flight_deck.AllSettings.prototype.getVehicleSettings = function() {
  return /** @type{?proto.flight_deck.VehicleSettings} */ (
    jspb.Message.getWrapperField(this, proto.flight_deck.VehicleSettings, 4));
};


/**
 * @param {?proto.flight_deck.VehicleSettings|undefined} value
 * @return {!proto.flight_deck.AllSettings} returns this
*/
proto.flight_deck.AllSettings.prototype.setVehicleSettings = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flight_deck.AllSettings} returns this
 */
proto.flight_deck.AllSettings.prototype.clearVehicleSettings = function() {
  return this.setVehicleSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flight_deck.AllSettings.prototype.hasVehicleSettings = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional IrCaptureSettings ir_capture_settings = 5;
 * @return {?proto.flight_deck.IrCaptureSettings}
 */
proto.flight_deck.AllSettings.prototype.getIrCaptureSettings = function() {
  return /** @type{?proto.flight_deck.IrCaptureSettings} */ (
    jspb.Message.getWrapperField(this, proto.flight_deck.IrCaptureSettings, 5));
};


/**
 * @param {?proto.flight_deck.IrCaptureSettings|undefined} value
 * @return {!proto.flight_deck.AllSettings} returns this
*/
proto.flight_deck.AllSettings.prototype.setIrCaptureSettings = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flight_deck.AllSettings} returns this
 */
proto.flight_deck.AllSettings.prototype.clearIrCaptureSettings = function() {
  return this.setIrCaptureSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flight_deck.AllSettings.prototype.hasIrCaptureSettings = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional RemoteIDAuthSettings remoteid_auth_settings = 6;
 * @return {?proto.flight_deck.RemoteIDAuthSettings}
 */
proto.flight_deck.AllSettings.prototype.getRemoteidAuthSettings = function() {
  return /** @type{?proto.flight_deck.RemoteIDAuthSettings} */ (
    jspb.Message.getWrapperField(this, proto.flight_deck.RemoteIDAuthSettings, 6));
};


/**
 * @param {?proto.flight_deck.RemoteIDAuthSettings|undefined} value
 * @return {!proto.flight_deck.AllSettings} returns this
*/
proto.flight_deck.AllSettings.prototype.setRemoteidAuthSettings = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flight_deck.AllSettings} returns this
 */
proto.flight_deck.AllSettings.prototype.clearRemoteidAuthSettings = function() {
  return this.setRemoteidAuthSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flight_deck.AllSettings.prototype.hasRemoteidAuthSettings = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.flight_deck.BeaconSettings.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flight_deck.BeaconSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.flight_deck.BeaconSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flight_deck.BeaconSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.BeaconSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
selectedSkillsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
locked: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
displayUnits: jspb.Message.getFieldWithDefault(msg, 4, 0),
isConsumerAppConnected: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flight_deck.BeaconSettings}
 */
proto.flight_deck.BeaconSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flight_deck.BeaconSettings;
  return proto.flight_deck.BeaconSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flight_deck.BeaconSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flight_deck.BeaconSettings}
 */
proto.flight_deck.BeaconSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addSelectedSkills(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLocked(value);
      break;
    case 4:
      var value = /** @type {!proto.phone.DisplayUnits.Enum} */ (reader.readEnum());
      msg.setDisplayUnits(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsConsumerAppConnected(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flight_deck.BeaconSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flight_deck.BeaconSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flight_deck.BeaconSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.BeaconSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSelectedSkillsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getLocked();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getDisplayUnits();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getIsConsumerAppConnected();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.flight_deck.BeaconSettings.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.flight_deck.BeaconSettings} returns this
 */
proto.flight_deck.BeaconSettings.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated string selected_skills = 2;
 * @return {!Array<string>}
 */
proto.flight_deck.BeaconSettings.prototype.getSelectedSkillsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.flight_deck.BeaconSettings} returns this
 */
proto.flight_deck.BeaconSettings.prototype.setSelectedSkillsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.flight_deck.BeaconSettings} returns this
 */
proto.flight_deck.BeaconSettings.prototype.addSelectedSkills = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flight_deck.BeaconSettings} returns this
 */
proto.flight_deck.BeaconSettings.prototype.clearSelectedSkillsList = function() {
  return this.setSelectedSkillsList([]);
};


/**
 * optional bool locked = 3;
 * @return {boolean}
 */
proto.flight_deck.BeaconSettings.prototype.getLocked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.BeaconSettings} returns this
 */
proto.flight_deck.BeaconSettings.prototype.setLocked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional phone.DisplayUnits.Enum display_units = 4;
 * @return {!proto.phone.DisplayUnits.Enum}
 */
proto.flight_deck.BeaconSettings.prototype.getDisplayUnits = function() {
  return /** @type {!proto.phone.DisplayUnits.Enum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.phone.DisplayUnits.Enum} value
 * @return {!proto.flight_deck.BeaconSettings} returns this
 */
proto.flight_deck.BeaconSettings.prototype.setDisplayUnits = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional bool is_consumer_app_connected = 5;
 * @return {boolean}
 */
proto.flight_deck.BeaconSettings.prototype.getIsConsumerAppConnected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.BeaconSettings} returns this
 */
proto.flight_deck.BeaconSettings.prototype.setIsConsumerAppConnected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flight_deck.CaptureSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.flight_deck.CaptureSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flight_deck.CaptureSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.CaptureSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
validationId: jspb.Message.getFieldWithDefault(msg, 18, 0),
source: jspb.Message.getFieldWithDefault(msg, 23, 0),
recordingMode: jspb.Message.getFieldWithDefault(msg, 2, 0),
videoAutoRecord: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
photoFormat: jspb.Message.getFieldWithDefault(msg, 8, 0),
photoInterval: jspb.Message.getFieldWithDefault(msg, 9, 0),
iso: jspb.Message.getFieldWithDefault(msg, 4, 0),
exposure: jspb.Message.getFieldWithDefault(msg, 5, 0),
compensation: jspb.Message.getFieldWithDefault(msg, 7, 0),
whiteBalance: jspb.Message.getFieldWithDefault(msg, 6, 0),
encoderMode: jspb.Message.getFieldWithDefault(msg, 10, 0),
streamEncoderMode: jspb.Message.getFieldWithDefault(msg, 22, 0),
colorMode: jspb.Message.getFieldWithDefault(msg, 11, 0),
userBitratePreset: jspb.Message.getFieldWithDefault(msg, 13, 0),
autofocusRegion: (f = msg.getAutofocusRegion()) && pbtypes_gen_user_camera_auto_region_request_pb.AutoRegionRequest.toObject(includeInstance, f),
autoexposureRegion: (f = msg.getAutoexposureRegion()) && pbtypes_gen_user_camera_auto_region_request_pb.AutoRegionRequest.toObject(includeInstance, f),
autofocusMode: jspb.Message.getFieldWithDefault(msg, 20, 0),
manualFocusPosition: jspb.Message.getFloatingPointFieldWithDefault(msg, 24, 0.0),
userBitrateOverride: jspb.Message.getFieldWithDefault(msg, 15, 0),
antiBandingMode: jspb.Message.getFieldWithDefault(msg, 14, 0),
photo15mpEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
publishThumbnail: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
thumbnailWidth: jspb.Message.getFieldWithDefault(msg, 17, 0),
videoRecordingMode: jspb.Message.getFieldWithDefault(msg, 25, 0),
photoRecordingMode: jspb.Message.getFieldWithDefault(msg, 26, 0),
customFolder: jspb.Message.getFieldWithDefault(msg, 27, ""),
useCustomFolder: jspb.Message.getBooleanFieldWithDefault(msg, 28, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flight_deck.CaptureSettings}
 */
proto.flight_deck.CaptureSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flight_deck.CaptureSettings;
  return proto.flight_deck.CaptureSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flight_deck.CaptureSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flight_deck.CaptureSettings}
 */
proto.flight_deck.CaptureSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValidationId(value);
      break;
    case 23:
      var value = /** @type {!proto.flight_deck.CaptureSettings.CaptureSettingsSource} */ (reader.readEnum());
      msg.setSource(value);
      break;
    case 2:
      var value = /** @type {!proto.user_camera.RecordingMode.Enum} */ (reader.readEnum());
      msg.setRecordingMode(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVideoAutoRecord(value);
      break;
    case 8:
      var value = /** @type {!proto.user_camera.PhotoFormat.Enum} */ (reader.readEnum());
      msg.setPhotoFormat(value);
      break;
    case 9:
      var value = /** @type {!proto.user_camera.PhotoIntervalMode.Enum} */ (reader.readEnum());
      msg.setPhotoInterval(value);
      break;
    case 4:
      var value = /** @type {!proto.user_camera.IsoMode.Enum} */ (reader.readEnum());
      msg.setIso(value);
      break;
    case 5:
      var value = /** @type {!proto.user_camera.ExposureMode.Enum} */ (reader.readEnum());
      msg.setExposure(value);
      break;
    case 7:
      var value = /** @type {!proto.user_camera.CompensationMode.Enum} */ (reader.readEnum());
      msg.setCompensation(value);
      break;
    case 6:
      var value = /** @type {!proto.user_camera.AwbMode.Enum} */ (reader.readEnum());
      msg.setWhiteBalance(value);
      break;
    case 10:
      var value = /** @type {!proto.user_camera.EncoderMode.Enum} */ (reader.readEnum());
      msg.setEncoderMode(value);
      break;
    case 22:
      var value = /** @type {!proto.user_camera.EncoderMode.Enum} */ (reader.readEnum());
      msg.setStreamEncoderMode(value);
      break;
    case 11:
      var value = /** @type {!proto.user_camera.ColorMode.Enum} */ (reader.readEnum());
      msg.setColorMode(value);
      break;
    case 13:
      var value = /** @type {!proto.user_camera.RecordingBitratePreset.Enum} */ (reader.readEnum());
      msg.setUserBitratePreset(value);
      break;
    case 19:
      var value = new pbtypes_gen_user_camera_auto_region_request_pb.AutoRegionRequest;
      reader.readMessage(value,pbtypes_gen_user_camera_auto_region_request_pb.AutoRegionRequest.deserializeBinaryFromReader);
      msg.setAutofocusRegion(value);
      break;
    case 21:
      var value = new pbtypes_gen_user_camera_auto_region_request_pb.AutoRegionRequest;
      reader.readMessage(value,pbtypes_gen_user_camera_auto_region_request_pb.AutoRegionRequest.deserializeBinaryFromReader);
      msg.setAutoexposureRegion(value);
      break;
    case 20:
      var value = /** @type {!proto.user_camera.AutofocusMode.Enum} */ (reader.readEnum());
      msg.setAutofocusMode(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setManualFocusPosition(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserBitrateOverride(value);
      break;
    case 14:
      var value = /** @type {!proto.user_camera.AntiBandingMode.Enum} */ (reader.readEnum());
      msg.setAntiBandingMode(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPhoto15mpEnabled(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPublishThumbnail(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setThumbnailWidth(value);
      break;
    case 25:
      var value = /** @type {!proto.user_camera.RecordingMode.Enum} */ (reader.readEnum());
      msg.setVideoRecordingMode(value);
      break;
    case 26:
      var value = /** @type {!proto.user_camera.RecordingMode.Enum} */ (reader.readEnum());
      msg.setPhotoRecordingMode(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomFolder(value);
      break;
    case 28:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseCustomFolder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flight_deck.CaptureSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flight_deck.CaptureSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flight_deck.CaptureSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.CaptureSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getValidationId();
  if (f !== 0) {
    writer.writeInt32(
      18,
      f
    );
  }
  f = message.getSource();
  if (f !== 0.0) {
    writer.writeEnum(
      23,
      f
    );
  }
  f = message.getRecordingMode();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getVideoAutoRecord();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getPhotoFormat();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getPhotoInterval();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getIso();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getExposure();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getCompensation();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getWhiteBalance();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getEncoderMode();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getStreamEncoderMode();
  if (f !== 0.0) {
    writer.writeEnum(
      22,
      f
    );
  }
  f = message.getColorMode();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getUserBitratePreset();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getAutofocusRegion();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      pbtypes_gen_user_camera_auto_region_request_pb.AutoRegionRequest.serializeBinaryToWriter
    );
  }
  f = message.getAutoexposureRegion();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      pbtypes_gen_user_camera_auto_region_request_pb.AutoRegionRequest.serializeBinaryToWriter
    );
  }
  f = message.getAutofocusMode();
  if (f !== 0.0) {
    writer.writeEnum(
      20,
      f
    );
  }
  f = message.getManualFocusPosition();
  if (f !== 0.0) {
    writer.writeFloat(
      24,
      f
    );
  }
  f = message.getUserBitrateOverride();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getAntiBandingMode();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getPhoto15mpEnabled();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getPublishThumbnail();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getThumbnailWidth();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getVideoRecordingMode();
  if (f !== 0.0) {
    writer.writeEnum(
      25,
      f
    );
  }
  f = message.getPhotoRecordingMode();
  if (f !== 0.0) {
    writer.writeEnum(
      26,
      f
    );
  }
  f = message.getCustomFolder();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getUseCustomFolder();
  if (f) {
    writer.writeBool(
      28,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.flight_deck.CaptureSettings.CaptureSettingsSource = {
  UNSPECIFIED: 0,
  SKILLS: 1,
  MOBILE_APP: 2,
  TELEOP_UI: 3,
  LANDING_PAD_FRONTEND: 4,
  MAVLINK: 5,
  CAMERA_SETTINGS_EXECUTABLE: 6
};

/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.flight_deck.CaptureSettings.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.flight_deck.CaptureSettings} returns this
 */
proto.flight_deck.CaptureSettings.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 validation_id = 18;
 * @return {number}
 */
proto.flight_deck.CaptureSettings.prototype.getValidationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.flight_deck.CaptureSettings} returns this
 */
proto.flight_deck.CaptureSettings.prototype.setValidationId = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional CaptureSettingsSource source = 23;
 * @return {!proto.flight_deck.CaptureSettings.CaptureSettingsSource}
 */
proto.flight_deck.CaptureSettings.prototype.getSource = function() {
  return /** @type {!proto.flight_deck.CaptureSettings.CaptureSettingsSource} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {!proto.flight_deck.CaptureSettings.CaptureSettingsSource} value
 * @return {!proto.flight_deck.CaptureSettings} returns this
 */
proto.flight_deck.CaptureSettings.prototype.setSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 23, value);
};


/**
 * optional user_camera.RecordingMode.Enum recording_mode = 2;
 * @return {!proto.user_camera.RecordingMode.Enum}
 */
proto.flight_deck.CaptureSettings.prototype.getRecordingMode = function() {
  return /** @type {!proto.user_camera.RecordingMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.user_camera.RecordingMode.Enum} value
 * @return {!proto.flight_deck.CaptureSettings} returns this
 */
proto.flight_deck.CaptureSettings.prototype.setRecordingMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional bool video_auto_record = 3;
 * @return {boolean}
 */
proto.flight_deck.CaptureSettings.prototype.getVideoAutoRecord = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.CaptureSettings} returns this
 */
proto.flight_deck.CaptureSettings.prototype.setVideoAutoRecord = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional user_camera.PhotoFormat.Enum photo_format = 8;
 * @return {!proto.user_camera.PhotoFormat.Enum}
 */
proto.flight_deck.CaptureSettings.prototype.getPhotoFormat = function() {
  return /** @type {!proto.user_camera.PhotoFormat.Enum} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.user_camera.PhotoFormat.Enum} value
 * @return {!proto.flight_deck.CaptureSettings} returns this
 */
proto.flight_deck.CaptureSettings.prototype.setPhotoFormat = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional user_camera.PhotoIntervalMode.Enum photo_interval = 9;
 * @return {!proto.user_camera.PhotoIntervalMode.Enum}
 */
proto.flight_deck.CaptureSettings.prototype.getPhotoInterval = function() {
  return /** @type {!proto.user_camera.PhotoIntervalMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.user_camera.PhotoIntervalMode.Enum} value
 * @return {!proto.flight_deck.CaptureSettings} returns this
 */
proto.flight_deck.CaptureSettings.prototype.setPhotoInterval = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional user_camera.IsoMode.Enum iso = 4;
 * @return {!proto.user_camera.IsoMode.Enum}
 */
proto.flight_deck.CaptureSettings.prototype.getIso = function() {
  return /** @type {!proto.user_camera.IsoMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.user_camera.IsoMode.Enum} value
 * @return {!proto.flight_deck.CaptureSettings} returns this
 */
proto.flight_deck.CaptureSettings.prototype.setIso = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional user_camera.ExposureMode.Enum exposure = 5;
 * @return {!proto.user_camera.ExposureMode.Enum}
 */
proto.flight_deck.CaptureSettings.prototype.getExposure = function() {
  return /** @type {!proto.user_camera.ExposureMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.user_camera.ExposureMode.Enum} value
 * @return {!proto.flight_deck.CaptureSettings} returns this
 */
proto.flight_deck.CaptureSettings.prototype.setExposure = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional user_camera.CompensationMode.Enum compensation = 7;
 * @return {!proto.user_camera.CompensationMode.Enum}
 */
proto.flight_deck.CaptureSettings.prototype.getCompensation = function() {
  return /** @type {!proto.user_camera.CompensationMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.user_camera.CompensationMode.Enum} value
 * @return {!proto.flight_deck.CaptureSettings} returns this
 */
proto.flight_deck.CaptureSettings.prototype.setCompensation = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional user_camera.AwbMode.Enum white_balance = 6;
 * @return {!proto.user_camera.AwbMode.Enum}
 */
proto.flight_deck.CaptureSettings.prototype.getWhiteBalance = function() {
  return /** @type {!proto.user_camera.AwbMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.user_camera.AwbMode.Enum} value
 * @return {!proto.flight_deck.CaptureSettings} returns this
 */
proto.flight_deck.CaptureSettings.prototype.setWhiteBalance = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional user_camera.EncoderMode.Enum encoder_mode = 10;
 * @return {!proto.user_camera.EncoderMode.Enum}
 */
proto.flight_deck.CaptureSettings.prototype.getEncoderMode = function() {
  return /** @type {!proto.user_camera.EncoderMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.user_camera.EncoderMode.Enum} value
 * @return {!proto.flight_deck.CaptureSettings} returns this
 */
proto.flight_deck.CaptureSettings.prototype.setEncoderMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional user_camera.EncoderMode.Enum stream_encoder_mode = 22;
 * @return {!proto.user_camera.EncoderMode.Enum}
 */
proto.flight_deck.CaptureSettings.prototype.getStreamEncoderMode = function() {
  return /** @type {!proto.user_camera.EncoderMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {!proto.user_camera.EncoderMode.Enum} value
 * @return {!proto.flight_deck.CaptureSettings} returns this
 */
proto.flight_deck.CaptureSettings.prototype.setStreamEncoderMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 22, value);
};


/**
 * optional user_camera.ColorMode.Enum color_mode = 11;
 * @return {!proto.user_camera.ColorMode.Enum}
 */
proto.flight_deck.CaptureSettings.prototype.getColorMode = function() {
  return /** @type {!proto.user_camera.ColorMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.user_camera.ColorMode.Enum} value
 * @return {!proto.flight_deck.CaptureSettings} returns this
 */
proto.flight_deck.CaptureSettings.prototype.setColorMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional user_camera.RecordingBitratePreset.Enum user_bitrate_preset = 13;
 * @return {!proto.user_camera.RecordingBitratePreset.Enum}
 */
proto.flight_deck.CaptureSettings.prototype.getUserBitratePreset = function() {
  return /** @type {!proto.user_camera.RecordingBitratePreset.Enum} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.user_camera.RecordingBitratePreset.Enum} value
 * @return {!proto.flight_deck.CaptureSettings} returns this
 */
proto.flight_deck.CaptureSettings.prototype.setUserBitratePreset = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional user_camera.AutoRegionRequest autofocus_region = 19;
 * @return {?proto.user_camera.AutoRegionRequest}
 */
proto.flight_deck.CaptureSettings.prototype.getAutofocusRegion = function() {
  return /** @type{?proto.user_camera.AutoRegionRequest} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_user_camera_auto_region_request_pb.AutoRegionRequest, 19));
};


/**
 * @param {?proto.user_camera.AutoRegionRequest|undefined} value
 * @return {!proto.flight_deck.CaptureSettings} returns this
*/
proto.flight_deck.CaptureSettings.prototype.setAutofocusRegion = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flight_deck.CaptureSettings} returns this
 */
proto.flight_deck.CaptureSettings.prototype.clearAutofocusRegion = function() {
  return this.setAutofocusRegion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flight_deck.CaptureSettings.prototype.hasAutofocusRegion = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional user_camera.AutoRegionRequest autoexposure_region = 21;
 * @return {?proto.user_camera.AutoRegionRequest}
 */
proto.flight_deck.CaptureSettings.prototype.getAutoexposureRegion = function() {
  return /** @type{?proto.user_camera.AutoRegionRequest} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_user_camera_auto_region_request_pb.AutoRegionRequest, 21));
};


/**
 * @param {?proto.user_camera.AutoRegionRequest|undefined} value
 * @return {!proto.flight_deck.CaptureSettings} returns this
*/
proto.flight_deck.CaptureSettings.prototype.setAutoexposureRegion = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flight_deck.CaptureSettings} returns this
 */
proto.flight_deck.CaptureSettings.prototype.clearAutoexposureRegion = function() {
  return this.setAutoexposureRegion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flight_deck.CaptureSettings.prototype.hasAutoexposureRegion = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional user_camera.AutofocusMode.Enum autofocus_mode = 20;
 * @return {!proto.user_camera.AutofocusMode.Enum}
 */
proto.flight_deck.CaptureSettings.prototype.getAutofocusMode = function() {
  return /** @type {!proto.user_camera.AutofocusMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {!proto.user_camera.AutofocusMode.Enum} value
 * @return {!proto.flight_deck.CaptureSettings} returns this
 */
proto.flight_deck.CaptureSettings.prototype.setAutofocusMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 20, value);
};


/**
 * optional float manual_focus_position = 24;
 * @return {number}
 */
proto.flight_deck.CaptureSettings.prototype.getManualFocusPosition = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 24, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.flight_deck.CaptureSettings} returns this
 */
proto.flight_deck.CaptureSettings.prototype.setManualFocusPosition = function(value) {
  return jspb.Message.setProto3FloatField(this, 24, value);
};


/**
 * optional int32 user_bitrate_override = 15;
 * @return {number}
 */
proto.flight_deck.CaptureSettings.prototype.getUserBitrateOverride = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.flight_deck.CaptureSettings} returns this
 */
proto.flight_deck.CaptureSettings.prototype.setUserBitrateOverride = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional user_camera.AntiBandingMode.Enum anti_banding_mode = 14;
 * @return {!proto.user_camera.AntiBandingMode.Enum}
 */
proto.flight_deck.CaptureSettings.prototype.getAntiBandingMode = function() {
  return /** @type {!proto.user_camera.AntiBandingMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.user_camera.AntiBandingMode.Enum} value
 * @return {!proto.flight_deck.CaptureSettings} returns this
 */
proto.flight_deck.CaptureSettings.prototype.setAntiBandingMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional bool photo_15mp_enabled = 12;
 * @return {boolean}
 */
proto.flight_deck.CaptureSettings.prototype.getPhoto15mpEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.CaptureSettings} returns this
 */
proto.flight_deck.CaptureSettings.prototype.setPhoto15mpEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool publish_thumbnail = 16;
 * @return {boolean}
 */
proto.flight_deck.CaptureSettings.prototype.getPublishThumbnail = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.CaptureSettings} returns this
 */
proto.flight_deck.CaptureSettings.prototype.setPublishThumbnail = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional int32 thumbnail_width = 17;
 * @return {number}
 */
proto.flight_deck.CaptureSettings.prototype.getThumbnailWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.flight_deck.CaptureSettings} returns this
 */
proto.flight_deck.CaptureSettings.prototype.setThumbnailWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional user_camera.RecordingMode.Enum video_recording_mode = 25;
 * @return {!proto.user_camera.RecordingMode.Enum}
 */
proto.flight_deck.CaptureSettings.prototype.getVideoRecordingMode = function() {
  return /** @type {!proto.user_camera.RecordingMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {!proto.user_camera.RecordingMode.Enum} value
 * @return {!proto.flight_deck.CaptureSettings} returns this
 */
proto.flight_deck.CaptureSettings.prototype.setVideoRecordingMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 25, value);
};


/**
 * optional user_camera.RecordingMode.Enum photo_recording_mode = 26;
 * @return {!proto.user_camera.RecordingMode.Enum}
 */
proto.flight_deck.CaptureSettings.prototype.getPhotoRecordingMode = function() {
  return /** @type {!proto.user_camera.RecordingMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {!proto.user_camera.RecordingMode.Enum} value
 * @return {!proto.flight_deck.CaptureSettings} returns this
 */
proto.flight_deck.CaptureSettings.prototype.setPhotoRecordingMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 26, value);
};


/**
 * optional string custom_folder = 27;
 * @return {string}
 */
proto.flight_deck.CaptureSettings.prototype.getCustomFolder = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.flight_deck.CaptureSettings} returns this
 */
proto.flight_deck.CaptureSettings.prototype.setCustomFolder = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional bool use_custom_folder = 28;
 * @return {boolean}
 */
proto.flight_deck.CaptureSettings.prototype.getUseCustomFolder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 28, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.CaptureSettings} returns this
 */
proto.flight_deck.CaptureSettings.prototype.setUseCustomFolder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 28, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flight_deck.IrCaptureSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.flight_deck.IrCaptureSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flight_deck.IrCaptureSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.IrCaptureSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
colorlut: jspb.Message.getFieldWithDefault(msg, 2, 0),
photoDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
photoFormat: jspb.Message.getFieldWithDefault(msg, 4, 0),
agc: (f = msg.getAgc()) && pbtypes_gen_flir_boson_driver_agc_settings_pb.AgcSettings.toObject(includeInstance, f),
exposureRoi: (f = msg.getExposureRoi()) && pbtypes_gen_flir_boson_driver_roi_settings_pb.RoiSettings.toObject(includeInstance, f),
thermalParams: (f = msg.getThermalParams()) && pbtypes_gen_flir_boson_driver_thermal_params_pb.ThermalParams.toObject(includeInstance, f),
isotherm: (f = msg.getIsotherm()) && pbtypes_gen_flir_boson_driver_isotherm_settings_pb.IsothermSettings.toObject(includeInstance, f),
boson: (f = msg.getBoson()) && pbtypes_gen_flir_boson_driver_boson_settings_pb.BosonSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flight_deck.IrCaptureSettings}
 */
proto.flight_deck.IrCaptureSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flight_deck.IrCaptureSettings;
  return proto.flight_deck.IrCaptureSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flight_deck.IrCaptureSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flight_deck.IrCaptureSettings}
 */
proto.flight_deck.IrCaptureSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = /** @type {!proto.flir_boson_driver.Colorlut.Enum} */ (reader.readEnum());
      msg.setColorlut(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPhotoDisabled(value);
      break;
    case 4:
      var value = /** @type {!proto.user_camera.PhotoFormat.Enum} */ (reader.readEnum());
      msg.setPhotoFormat(value);
      break;
    case 5:
      var value = new pbtypes_gen_flir_boson_driver_agc_settings_pb.AgcSettings;
      reader.readMessage(value,pbtypes_gen_flir_boson_driver_agc_settings_pb.AgcSettings.deserializeBinaryFromReader);
      msg.setAgc(value);
      break;
    case 6:
      var value = new pbtypes_gen_flir_boson_driver_roi_settings_pb.RoiSettings;
      reader.readMessage(value,pbtypes_gen_flir_boson_driver_roi_settings_pb.RoiSettings.deserializeBinaryFromReader);
      msg.setExposureRoi(value);
      break;
    case 7:
      var value = new pbtypes_gen_flir_boson_driver_thermal_params_pb.ThermalParams;
      reader.readMessage(value,pbtypes_gen_flir_boson_driver_thermal_params_pb.ThermalParams.deserializeBinaryFromReader);
      msg.setThermalParams(value);
      break;
    case 8:
      var value = new pbtypes_gen_flir_boson_driver_isotherm_settings_pb.IsothermSettings;
      reader.readMessage(value,pbtypes_gen_flir_boson_driver_isotherm_settings_pb.IsothermSettings.deserializeBinaryFromReader);
      msg.setIsotherm(value);
      break;
    case 9:
      var value = new pbtypes_gen_flir_boson_driver_boson_settings_pb.BosonSettings;
      reader.readMessage(value,pbtypes_gen_flir_boson_driver_boson_settings_pb.BosonSettings.deserializeBinaryFromReader);
      msg.setBoson(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flight_deck.IrCaptureSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flight_deck.IrCaptureSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flight_deck.IrCaptureSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.IrCaptureSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getColorlut();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getPhotoDisabled();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getPhotoFormat();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getAgc();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      pbtypes_gen_flir_boson_driver_agc_settings_pb.AgcSettings.serializeBinaryToWriter
    );
  }
  f = message.getExposureRoi();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      pbtypes_gen_flir_boson_driver_roi_settings_pb.RoiSettings.serializeBinaryToWriter
    );
  }
  f = message.getThermalParams();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      pbtypes_gen_flir_boson_driver_thermal_params_pb.ThermalParams.serializeBinaryToWriter
    );
  }
  f = message.getIsotherm();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      pbtypes_gen_flir_boson_driver_isotherm_settings_pb.IsothermSettings.serializeBinaryToWriter
    );
  }
  f = message.getBoson();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      pbtypes_gen_flir_boson_driver_boson_settings_pb.BosonSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.flight_deck.IrCaptureSettings.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.flight_deck.IrCaptureSettings} returns this
 */
proto.flight_deck.IrCaptureSettings.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional flir_boson_driver.Colorlut.Enum colorlut = 2;
 * @return {!proto.flir_boson_driver.Colorlut.Enum}
 */
proto.flight_deck.IrCaptureSettings.prototype.getColorlut = function() {
  return /** @type {!proto.flir_boson_driver.Colorlut.Enum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.flir_boson_driver.Colorlut.Enum} value
 * @return {!proto.flight_deck.IrCaptureSettings} returns this
 */
proto.flight_deck.IrCaptureSettings.prototype.setColorlut = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional bool photo_disabled = 3;
 * @return {boolean}
 */
proto.flight_deck.IrCaptureSettings.prototype.getPhotoDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.IrCaptureSettings} returns this
 */
proto.flight_deck.IrCaptureSettings.prototype.setPhotoDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional user_camera.PhotoFormat.Enum photo_format = 4;
 * @return {!proto.user_camera.PhotoFormat.Enum}
 */
proto.flight_deck.IrCaptureSettings.prototype.getPhotoFormat = function() {
  return /** @type {!proto.user_camera.PhotoFormat.Enum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.user_camera.PhotoFormat.Enum} value
 * @return {!proto.flight_deck.IrCaptureSettings} returns this
 */
proto.flight_deck.IrCaptureSettings.prototype.setPhotoFormat = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional flir_boson_driver.AgcSettings agc = 5;
 * @return {?proto.flir_boson_driver.AgcSettings}
 */
proto.flight_deck.IrCaptureSettings.prototype.getAgc = function() {
  return /** @type{?proto.flir_boson_driver.AgcSettings} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_flir_boson_driver_agc_settings_pb.AgcSettings, 5));
};


/**
 * @param {?proto.flir_boson_driver.AgcSettings|undefined} value
 * @return {!proto.flight_deck.IrCaptureSettings} returns this
*/
proto.flight_deck.IrCaptureSettings.prototype.setAgc = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flight_deck.IrCaptureSettings} returns this
 */
proto.flight_deck.IrCaptureSettings.prototype.clearAgc = function() {
  return this.setAgc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flight_deck.IrCaptureSettings.prototype.hasAgc = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional flir_boson_driver.RoiSettings exposure_roi = 6;
 * @return {?proto.flir_boson_driver.RoiSettings}
 */
proto.flight_deck.IrCaptureSettings.prototype.getExposureRoi = function() {
  return /** @type{?proto.flir_boson_driver.RoiSettings} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_flir_boson_driver_roi_settings_pb.RoiSettings, 6));
};


/**
 * @param {?proto.flir_boson_driver.RoiSettings|undefined} value
 * @return {!proto.flight_deck.IrCaptureSettings} returns this
*/
proto.flight_deck.IrCaptureSettings.prototype.setExposureRoi = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flight_deck.IrCaptureSettings} returns this
 */
proto.flight_deck.IrCaptureSettings.prototype.clearExposureRoi = function() {
  return this.setExposureRoi(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flight_deck.IrCaptureSettings.prototype.hasExposureRoi = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional flir_boson_driver.ThermalParams thermal_params = 7;
 * @return {?proto.flir_boson_driver.ThermalParams}
 */
proto.flight_deck.IrCaptureSettings.prototype.getThermalParams = function() {
  return /** @type{?proto.flir_boson_driver.ThermalParams} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_flir_boson_driver_thermal_params_pb.ThermalParams, 7));
};


/**
 * @param {?proto.flir_boson_driver.ThermalParams|undefined} value
 * @return {!proto.flight_deck.IrCaptureSettings} returns this
*/
proto.flight_deck.IrCaptureSettings.prototype.setThermalParams = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flight_deck.IrCaptureSettings} returns this
 */
proto.flight_deck.IrCaptureSettings.prototype.clearThermalParams = function() {
  return this.setThermalParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flight_deck.IrCaptureSettings.prototype.hasThermalParams = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional flir_boson_driver.IsothermSettings isotherm = 8;
 * @return {?proto.flir_boson_driver.IsothermSettings}
 */
proto.flight_deck.IrCaptureSettings.prototype.getIsotherm = function() {
  return /** @type{?proto.flir_boson_driver.IsothermSettings} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_flir_boson_driver_isotherm_settings_pb.IsothermSettings, 8));
};


/**
 * @param {?proto.flir_boson_driver.IsothermSettings|undefined} value
 * @return {!proto.flight_deck.IrCaptureSettings} returns this
*/
proto.flight_deck.IrCaptureSettings.prototype.setIsotherm = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flight_deck.IrCaptureSettings} returns this
 */
proto.flight_deck.IrCaptureSettings.prototype.clearIsotherm = function() {
  return this.setIsotherm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flight_deck.IrCaptureSettings.prototype.hasIsotherm = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional flir_boson_driver.BosonSettings boson = 9;
 * @return {?proto.flir_boson_driver.BosonSettings}
 */
proto.flight_deck.IrCaptureSettings.prototype.getBoson = function() {
  return /** @type{?proto.flir_boson_driver.BosonSettings} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_flir_boson_driver_boson_settings_pb.BosonSettings, 9));
};


/**
 * @param {?proto.flir_boson_driver.BosonSettings|undefined} value
 * @return {!proto.flight_deck.IrCaptureSettings} returns this
*/
proto.flight_deck.IrCaptureSettings.prototype.setBoson = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flight_deck.IrCaptureSettings} returns this
 */
proto.flight_deck.IrCaptureSettings.prototype.clearBoson = function() {
  return this.setBoson(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flight_deck.IrCaptureSettings.prototype.hasBoson = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flight_deck.IrAuxillaryPersistedSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.flight_deck.IrAuxillaryPersistedSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flight_deck.IrAuxillaryPersistedSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.IrAuxillaryPersistedSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
spotMeterEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
userStatsRegionEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flight_deck.IrAuxillaryPersistedSettings}
 */
proto.flight_deck.IrAuxillaryPersistedSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flight_deck.IrAuxillaryPersistedSettings;
  return proto.flight_deck.IrAuxillaryPersistedSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flight_deck.IrAuxillaryPersistedSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flight_deck.IrAuxillaryPersistedSettings}
 */
proto.flight_deck.IrAuxillaryPersistedSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSpotMeterEnabled(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUserStatsRegionEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flight_deck.IrAuxillaryPersistedSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flight_deck.IrAuxillaryPersistedSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flight_deck.IrAuxillaryPersistedSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.IrAuxillaryPersistedSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpotMeterEnabled();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getUserStatsRegionEnabled();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bool spot_meter_enabled = 1;
 * @return {boolean}
 */
proto.flight_deck.IrAuxillaryPersistedSettings.prototype.getSpotMeterEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.IrAuxillaryPersistedSettings} returns this
 */
proto.flight_deck.IrAuxillaryPersistedSettings.prototype.setSpotMeterEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool user_stats_region_enabled = 2;
 * @return {boolean}
 */
proto.flight_deck.IrAuxillaryPersistedSettings.prototype.getUserStatsRegionEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.IrAuxillaryPersistedSettings} returns this
 */
proto.flight_deck.IrAuxillaryPersistedSettings.prototype.setUserStatsRegionEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flight_deck.RemoteIDAuthSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.flight_deck.RemoteIDAuthSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flight_deck.RemoteIDAuthSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.RemoteIDAuthSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
registrationCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
datablock: jspb.Message.getFieldWithDefault(msg, 2, ""),
writeStatus: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
modifiedDate: jspb.Message.getFieldWithDefault(msg, 4, ""),
broadcastMethod: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flight_deck.RemoteIDAuthSettings}
 */
proto.flight_deck.RemoteIDAuthSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flight_deck.RemoteIDAuthSettings;
  return proto.flight_deck.RemoteIDAuthSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flight_deck.RemoteIDAuthSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flight_deck.RemoteIDAuthSettings}
 */
proto.flight_deck.RemoteIDAuthSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegistrationCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatablock(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWriteStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setModifiedDate(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBroadcastMethod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flight_deck.RemoteIDAuthSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flight_deck.RemoteIDAuthSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flight_deck.RemoteIDAuthSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.RemoteIDAuthSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRegistrationCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDatablock();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getWriteStatus();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getModifiedDate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBroadcastMethod();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string registration_code = 1;
 * @return {string}
 */
proto.flight_deck.RemoteIDAuthSettings.prototype.getRegistrationCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.flight_deck.RemoteIDAuthSettings} returns this
 */
proto.flight_deck.RemoteIDAuthSettings.prototype.setRegistrationCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string datablock = 2;
 * @return {string}
 */
proto.flight_deck.RemoteIDAuthSettings.prototype.getDatablock = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.flight_deck.RemoteIDAuthSettings} returns this
 */
proto.flight_deck.RemoteIDAuthSettings.prototype.setDatablock = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool write_status = 3;
 * @return {boolean}
 */
proto.flight_deck.RemoteIDAuthSettings.prototype.getWriteStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.RemoteIDAuthSettings} returns this
 */
proto.flight_deck.RemoteIDAuthSettings.prototype.setWriteStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string modified_date = 4;
 * @return {string}
 */
proto.flight_deck.RemoteIDAuthSettings.prototype.getModifiedDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.flight_deck.RemoteIDAuthSettings} returns this
 */
proto.flight_deck.RemoteIDAuthSettings.prototype.setModifiedDate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool broadcast_method = 5;
 * @return {boolean}
 */
proto.flight_deck.RemoteIDAuthSettings.prototype.getBroadcastMethod = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.RemoteIDAuthSettings} returns this
 */
proto.flight_deck.RemoteIDAuthSettings.prototype.setBroadcastMethod = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flight_deck.MavlinkClientSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.flight_deck.MavlinkClientSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flight_deck.MavlinkClientSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.MavlinkClientSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
geofenceRadius: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
geofenceHeight: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
geofenceAction: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flight_deck.MavlinkClientSettings}
 */
proto.flight_deck.MavlinkClientSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flight_deck.MavlinkClientSettings;
  return proto.flight_deck.MavlinkClientSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flight_deck.MavlinkClientSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flight_deck.MavlinkClientSettings}
 */
proto.flight_deck.MavlinkClientSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setGeofenceRadius(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setGeofenceHeight(value);
      break;
    case 3:
      var value = /** @type {!proto.flight_deck.MavlinkClientSettings.MavlinkGeofenceAction} */ (reader.readEnum());
      msg.setGeofenceAction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flight_deck.MavlinkClientSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flight_deck.MavlinkClientSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flight_deck.MavlinkClientSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.MavlinkClientSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeofenceRadius();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getGeofenceHeight();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getGeofenceAction();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.flight_deck.MavlinkClientSettings.MavlinkGeofenceAction = {
  MAVLINK_GEOFENCE_ACTION_NONE: 0,
  MAVLINK_GEOFENCE_ACTION_WARN: 1,
  MAVLINK_GEOFENCE_ACTION_HOLD: 2,
  MAVLINK_GEOFENCE_ACTION_RETURN: 3,
  MAVLINK_GEOFENCE_ACTION_TERMINATE: 4,
  MAVLINK_GEOFENCE_ACTION_LAND: 5
};

/**
 * optional float geofence_radius = 1;
 * @return {number}
 */
proto.flight_deck.MavlinkClientSettings.prototype.getGeofenceRadius = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.flight_deck.MavlinkClientSettings} returns this
 */
proto.flight_deck.MavlinkClientSettings.prototype.setGeofenceRadius = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float geofence_height = 2;
 * @return {number}
 */
proto.flight_deck.MavlinkClientSettings.prototype.getGeofenceHeight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.flight_deck.MavlinkClientSettings} returns this
 */
proto.flight_deck.MavlinkClientSettings.prototype.setGeofenceHeight = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional MavlinkGeofenceAction geofence_action = 3;
 * @return {!proto.flight_deck.MavlinkClientSettings.MavlinkGeofenceAction}
 */
proto.flight_deck.MavlinkClientSettings.prototype.getGeofenceAction = function() {
  return /** @type {!proto.flight_deck.MavlinkClientSettings.MavlinkGeofenceAction} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.flight_deck.MavlinkClientSettings.MavlinkGeofenceAction} value
 * @return {!proto.flight_deck.MavlinkClientSettings} returns this
 */
proto.flight_deck.MavlinkClientSettings.prototype.setGeofenceAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.flight_deck.VehicleSettings.repeatedFields_ = [29,83,85,104,112];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flight_deck.VehicleSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.flight_deck.VehicleSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flight_deck.VehicleSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.VehicleSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
validationId: jspb.Message.getFieldWithDefault(msg, 30, 0),
cloudConfigUclock: jspb.Message.getFieldWithDefault(msg, 23, 0),
safetyHeight: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
heightCeilingOff: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
heightCeilingValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
ignoreGpsHeightCeil: jspb.Message.getBooleanFieldWithDefault(msg, 27, false),
geofenceOff: jspb.Message.getBooleanFieldWithDefault(msg, 54, false),
geofence: (f = msg.getGeofence()) && pbtypes_util_geofence_geofence_pb.Geofence.toObject(includeInstance, f),
illuminationMode: jspb.Message.getFieldWithDefault(msg, 4, 0),
obstacleMarginMode: jspb.Message.getFieldWithDefault(msg, 5, 0),
caseLandingDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
returnWithVisionOnly: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
rgbLedsDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
superzoom: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
digitalZoom: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
returnSettings: (f = msg.getReturnSettings()) && pbtypes_vehicle_infrastructure_flight_deck_return_settings_pb.ReturnSettings.toObject(includeInstance, f),
precisionFlightMode: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
enterpriseMode: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
navigationMode: jspb.Message.getFieldWithDefault(msg, 13, 0),
wifiBand: jspb.Message.getFieldWithDefault(msg, 14, 0),
narrowBandEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
disableMediaEncryption: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
skydiolinkAes256: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
skysticksPairingInfo: (f = msg.getSkysticksPairingInfo()) && pbtypes_infrastructure_wifi_wifi_pb.SkysticksPairingInfo.toObject(includeInstance, f),
ignoreLowBatteryBehavior: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
offline: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
rgbLedMode: jspb.Message.getFieldWithDefault(msg, 24, 0),
overwriteOldMedia: jspb.Message.getBooleanFieldWithDefault(msg, 25, false),
flightHistory: jspb.Message.getBooleanFieldWithDefault(msg, 26, false),
manualWifiChannel: jspb.Message.getFieldWithDefault(msg, 28, 0),
wifiChannelListList: (f = jspb.Message.getRepeatedField(msg, 29)) == null ? undefined : f,
multiResPreviewEnable: jspb.Message.getBooleanFieldWithDefault(msg, 31, false),
centralizedFrbRateEnable: jspb.Message.getBooleanFieldWithDefault(msg, 32, false),
alignedTelemetryCsv: jspb.Message.getBooleanFieldWithDefault(msg, 34, false),
handWaveEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 36, false),
displayHandWaveCalibration: jspb.Message.getBooleanFieldWithDefault(msg, 37, false),
variableObstacleAvoidance: jspb.Message.getBooleanFieldWithDefault(msg, 38, false),
rebootBeforeScheduledMissionsSeconds: jspb.Message.getFieldWithDefault(msg, 39, 0),
dockJigglingEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 40, false),
allowTakeoffFromMismatchedDock: jspb.Message.getBooleanFieldWithDefault(msg, 41, false),
batteryButtonLaunch: jspb.Message.getBooleanFieldWithDefault(msg, 42, false),
rfHighPower: jspb.Message.getBooleanFieldWithDefault(msg, 43, false),
flightMode: jspb.Message.getFieldWithDefault(msg, 44, 0),
ridFeatureEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 50, false),
allowOnVehiclePhotogrammetry: jspb.Message.getBooleanFieldWithDefault(msg, 45, false),
ridFeatureDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 51, false),
allowSurfaceScanAlphaFeatures: jspb.Message.getBooleanFieldWithDefault(msg, 52, false),
rebootMaxUptimeSeconds: jspb.Message.getFieldWithDefault(msg, 53, 0),
battImbalanceThresholds: (f = msg.getBattImbalanceThresholds()) && pbtypes_vehicle_health_monitor_fault_detectors_pb.BatteryCellVoltageImbalanceThresholds.toObject(includeInstance, f),
wifiCountryCode: jspb.Message.getFieldWithDefault(msg, 57, ""),
stopAtStructure: jspb.Message.getBooleanFieldWithDefault(msg, 58, false),
speedNearObstacles: jspb.Message.getFloatingPointFieldWithDefault(msg, 59, 0.0),
surfaceScan: jspb.Message.getBooleanFieldWithDefault(msg, 60, false),
landingLedMode: jspb.Message.getFieldWithDefault(msg, 61, 0),
enableRapidDescent: jspb.Message.getBooleanFieldWithDefault(msg, 63, false),
disableObstacleAvoidanceDockLanding: jspb.Message.getBooleanFieldWithDefault(msg, 64, false),
longRangeDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 65, false),
allowPoweredPayloads: jspb.Message.getBooleanFieldWithDefault(msg, 66, false),
disableCountryCodeScan: jspb.Message.getBooleanFieldWithDefault(msg, 68, false),
allow3dCaptureScanMode: jspb.Message.getBooleanFieldWithDefault(msg, 69, false),
allowSurfaceCaptureScanMode: jspb.Message.getBooleanFieldWithDefault(msg, 70, false),
allowMapCaptureScanMode: jspb.Message.getBooleanFieldWithDefault(msg, 71, false),
allowTowerCaptureScanMode: jspb.Message.getBooleanFieldWithDefault(msg, 35, false),
allowIndoorCaptureScanMode: jspb.Message.getBooleanFieldWithDefault(msg, 33, false),
allowIndoorCaptureScanModeV2: jspb.Message.getBooleanFieldWithDefault(msg, 97, false),
allowOrbitCaptureScanMode: jspb.Message.getBooleanFieldWithDefault(msg, 62, false),
allowCylinderCaptureScanMode: jspb.Message.getBooleanFieldWithDefault(msg, 72, false),
allowSemanticTransmissionTowerScanMode: jspb.Message.getBooleanFieldWithDefault(msg, 80, false),
fullSpinOnTakeoff: jspb.Message.getBooleanFieldWithDefault(msg, 73, false),
allowIrSubjectTracking: jspb.Message.getBooleanFieldWithDefault(msg, 74, false),
enableIspPixelContentFlip: jspb.Message.getBooleanFieldWithDefault(msg, 75, false),
enableVehicleRtspKlv: jspb.Message.getBooleanFieldWithDefault(msg, 77, false),
gimbalInspectionMode: jspb.Message.getBooleanFieldWithDefault(msg, 78, false),
nightsenseDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 79, false),
boostDisablesNightsense: jspb.Message.getBooleanFieldWithDefault(msg, 98, false),
nightsenseOffAllowedWithPoorGps: jspb.Message.getBooleanFieldWithDefault(msg, 99, false),
nightsenseDisabledNightAutonomySwitch: jspb.Message.getBooleanFieldWithDefault(msg, 100, false),
flashDriveRequiredForFlight: jspb.Message.getBooleanFieldWithDefault(msg, 81, false),
manualBandSelection: jspb.Message.getFieldWithDefault(msg, 82, 0),
bandSelectionListList: (f = jspb.Message.getRepeatedField(msg, 83)) == null ? undefined : f,
manualRadiowidthSelection: jspb.Message.getFieldWithDefault(msg, 84, 0),
radiowidthSelectionListList: (f = jspb.Message.getRepeatedField(msg, 85)) == null ? undefined : f,
lteModemEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 86, false),
allowCommandedEland: jspb.Message.getBooleanFieldWithDefault(msg, 87, false),
allowInternalMissionActions: jspb.Message.getBooleanFieldWithDefault(msg, 88, false),
autoLandInAttiMode: jspb.Message.getBooleanFieldWithDefault(msg, 89, false),
mhVehicleDcsCapable: jspb.Message.getBooleanFieldWithDefault(msg, 90, false),
increasedMaxSpeedMapCapture: jspb.Message.getBooleanFieldWithDefault(msg, 91, false),
manualWifiChannelOnRest: jspb.Message.getFieldWithDefault(msg, 92, 0),
allowMavlinkPerch: jspb.Message.getBooleanFieldWithDefault(msg, 93, false),
policeMode: jspb.Message.getBooleanFieldWithDefault(msg, 94, false),
lteDataSaver: jspb.Message.getBooleanFieldWithDefault(msg, 95, false),
blackoutModeEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 96, false),
disableGps: jspb.Message.getBooleanFieldWithDefault(msg, 101, false),
manualFrameSelection: jspb.Message.getFieldWithDefault(msg, 103, 0),
frameSelectionListList: (f = jspb.Message.getRepeatedField(msg, 104)) == null ? undefined : f,
enableHighAltitudeVio: jspb.Message.getBooleanFieldWithDefault(msg, 105, false),
enableAttitudeRtx: jspb.Message.getBooleanFieldWithDefault(msg, 106, false),
enableManualWff: jspb.Message.getBooleanFieldWithDefault(msg, 107, false),
oaOffGroundAvoidanceDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 108, false),
enableJpgHighCompress3ds: jspb.Message.getBooleanFieldWithDefault(msg, 109, false),
enablePerch: jspb.Message.getBooleanFieldWithDefault(msg, 110, false),
manualModeSelection: jspb.Message.getFieldWithDefault(msg, 111, 0),
modeSelectionListList: (f = jspb.Message.getRepeatedField(msg, 112)) == null ? undefined : f,
enableCommandedAttitude: jspb.Message.getBooleanFieldWithDefault(msg, 113, false),
publicSafetyPoliceMode: jspb.Message.getBooleanFieldWithDefault(msg, 114, false),
publicSafetyEmergencyMode: jspb.Message.getBooleanFieldWithDefault(msg, 115, false),
europeanUnionLighting: jspb.Message.getBooleanFieldWithDefault(msg, 127, false),
commandedAttitudeModeOn: jspb.Message.getBooleanFieldWithDefault(msg, 116, false),
enableGetMicrohardRfConfig: jspb.Message.getBooleanFieldWithDefault(msg, 117, false),
enableCommandedLostComms: jspb.Message.getBooleanFieldWithDefault(msg, 118, false),
mavlinkClientSettings: (f = msg.getMavlinkClientSettings()) && proto.flight_deck.MavlinkClientSettings.toObject(includeInstance, f),
enableGpsSpoofingDetector: jspb.Message.getBooleanFieldWithDefault(msg, 120, false),
enableRscToggle3ds: jspb.Message.getBooleanFieldWithDefault(msg, 121, false),
enableLowTakeoff: jspb.Message.getBooleanFieldWithDefault(msg, 122, false),
enableRtkSupport: jspb.Message.getBooleanFieldWithDefault(msg, 123, false),
enableFlightFileManifestTask: jspb.Message.getBooleanFieldWithDefault(msg, 124, false),
isEuropeanUnion: jspb.Message.getBooleanFieldWithDefault(msg, 125, false),
enableEuSlowSpeed: jspb.Message.getBooleanFieldWithDefault(msg, 126, false),
enableForceVioDegraded: jspb.Message.getBooleanFieldWithDefault(msg, 128, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flight_deck.VehicleSettings}
 */
proto.flight_deck.VehicleSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flight_deck.VehicleSettings;
  return proto.flight_deck.VehicleSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flight_deck.VehicleSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flight_deck.VehicleSettings}
 */
proto.flight_deck.VehicleSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValidationId(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCloudConfigUclock(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSafetyHeight(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHeightCeilingOff(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setHeightCeilingValue(value);
      break;
    case 27:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnoreGpsHeightCeil(value);
      break;
    case 54:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGeofenceOff(value);
      break;
    case 55:
      var value = new pbtypes_util_geofence_geofence_pb.Geofence;
      reader.readMessage(value,pbtypes_util_geofence_geofence_pb.Geofence.deserializeBinaryFromReader);
      msg.setGeofence(value);
      break;
    case 4:
      var value = /** @type {!proto.flight_deck.VehicleSettings.IlluminationMode} */ (reader.readEnum());
      msg.setIlluminationMode(value);
      break;
    case 5:
      var value = /** @type {!proto.flight_deck.VehicleSettings.ObstacleMarginMode} */ (reader.readEnum());
      msg.setObstacleMarginMode(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCaseLandingDisabled(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReturnWithVisionOnly(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRgbLedsDisabled(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuperzoom(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDigitalZoom(value);
      break;
    case 10:
      var value = new pbtypes_vehicle_infrastructure_flight_deck_return_settings_pb.ReturnSettings;
      reader.readMessage(value,pbtypes_vehicle_infrastructure_flight_deck_return_settings_pb.ReturnSettings.deserializeBinaryFromReader);
      msg.setReturnSettings(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrecisionFlightMode(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnterpriseMode(value);
      break;
    case 13:
      var value = /** @type {!proto.flight_deck.NavigationMode.Mode} */ (reader.readEnum());
      msg.setNavigationMode(value);
      break;
    case 14:
      var value = /** @type {!proto.flight_deck.VehicleSettings.WifiBand} */ (reader.readEnum());
      msg.setWifiBand(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNarrowBandEnabled(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisableMediaEncryption(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSkydiolinkAes256(value);
      break;
    case 19:
      var value = new pbtypes_infrastructure_wifi_wifi_pb.SkysticksPairingInfo;
      reader.readMessage(value,pbtypes_infrastructure_wifi_wifi_pb.SkysticksPairingInfo.deserializeBinaryFromReader);
      msg.setSkysticksPairingInfo(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnoreLowBatteryBehavior(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOffline(value);
      break;
    case 24:
      var value = /** @type {!proto.flight_deck.RgbLedMode.Enum} */ (reader.readEnum());
      msg.setRgbLedMode(value);
      break;
    case 25:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOverwriteOldMedia(value);
      break;
    case 26:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFlightHistory(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setManualWifiChannel(value);
      break;
    case 29:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addWifiChannelList(values[i]);
      }
      break;
    case 31:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMultiResPreviewEnable(value);
      break;
    case 32:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCentralizedFrbRateEnable(value);
      break;
    case 34:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAlignedTelemetryCsv(value);
      break;
    case 36:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHandWaveEnabled(value);
      break;
    case 37:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisplayHandWaveCalibration(value);
      break;
    case 38:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVariableObstacleAvoidance(value);
      break;
    case 39:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRebootBeforeScheduledMissionsSeconds(value);
      break;
    case 40:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDockJigglingEnabled(value);
      break;
    case 41:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowTakeoffFromMismatchedDock(value);
      break;
    case 42:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBatteryButtonLaunch(value);
      break;
    case 43:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRfHighPower(value);
      break;
    case 44:
      var value = /** @type {!proto.flight_deck.VehicleSettings.FlightMode} */ (reader.readEnum());
      msg.setFlightMode(value);
      break;
    case 50:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRidFeatureEnabled(value);
      break;
    case 45:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowOnVehiclePhotogrammetry(value);
      break;
    case 51:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRidFeatureDisabled(value);
      break;
    case 52:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowSurfaceScanAlphaFeatures(value);
      break;
    case 53:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRebootMaxUptimeSeconds(value);
      break;
    case 56:
      var value = new pbtypes_vehicle_health_monitor_fault_detectors_pb.BatteryCellVoltageImbalanceThresholds;
      reader.readMessage(value,pbtypes_vehicle_health_monitor_fault_detectors_pb.BatteryCellVoltageImbalanceThresholds.deserializeBinaryFromReader);
      msg.setBattImbalanceThresholds(value);
      break;
    case 57:
      var value = /** @type {string} */ (reader.readString());
      msg.setWifiCountryCode(value);
      break;
    case 58:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStopAtStructure(value);
      break;
    case 59:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSpeedNearObstacles(value);
      break;
    case 60:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSurfaceScan(value);
      break;
    case 61:
      var value = /** @type {!proto.flight_deck.VehicleSettings.LandingLedMode} */ (reader.readEnum());
      msg.setLandingLedMode(value);
      break;
    case 63:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableRapidDescent(value);
      break;
    case 64:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisableObstacleAvoidanceDockLanding(value);
      break;
    case 65:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLongRangeDisabled(value);
      break;
    case 66:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowPoweredPayloads(value);
      break;
    case 68:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisableCountryCodeScan(value);
      break;
    case 69:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllow3dCaptureScanMode(value);
      break;
    case 70:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowSurfaceCaptureScanMode(value);
      break;
    case 71:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowMapCaptureScanMode(value);
      break;
    case 35:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowTowerCaptureScanMode(value);
      break;
    case 33:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowIndoorCaptureScanMode(value);
      break;
    case 97:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowIndoorCaptureScanModeV2(value);
      break;
    case 62:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowOrbitCaptureScanMode(value);
      break;
    case 72:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowCylinderCaptureScanMode(value);
      break;
    case 80:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowSemanticTransmissionTowerScanMode(value);
      break;
    case 73:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFullSpinOnTakeoff(value);
      break;
    case 74:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowIrSubjectTracking(value);
      break;
    case 75:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableIspPixelContentFlip(value);
      break;
    case 77:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableVehicleRtspKlv(value);
      break;
    case 78:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGimbalInspectionMode(value);
      break;
    case 79:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNightsenseDisabled(value);
      break;
    case 98:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBoostDisablesNightsense(value);
      break;
    case 99:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNightsenseOffAllowedWithPoorGps(value);
      break;
    case 100:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNightsenseDisabledNightAutonomySwitch(value);
      break;
    case 81:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFlashDriveRequiredForFlight(value);
      break;
    case 82:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setManualBandSelection(value);
      break;
    case 83:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addBandSelectionList(values[i]);
      }
      break;
    case 84:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setManualRadiowidthSelection(value);
      break;
    case 85:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRadiowidthSelectionList(values[i]);
      }
      break;
    case 86:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLteModemEnabled(value);
      break;
    case 87:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowCommandedEland(value);
      break;
    case 88:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowInternalMissionActions(value);
      break;
    case 89:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoLandInAttiMode(value);
      break;
    case 90:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMhVehicleDcsCapable(value);
      break;
    case 91:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncreasedMaxSpeedMapCapture(value);
      break;
    case 92:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setManualWifiChannelOnRest(value);
      break;
    case 93:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowMavlinkPerch(value);
      break;
    case 94:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPoliceMode(value);
      break;
    case 95:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLteDataSaver(value);
      break;
    case 96:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBlackoutModeEnabled(value);
      break;
    case 101:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisableGps(value);
      break;
    case 103:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setManualFrameSelection(value);
      break;
    case 104:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFrameSelectionList(values[i]);
      }
      break;
    case 105:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableHighAltitudeVio(value);
      break;
    case 106:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableAttitudeRtx(value);
      break;
    case 107:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableManualWff(value);
      break;
    case 108:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOaOffGroundAvoidanceDisabled(value);
      break;
    case 109:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableJpgHighCompress3ds(value);
      break;
    case 110:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnablePerch(value);
      break;
    case 111:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setManualModeSelection(value);
      break;
    case 112:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addModeSelectionList(values[i]);
      }
      break;
    case 113:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableCommandedAttitude(value);
      break;
    case 114:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPublicSafetyPoliceMode(value);
      break;
    case 115:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPublicSafetyEmergencyMode(value);
      break;
    case 127:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEuropeanUnionLighting(value);
      break;
    case 116:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCommandedAttitudeModeOn(value);
      break;
    case 117:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableGetMicrohardRfConfig(value);
      break;
    case 118:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableCommandedLostComms(value);
      break;
    case 119:
      var value = new proto.flight_deck.MavlinkClientSettings;
      reader.readMessage(value,proto.flight_deck.MavlinkClientSettings.deserializeBinaryFromReader);
      msg.setMavlinkClientSettings(value);
      break;
    case 120:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableGpsSpoofingDetector(value);
      break;
    case 121:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableRscToggle3ds(value);
      break;
    case 122:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableLowTakeoff(value);
      break;
    case 123:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableRtkSupport(value);
      break;
    case 124:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableFlightFileManifestTask(value);
      break;
    case 125:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEuropeanUnion(value);
      break;
    case 126:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableEuSlowSpeed(value);
      break;
    case 128:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableForceVioDegraded(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flight_deck.VehicleSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flight_deck.VehicleSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flight_deck.VehicleSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.VehicleSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getValidationId();
  if (f !== 0) {
    writer.writeInt32(
      30,
      f
    );
  }
  f = message.getCloudConfigUclock();
  if (f !== 0) {
    writer.writeInt64(
      23,
      f
    );
  }
  f = message.getSafetyHeight();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getHeightCeilingOff();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getHeightCeilingValue();
  if (f !== 0.0) {
    writer.writeFloat(
      15,
      f
    );
  }
  f = message.getIgnoreGpsHeightCeil();
  if (f) {
    writer.writeBool(
      27,
      f
    );
  }
  f = message.getGeofenceOff();
  if (f) {
    writer.writeBool(
      54,
      f
    );
  }
  f = message.getGeofence();
  if (f != null) {
    writer.writeMessage(
      55,
      f,
      pbtypes_util_geofence_geofence_pb.Geofence.serializeBinaryToWriter
    );
  }
  f = message.getIlluminationMode();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getObstacleMarginMode();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getCaseLandingDisabled();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getReturnWithVisionOnly();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getRgbLedsDisabled();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getSuperzoom();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getDigitalZoom();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getReturnSettings();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      pbtypes_vehicle_infrastructure_flight_deck_return_settings_pb.ReturnSettings.serializeBinaryToWriter
    );
  }
  f = message.getPrecisionFlightMode();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getEnterpriseMode();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getNavigationMode();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getWifiBand();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getNarrowBandEnabled();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = message.getDisableMediaEncryption();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getSkydiolinkAes256();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getSkysticksPairingInfo();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      pbtypes_infrastructure_wifi_wifi_pb.SkysticksPairingInfo.serializeBinaryToWriter
    );
  }
  f = message.getIgnoreLowBatteryBehavior();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getOffline();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getRgbLedMode();
  if (f !== 0.0) {
    writer.writeEnum(
      24,
      f
    );
  }
  f = message.getOverwriteOldMedia();
  if (f) {
    writer.writeBool(
      25,
      f
    );
  }
  f = message.getFlightHistory();
  if (f) {
    writer.writeBool(
      26,
      f
    );
  }
  f = message.getManualWifiChannel();
  if (f !== 0) {
    writer.writeInt32(
      28,
      f
    );
  }
  f = message.getWifiChannelListList();
  if (f.length > 0) {
    writer.writePackedInt32(
      29,
      f
    );
  }
  f = message.getMultiResPreviewEnable();
  if (f) {
    writer.writeBool(
      31,
      f
    );
  }
  f = message.getCentralizedFrbRateEnable();
  if (f) {
    writer.writeBool(
      32,
      f
    );
  }
  f = message.getAlignedTelemetryCsv();
  if (f) {
    writer.writeBool(
      34,
      f
    );
  }
  f = message.getHandWaveEnabled();
  if (f) {
    writer.writeBool(
      36,
      f
    );
  }
  f = message.getDisplayHandWaveCalibration();
  if (f) {
    writer.writeBool(
      37,
      f
    );
  }
  f = message.getVariableObstacleAvoidance();
  if (f) {
    writer.writeBool(
      38,
      f
    );
  }
  f = message.getRebootBeforeScheduledMissionsSeconds();
  if (f !== 0) {
    writer.writeInt32(
      39,
      f
    );
  }
  f = message.getDockJigglingEnabled();
  if (f) {
    writer.writeBool(
      40,
      f
    );
  }
  f = message.getAllowTakeoffFromMismatchedDock();
  if (f) {
    writer.writeBool(
      41,
      f
    );
  }
  f = message.getBatteryButtonLaunch();
  if (f) {
    writer.writeBool(
      42,
      f
    );
  }
  f = message.getRfHighPower();
  if (f) {
    writer.writeBool(
      43,
      f
    );
  }
  f = message.getFlightMode();
  if (f !== 0.0) {
    writer.writeEnum(
      44,
      f
    );
  }
  f = message.getRidFeatureEnabled();
  if (f) {
    writer.writeBool(
      50,
      f
    );
  }
  f = message.getAllowOnVehiclePhotogrammetry();
  if (f) {
    writer.writeBool(
      45,
      f
    );
  }
  f = message.getRidFeatureDisabled();
  if (f) {
    writer.writeBool(
      51,
      f
    );
  }
  f = message.getAllowSurfaceScanAlphaFeatures();
  if (f) {
    writer.writeBool(
      52,
      f
    );
  }
  f = message.getRebootMaxUptimeSeconds();
  if (f !== 0) {
    writer.writeInt32(
      53,
      f
    );
  }
  f = message.getBattImbalanceThresholds();
  if (f != null) {
    writer.writeMessage(
      56,
      f,
      pbtypes_vehicle_health_monitor_fault_detectors_pb.BatteryCellVoltageImbalanceThresholds.serializeBinaryToWriter
    );
  }
  f = message.getWifiCountryCode();
  if (f.length > 0) {
    writer.writeString(
      57,
      f
    );
  }
  f = message.getStopAtStructure();
  if (f) {
    writer.writeBool(
      58,
      f
    );
  }
  f = message.getSpeedNearObstacles();
  if (f !== 0.0) {
    writer.writeFloat(
      59,
      f
    );
  }
  f = message.getSurfaceScan();
  if (f) {
    writer.writeBool(
      60,
      f
    );
  }
  f = message.getLandingLedMode();
  if (f !== 0.0) {
    writer.writeEnum(
      61,
      f
    );
  }
  f = message.getEnableRapidDescent();
  if (f) {
    writer.writeBool(
      63,
      f
    );
  }
  f = message.getDisableObstacleAvoidanceDockLanding();
  if (f) {
    writer.writeBool(
      64,
      f
    );
  }
  f = message.getLongRangeDisabled();
  if (f) {
    writer.writeBool(
      65,
      f
    );
  }
  f = message.getAllowPoweredPayloads();
  if (f) {
    writer.writeBool(
      66,
      f
    );
  }
  f = message.getDisableCountryCodeScan();
  if (f) {
    writer.writeBool(
      68,
      f
    );
  }
  f = message.getAllow3dCaptureScanMode();
  if (f) {
    writer.writeBool(
      69,
      f
    );
  }
  f = message.getAllowSurfaceCaptureScanMode();
  if (f) {
    writer.writeBool(
      70,
      f
    );
  }
  f = message.getAllowMapCaptureScanMode();
  if (f) {
    writer.writeBool(
      71,
      f
    );
  }
  f = message.getAllowTowerCaptureScanMode();
  if (f) {
    writer.writeBool(
      35,
      f
    );
  }
  f = message.getAllowIndoorCaptureScanMode();
  if (f) {
    writer.writeBool(
      33,
      f
    );
  }
  f = message.getAllowIndoorCaptureScanModeV2();
  if (f) {
    writer.writeBool(
      97,
      f
    );
  }
  f = message.getAllowOrbitCaptureScanMode();
  if (f) {
    writer.writeBool(
      62,
      f
    );
  }
  f = message.getAllowCylinderCaptureScanMode();
  if (f) {
    writer.writeBool(
      72,
      f
    );
  }
  f = message.getAllowSemanticTransmissionTowerScanMode();
  if (f) {
    writer.writeBool(
      80,
      f
    );
  }
  f = message.getFullSpinOnTakeoff();
  if (f) {
    writer.writeBool(
      73,
      f
    );
  }
  f = message.getAllowIrSubjectTracking();
  if (f) {
    writer.writeBool(
      74,
      f
    );
  }
  f = message.getEnableIspPixelContentFlip();
  if (f) {
    writer.writeBool(
      75,
      f
    );
  }
  f = message.getEnableVehicleRtspKlv();
  if (f) {
    writer.writeBool(
      77,
      f
    );
  }
  f = message.getGimbalInspectionMode();
  if (f) {
    writer.writeBool(
      78,
      f
    );
  }
  f = message.getNightsenseDisabled();
  if (f) {
    writer.writeBool(
      79,
      f
    );
  }
  f = message.getBoostDisablesNightsense();
  if (f) {
    writer.writeBool(
      98,
      f
    );
  }
  f = message.getNightsenseOffAllowedWithPoorGps();
  if (f) {
    writer.writeBool(
      99,
      f
    );
  }
  f = message.getNightsenseDisabledNightAutonomySwitch();
  if (f) {
    writer.writeBool(
      100,
      f
    );
  }
  f = message.getFlashDriveRequiredForFlight();
  if (f) {
    writer.writeBool(
      81,
      f
    );
  }
  f = message.getManualBandSelection();
  if (f !== 0) {
    writer.writeInt32(
      82,
      f
    );
  }
  f = message.getBandSelectionListList();
  if (f.length > 0) {
    writer.writePackedInt32(
      83,
      f
    );
  }
  f = message.getManualRadiowidthSelection();
  if (f !== 0) {
    writer.writeInt32(
      84,
      f
    );
  }
  f = message.getRadiowidthSelectionListList();
  if (f.length > 0) {
    writer.writePackedInt32(
      85,
      f
    );
  }
  f = message.getLteModemEnabled();
  if (f) {
    writer.writeBool(
      86,
      f
    );
  }
  f = message.getAllowCommandedEland();
  if (f) {
    writer.writeBool(
      87,
      f
    );
  }
  f = message.getAllowInternalMissionActions();
  if (f) {
    writer.writeBool(
      88,
      f
    );
  }
  f = message.getAutoLandInAttiMode();
  if (f) {
    writer.writeBool(
      89,
      f
    );
  }
  f = message.getMhVehicleDcsCapable();
  if (f) {
    writer.writeBool(
      90,
      f
    );
  }
  f = message.getIncreasedMaxSpeedMapCapture();
  if (f) {
    writer.writeBool(
      91,
      f
    );
  }
  f = message.getManualWifiChannelOnRest();
  if (f !== 0) {
    writer.writeInt32(
      92,
      f
    );
  }
  f = message.getAllowMavlinkPerch();
  if (f) {
    writer.writeBool(
      93,
      f
    );
  }
  f = message.getPoliceMode();
  if (f) {
    writer.writeBool(
      94,
      f
    );
  }
  f = message.getLteDataSaver();
  if (f) {
    writer.writeBool(
      95,
      f
    );
  }
  f = message.getBlackoutModeEnabled();
  if (f) {
    writer.writeBool(
      96,
      f
    );
  }
  f = message.getDisableGps();
  if (f) {
    writer.writeBool(
      101,
      f
    );
  }
  f = message.getManualFrameSelection();
  if (f !== 0) {
    writer.writeInt32(
      103,
      f
    );
  }
  f = message.getFrameSelectionListList();
  if (f.length > 0) {
    writer.writePackedInt32(
      104,
      f
    );
  }
  f = message.getEnableHighAltitudeVio();
  if (f) {
    writer.writeBool(
      105,
      f
    );
  }
  f = message.getEnableAttitudeRtx();
  if (f) {
    writer.writeBool(
      106,
      f
    );
  }
  f = message.getEnableManualWff();
  if (f) {
    writer.writeBool(
      107,
      f
    );
  }
  f = message.getOaOffGroundAvoidanceDisabled();
  if (f) {
    writer.writeBool(
      108,
      f
    );
  }
  f = message.getEnableJpgHighCompress3ds();
  if (f) {
    writer.writeBool(
      109,
      f
    );
  }
  f = message.getEnablePerch();
  if (f) {
    writer.writeBool(
      110,
      f
    );
  }
  f = message.getManualModeSelection();
  if (f !== 0) {
    writer.writeInt32(
      111,
      f
    );
  }
  f = message.getModeSelectionListList();
  if (f.length > 0) {
    writer.writePackedInt32(
      112,
      f
    );
  }
  f = message.getEnableCommandedAttitude();
  if (f) {
    writer.writeBool(
      113,
      f
    );
  }
  f = message.getPublicSafetyPoliceMode();
  if (f) {
    writer.writeBool(
      114,
      f
    );
  }
  f = message.getPublicSafetyEmergencyMode();
  if (f) {
    writer.writeBool(
      115,
      f
    );
  }
  f = message.getEuropeanUnionLighting();
  if (f) {
    writer.writeBool(
      127,
      f
    );
  }
  f = message.getCommandedAttitudeModeOn();
  if (f) {
    writer.writeBool(
      116,
      f
    );
  }
  f = message.getEnableGetMicrohardRfConfig();
  if (f) {
    writer.writeBool(
      117,
      f
    );
  }
  f = message.getEnableCommandedLostComms();
  if (f) {
    writer.writeBool(
      118,
      f
    );
  }
  f = message.getMavlinkClientSettings();
  if (f != null) {
    writer.writeMessage(
      119,
      f,
      proto.flight_deck.MavlinkClientSettings.serializeBinaryToWriter
    );
  }
  f = message.getEnableGpsSpoofingDetector();
  if (f) {
    writer.writeBool(
      120,
      f
    );
  }
  f = message.getEnableRscToggle3ds();
  if (f) {
    writer.writeBool(
      121,
      f
    );
  }
  f = message.getEnableLowTakeoff();
  if (f) {
    writer.writeBool(
      122,
      f
    );
  }
  f = message.getEnableRtkSupport();
  if (f) {
    writer.writeBool(
      123,
      f
    );
  }
  f = message.getEnableFlightFileManifestTask();
  if (f) {
    writer.writeBool(
      124,
      f
    );
  }
  f = message.getIsEuropeanUnion();
  if (f) {
    writer.writeBool(
      125,
      f
    );
  }
  f = message.getEnableEuSlowSpeed();
  if (f) {
    writer.writeBool(
      126,
      f
    );
  }
  f = message.getEnableForceVioDegraded();
  if (f) {
    writer.writeBool(
      128,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.flight_deck.VehicleSettings.IlluminationMode = {
  DEFAULT_ILLUMINATION: 0,
  VISIBLE_STROBE: 1,
  INFRARED_STROBE: 2,
  INFRARED_ON: 3
};

/**
 * @enum {number}
 */
proto.flight_deck.VehicleSettings.ObstacleMarginMode = {
  DEFAULT_OBSTACLES: 0,
  REDUCED_OBSTACLES: 1,
  NO_OBSTACLES: 2,
  MINIMAL_OBSTACLES: 3
};

/**
 * @enum {number}
 */
proto.flight_deck.VehicleSettings.FlightMode = {
  NOT_SET: 0,
  DYNAMIC: 1,
  MISSION: 2,
  ATTITUDE: 3,
  WIDE_PHOTOS: 4
};

/**
 * @enum {number}
 */
proto.flight_deck.VehicleSettings.WifiBand = {
  DEFAULT_20_MHZ: 0,
  NARROW_10_MHZ: 1,
  NARROW_5_MHZ: 2
};

/**
 * @enum {number}
 */
proto.flight_deck.VehicleSettings.LandingLedMode = {
  LANDING_LED_OFF: 0,
  LANDING_LED_ON: 1
};

/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.flight_deck.VehicleSettings.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 validation_id = 30;
 * @return {number}
 */
proto.flight_deck.VehicleSettings.prototype.getValidationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {number} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setValidationId = function(value) {
  return jspb.Message.setProto3IntField(this, 30, value);
};


/**
 * optional int64 cloud_config_uclock = 23;
 * @return {number}
 */
proto.flight_deck.VehicleSettings.prototype.getCloudConfigUclock = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setCloudConfigUclock = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional bool safety_height = 2;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getSafetyHeight = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setSafetyHeight = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool height_ceiling_off = 3;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getHeightCeilingOff = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setHeightCeilingOff = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional float height_ceiling_value = 15;
 * @return {number}
 */
proto.flight_deck.VehicleSettings.prototype.getHeightCeilingValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setHeightCeilingValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional bool ignore_gps_height_ceil = 27;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getIgnoreGpsHeightCeil = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 27, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setIgnoreGpsHeightCeil = function(value) {
  return jspb.Message.setProto3BooleanField(this, 27, value);
};


/**
 * optional bool geofence_off = 54;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getGeofenceOff = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 54, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setGeofenceOff = function(value) {
  return jspb.Message.setProto3BooleanField(this, 54, value);
};


/**
 * optional geofence.Geofence geofence = 55;
 * @return {?proto.geofence.Geofence}
 */
proto.flight_deck.VehicleSettings.prototype.getGeofence = function() {
  return /** @type{?proto.geofence.Geofence} */ (
    jspb.Message.getWrapperField(this, pbtypes_util_geofence_geofence_pb.Geofence, 55));
};


/**
 * @param {?proto.geofence.Geofence|undefined} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
*/
proto.flight_deck.VehicleSettings.prototype.setGeofence = function(value) {
  return jspb.Message.setWrapperField(this, 55, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.clearGeofence = function() {
  return this.setGeofence(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.hasGeofence = function() {
  return jspb.Message.getField(this, 55) != null;
};


/**
 * optional IlluminationMode illumination_mode = 4;
 * @return {!proto.flight_deck.VehicleSettings.IlluminationMode}
 */
proto.flight_deck.VehicleSettings.prototype.getIlluminationMode = function() {
  return /** @type {!proto.flight_deck.VehicleSettings.IlluminationMode} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettings.IlluminationMode} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setIlluminationMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional ObstacleMarginMode obstacle_margin_mode = 5;
 * @return {!proto.flight_deck.VehicleSettings.ObstacleMarginMode}
 */
proto.flight_deck.VehicleSettings.prototype.getObstacleMarginMode = function() {
  return /** @type {!proto.flight_deck.VehicleSettings.ObstacleMarginMode} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettings.ObstacleMarginMode} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setObstacleMarginMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional bool case_landing_disabled = 6;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getCaseLandingDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setCaseLandingDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool return_with_vision_only = 7;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getReturnWithVisionOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setReturnWithVisionOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool rgb_leds_disabled = 8;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getRgbLedsDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setRgbLedsDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool superzoom = 9;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getSuperzoom = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setSuperzoom = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool digital_zoom = 18;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getDigitalZoom = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setDigitalZoom = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional ReturnSettings return_settings = 10;
 * @return {?proto.flight_deck.ReturnSettings}
 */
proto.flight_deck.VehicleSettings.prototype.getReturnSettings = function() {
  return /** @type{?proto.flight_deck.ReturnSettings} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_infrastructure_flight_deck_return_settings_pb.ReturnSettings, 10));
};


/**
 * @param {?proto.flight_deck.ReturnSettings|undefined} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
*/
proto.flight_deck.VehicleSettings.prototype.setReturnSettings = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.clearReturnSettings = function() {
  return this.setReturnSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.hasReturnSettings = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional bool precision_flight_mode = 11;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getPrecisionFlightMode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setPrecisionFlightMode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool enterprise_mode = 12;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getEnterpriseMode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setEnterpriseMode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional NavigationMode.Mode navigation_mode = 13;
 * @return {!proto.flight_deck.NavigationMode.Mode}
 */
proto.flight_deck.VehicleSettings.prototype.getNavigationMode = function() {
  return /** @type {!proto.flight_deck.NavigationMode.Mode} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.flight_deck.NavigationMode.Mode} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setNavigationMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional WifiBand wifi_band = 14;
 * @return {!proto.flight_deck.VehicleSettings.WifiBand}
 */
proto.flight_deck.VehicleSettings.prototype.getWifiBand = function() {
  return /** @type {!proto.flight_deck.VehicleSettings.WifiBand} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettings.WifiBand} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setWifiBand = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional bool narrow_band_enabled = 22;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getNarrowBandEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setNarrowBandEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * optional bool disable_media_encryption = 16;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getDisableMediaEncryption = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setDisableMediaEncryption = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional bool skydiolink_aes256 = 17;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getSkydiolinkAes256 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setSkydiolinkAes256 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional wifi.SkysticksPairingInfo skysticks_pairing_info = 19;
 * @return {?proto.wifi.SkysticksPairingInfo}
 */
proto.flight_deck.VehicleSettings.prototype.getSkysticksPairingInfo = function() {
  return /** @type{?proto.wifi.SkysticksPairingInfo} */ (
    jspb.Message.getWrapperField(this, pbtypes_infrastructure_wifi_wifi_pb.SkysticksPairingInfo, 19));
};


/**
 * @param {?proto.wifi.SkysticksPairingInfo|undefined} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
*/
proto.flight_deck.VehicleSettings.prototype.setSkysticksPairingInfo = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.clearSkysticksPairingInfo = function() {
  return this.setSkysticksPairingInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.hasSkysticksPairingInfo = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional bool ignore_low_battery_behavior = 20;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getIgnoreLowBatteryBehavior = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setIgnoreLowBatteryBehavior = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional bool offline = 21;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getOffline = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setOffline = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional RgbLedMode.Enum rgb_led_mode = 24;
 * @return {!proto.flight_deck.RgbLedMode.Enum}
 */
proto.flight_deck.VehicleSettings.prototype.getRgbLedMode = function() {
  return /** @type {!proto.flight_deck.RgbLedMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {!proto.flight_deck.RgbLedMode.Enum} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setRgbLedMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 24, value);
};


/**
 * optional bool overwrite_old_media = 25;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getOverwriteOldMedia = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 25, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setOverwriteOldMedia = function(value) {
  return jspb.Message.setProto3BooleanField(this, 25, value);
};


/**
 * optional bool flight_history = 26;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getFlightHistory = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 26, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setFlightHistory = function(value) {
  return jspb.Message.setProto3BooleanField(this, 26, value);
};


/**
 * optional int32 manual_wifi_channel = 28;
 * @return {number}
 */
proto.flight_deck.VehicleSettings.prototype.getManualWifiChannel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/**
 * @param {number} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setManualWifiChannel = function(value) {
  return jspb.Message.setProto3IntField(this, 28, value);
};


/**
 * repeated int32 wifi_channel_list = 29;
 * @return {!Array<number>}
 */
proto.flight_deck.VehicleSettings.prototype.getWifiChannelListList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 29));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setWifiChannelListList = function(value) {
  return jspb.Message.setField(this, 29, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.addWifiChannelList = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 29, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.clearWifiChannelListList = function() {
  return this.setWifiChannelListList([]);
};


/**
 * optional bool multi_res_preview_enable = 31;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getMultiResPreviewEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 31, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setMultiResPreviewEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 31, value);
};


/**
 * optional bool centralized_frb_rate_enable = 32;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getCentralizedFrbRateEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 32, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setCentralizedFrbRateEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 32, value);
};


/**
 * optional bool aligned_telemetry_csv = 34;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getAlignedTelemetryCsv = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 34, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setAlignedTelemetryCsv = function(value) {
  return jspb.Message.setProto3BooleanField(this, 34, value);
};


/**
 * optional bool hand_wave_enabled = 36;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getHandWaveEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 36, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setHandWaveEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 36, value);
};


/**
 * optional bool display_hand_wave_calibration = 37;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getDisplayHandWaveCalibration = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 37, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setDisplayHandWaveCalibration = function(value) {
  return jspb.Message.setProto3BooleanField(this, 37, value);
};


/**
 * optional bool variable_obstacle_avoidance = 38;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getVariableObstacleAvoidance = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 38, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setVariableObstacleAvoidance = function(value) {
  return jspb.Message.setProto3BooleanField(this, 38, value);
};


/**
 * optional int32 reboot_before_scheduled_missions_seconds = 39;
 * @return {number}
 */
proto.flight_deck.VehicleSettings.prototype.getRebootBeforeScheduledMissionsSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 39, 0));
};


/**
 * @param {number} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setRebootBeforeScheduledMissionsSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 39, value);
};


/**
 * optional bool dock_jiggling_enabled = 40;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getDockJigglingEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 40, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setDockJigglingEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 40, value);
};


/**
 * optional bool allow_takeoff_from_mismatched_dock = 41;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getAllowTakeoffFromMismatchedDock = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 41, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setAllowTakeoffFromMismatchedDock = function(value) {
  return jspb.Message.setProto3BooleanField(this, 41, value);
};


/**
 * optional bool battery_button_launch = 42;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getBatteryButtonLaunch = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 42, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setBatteryButtonLaunch = function(value) {
  return jspb.Message.setProto3BooleanField(this, 42, value);
};


/**
 * optional bool rf_high_power = 43;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getRfHighPower = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 43, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setRfHighPower = function(value) {
  return jspb.Message.setProto3BooleanField(this, 43, value);
};


/**
 * optional FlightMode flight_mode = 44;
 * @return {!proto.flight_deck.VehicleSettings.FlightMode}
 */
proto.flight_deck.VehicleSettings.prototype.getFlightMode = function() {
  return /** @type {!proto.flight_deck.VehicleSettings.FlightMode} */ (jspb.Message.getFieldWithDefault(this, 44, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettings.FlightMode} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setFlightMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 44, value);
};


/**
 * optional bool rid_feature_enabled = 50;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getRidFeatureEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 50, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setRidFeatureEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 50, value);
};


/**
 * optional bool allow_on_vehicle_photogrammetry = 45;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getAllowOnVehiclePhotogrammetry = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 45, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setAllowOnVehiclePhotogrammetry = function(value) {
  return jspb.Message.setProto3BooleanField(this, 45, value);
};


/**
 * optional bool rid_feature_disabled = 51;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getRidFeatureDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 51, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setRidFeatureDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 51, value);
};


/**
 * optional bool allow_surface_scan_alpha_features = 52;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getAllowSurfaceScanAlphaFeatures = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 52, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setAllowSurfaceScanAlphaFeatures = function(value) {
  return jspb.Message.setProto3BooleanField(this, 52, value);
};


/**
 * optional int32 reboot_max_uptime_seconds = 53;
 * @return {number}
 */
proto.flight_deck.VehicleSettings.prototype.getRebootMaxUptimeSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 53, 0));
};


/**
 * @param {number} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setRebootMaxUptimeSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 53, value);
};


/**
 * optional fault_detectors.BatteryCellVoltageImbalanceThresholds batt_imbalance_thresholds = 56;
 * @return {?proto.fault_detectors.BatteryCellVoltageImbalanceThresholds}
 */
proto.flight_deck.VehicleSettings.prototype.getBattImbalanceThresholds = function() {
  return /** @type{?proto.fault_detectors.BatteryCellVoltageImbalanceThresholds} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_health_monitor_fault_detectors_pb.BatteryCellVoltageImbalanceThresholds, 56));
};


/**
 * @param {?proto.fault_detectors.BatteryCellVoltageImbalanceThresholds|undefined} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
*/
proto.flight_deck.VehicleSettings.prototype.setBattImbalanceThresholds = function(value) {
  return jspb.Message.setWrapperField(this, 56, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.clearBattImbalanceThresholds = function() {
  return this.setBattImbalanceThresholds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.hasBattImbalanceThresholds = function() {
  return jspb.Message.getField(this, 56) != null;
};


/**
 * optional string wifi_country_code = 57;
 * @return {string}
 */
proto.flight_deck.VehicleSettings.prototype.getWifiCountryCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 57, ""));
};


/**
 * @param {string} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setWifiCountryCode = function(value) {
  return jspb.Message.setProto3StringField(this, 57, value);
};


/**
 * optional bool stop_at_structure = 58;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getStopAtStructure = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 58, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setStopAtStructure = function(value) {
  return jspb.Message.setProto3BooleanField(this, 58, value);
};


/**
 * optional float speed_near_obstacles = 59;
 * @return {number}
 */
proto.flight_deck.VehicleSettings.prototype.getSpeedNearObstacles = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 59, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setSpeedNearObstacles = function(value) {
  return jspb.Message.setProto3FloatField(this, 59, value);
};


/**
 * optional bool surface_scan = 60;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getSurfaceScan = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 60, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setSurfaceScan = function(value) {
  return jspb.Message.setProto3BooleanField(this, 60, value);
};


/**
 * optional LandingLedMode landing_led_mode = 61;
 * @return {!proto.flight_deck.VehicleSettings.LandingLedMode}
 */
proto.flight_deck.VehicleSettings.prototype.getLandingLedMode = function() {
  return /** @type {!proto.flight_deck.VehicleSettings.LandingLedMode} */ (jspb.Message.getFieldWithDefault(this, 61, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettings.LandingLedMode} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setLandingLedMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 61, value);
};


/**
 * optional bool enable_rapid_descent = 63;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getEnableRapidDescent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 63, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setEnableRapidDescent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 63, value);
};


/**
 * optional bool disable_obstacle_avoidance_dock_landing = 64;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getDisableObstacleAvoidanceDockLanding = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 64, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setDisableObstacleAvoidanceDockLanding = function(value) {
  return jspb.Message.setProto3BooleanField(this, 64, value);
};


/**
 * optional bool long_range_disabled = 65;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getLongRangeDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 65, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setLongRangeDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 65, value);
};


/**
 * optional bool allow_powered_payloads = 66;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getAllowPoweredPayloads = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 66, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setAllowPoweredPayloads = function(value) {
  return jspb.Message.setProto3BooleanField(this, 66, value);
};


/**
 * optional bool disable_country_code_scan = 68;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getDisableCountryCodeScan = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 68, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setDisableCountryCodeScan = function(value) {
  return jspb.Message.setProto3BooleanField(this, 68, value);
};


/**
 * optional bool allow_3d_capture_scan_mode = 69;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getAllow3dCaptureScanMode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 69, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setAllow3dCaptureScanMode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 69, value);
};


/**
 * optional bool allow_surface_capture_scan_mode = 70;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getAllowSurfaceCaptureScanMode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 70, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setAllowSurfaceCaptureScanMode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 70, value);
};


/**
 * optional bool allow_map_capture_scan_mode = 71;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getAllowMapCaptureScanMode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 71, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setAllowMapCaptureScanMode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 71, value);
};


/**
 * optional bool allow_tower_capture_scan_mode = 35;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getAllowTowerCaptureScanMode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 35, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setAllowTowerCaptureScanMode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 35, value);
};


/**
 * optional bool allow_indoor_capture_scan_mode = 33;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getAllowIndoorCaptureScanMode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 33, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setAllowIndoorCaptureScanMode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 33, value);
};


/**
 * optional bool allow_indoor_capture_scan_mode_v2 = 97;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getAllowIndoorCaptureScanModeV2 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 97, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setAllowIndoorCaptureScanModeV2 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 97, value);
};


/**
 * optional bool allow_orbit_capture_scan_mode = 62;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getAllowOrbitCaptureScanMode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 62, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setAllowOrbitCaptureScanMode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 62, value);
};


/**
 * optional bool allow_cylinder_capture_scan_mode = 72;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getAllowCylinderCaptureScanMode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 72, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setAllowCylinderCaptureScanMode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 72, value);
};


/**
 * optional bool allow_semantic_transmission_tower_scan_mode = 80;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getAllowSemanticTransmissionTowerScanMode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 80, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setAllowSemanticTransmissionTowerScanMode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 80, value);
};


/**
 * optional bool full_spin_on_takeoff = 73;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getFullSpinOnTakeoff = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 73, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setFullSpinOnTakeoff = function(value) {
  return jspb.Message.setProto3BooleanField(this, 73, value);
};


/**
 * optional bool allow_ir_subject_tracking = 74;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getAllowIrSubjectTracking = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 74, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setAllowIrSubjectTracking = function(value) {
  return jspb.Message.setProto3BooleanField(this, 74, value);
};


/**
 * optional bool enable_isp_pixel_content_flip = 75;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getEnableIspPixelContentFlip = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 75, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setEnableIspPixelContentFlip = function(value) {
  return jspb.Message.setProto3BooleanField(this, 75, value);
};


/**
 * optional bool enable_vehicle_rtsp_klv = 77;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getEnableVehicleRtspKlv = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 77, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setEnableVehicleRtspKlv = function(value) {
  return jspb.Message.setProto3BooleanField(this, 77, value);
};


/**
 * optional bool gimbal_inspection_mode = 78;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getGimbalInspectionMode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 78, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setGimbalInspectionMode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 78, value);
};


/**
 * optional bool nightsense_disabled = 79;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getNightsenseDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 79, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setNightsenseDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 79, value);
};


/**
 * optional bool boost_disables_nightsense = 98;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getBoostDisablesNightsense = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 98, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setBoostDisablesNightsense = function(value) {
  return jspb.Message.setProto3BooleanField(this, 98, value);
};


/**
 * optional bool nightsense_off_allowed_with_poor_gps = 99;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getNightsenseOffAllowedWithPoorGps = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 99, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setNightsenseOffAllowedWithPoorGps = function(value) {
  return jspb.Message.setProto3BooleanField(this, 99, value);
};


/**
 * optional bool nightsense_disabled_night_autonomy_switch = 100;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getNightsenseDisabledNightAutonomySwitch = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 100, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setNightsenseDisabledNightAutonomySwitch = function(value) {
  return jspb.Message.setProto3BooleanField(this, 100, value);
};


/**
 * optional bool flash_drive_required_for_flight = 81;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getFlashDriveRequiredForFlight = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 81, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setFlashDriveRequiredForFlight = function(value) {
  return jspb.Message.setProto3BooleanField(this, 81, value);
};


/**
 * optional int32 manual_band_selection = 82;
 * @return {number}
 */
proto.flight_deck.VehicleSettings.prototype.getManualBandSelection = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 82, 0));
};


/**
 * @param {number} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setManualBandSelection = function(value) {
  return jspb.Message.setProto3IntField(this, 82, value);
};


/**
 * repeated int32 band_selection_list = 83;
 * @return {!Array<number>}
 */
proto.flight_deck.VehicleSettings.prototype.getBandSelectionListList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 83));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setBandSelectionListList = function(value) {
  return jspb.Message.setField(this, 83, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.addBandSelectionList = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 83, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.clearBandSelectionListList = function() {
  return this.setBandSelectionListList([]);
};


/**
 * optional int32 manual_radiowidth_selection = 84;
 * @return {number}
 */
proto.flight_deck.VehicleSettings.prototype.getManualRadiowidthSelection = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 84, 0));
};


/**
 * @param {number} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setManualRadiowidthSelection = function(value) {
  return jspb.Message.setProto3IntField(this, 84, value);
};


/**
 * repeated int32 radiowidth_selection_list = 85;
 * @return {!Array<number>}
 */
proto.flight_deck.VehicleSettings.prototype.getRadiowidthSelectionListList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 85));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setRadiowidthSelectionListList = function(value) {
  return jspb.Message.setField(this, 85, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.addRadiowidthSelectionList = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 85, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.clearRadiowidthSelectionListList = function() {
  return this.setRadiowidthSelectionListList([]);
};


/**
 * optional bool lte_modem_enabled = 86;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getLteModemEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 86, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setLteModemEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 86, value);
};


/**
 * optional bool allow_commanded_eland = 87;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getAllowCommandedEland = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 87, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setAllowCommandedEland = function(value) {
  return jspb.Message.setProto3BooleanField(this, 87, value);
};


/**
 * optional bool allow_internal_mission_actions = 88;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getAllowInternalMissionActions = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 88, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setAllowInternalMissionActions = function(value) {
  return jspb.Message.setProto3BooleanField(this, 88, value);
};


/**
 * optional bool auto_land_in_atti_mode = 89;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getAutoLandInAttiMode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 89, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setAutoLandInAttiMode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 89, value);
};


/**
 * optional bool mh_vehicle_dcs_capable = 90;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getMhVehicleDcsCapable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 90, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setMhVehicleDcsCapable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 90, value);
};


/**
 * optional bool increased_max_speed_map_capture = 91;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getIncreasedMaxSpeedMapCapture = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 91, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setIncreasedMaxSpeedMapCapture = function(value) {
  return jspb.Message.setProto3BooleanField(this, 91, value);
};


/**
 * optional int32 manual_wifi_channel_on_rest = 92;
 * @return {number}
 */
proto.flight_deck.VehicleSettings.prototype.getManualWifiChannelOnRest = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 92, 0));
};


/**
 * @param {number} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setManualWifiChannelOnRest = function(value) {
  return jspb.Message.setProto3IntField(this, 92, value);
};


/**
 * optional bool allow_mavlink_perch = 93;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getAllowMavlinkPerch = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 93, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setAllowMavlinkPerch = function(value) {
  return jspb.Message.setProto3BooleanField(this, 93, value);
};


/**
 * optional bool police_mode = 94;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getPoliceMode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 94, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setPoliceMode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 94, value);
};


/**
 * optional bool lte_data_saver = 95;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getLteDataSaver = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 95, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setLteDataSaver = function(value) {
  return jspb.Message.setProto3BooleanField(this, 95, value);
};


/**
 * optional bool blackout_mode_enabled = 96;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getBlackoutModeEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 96, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setBlackoutModeEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 96, value);
};


/**
 * optional bool disable_gps = 101;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getDisableGps = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 101, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setDisableGps = function(value) {
  return jspb.Message.setProto3BooleanField(this, 101, value);
};


/**
 * optional int32 manual_frame_selection = 103;
 * @return {number}
 */
proto.flight_deck.VehicleSettings.prototype.getManualFrameSelection = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 103, 0));
};


/**
 * @param {number} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setManualFrameSelection = function(value) {
  return jspb.Message.setProto3IntField(this, 103, value);
};


/**
 * repeated int32 frame_selection_list = 104;
 * @return {!Array<number>}
 */
proto.flight_deck.VehicleSettings.prototype.getFrameSelectionListList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 104));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setFrameSelectionListList = function(value) {
  return jspb.Message.setField(this, 104, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.addFrameSelectionList = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 104, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.clearFrameSelectionListList = function() {
  return this.setFrameSelectionListList([]);
};


/**
 * optional bool enable_high_altitude_vio = 105;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getEnableHighAltitudeVio = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 105, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setEnableHighAltitudeVio = function(value) {
  return jspb.Message.setProto3BooleanField(this, 105, value);
};


/**
 * optional bool enable_attitude_rtx = 106;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getEnableAttitudeRtx = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 106, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setEnableAttitudeRtx = function(value) {
  return jspb.Message.setProto3BooleanField(this, 106, value);
};


/**
 * optional bool enable_manual_wff = 107;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getEnableManualWff = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 107, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setEnableManualWff = function(value) {
  return jspb.Message.setProto3BooleanField(this, 107, value);
};


/**
 * optional bool oa_off_ground_avoidance_disabled = 108;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getOaOffGroundAvoidanceDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 108, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setOaOffGroundAvoidanceDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 108, value);
};


/**
 * optional bool enable_jpg_high_compress_3ds = 109;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getEnableJpgHighCompress3ds = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 109, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setEnableJpgHighCompress3ds = function(value) {
  return jspb.Message.setProto3BooleanField(this, 109, value);
};


/**
 * optional bool enable_perch = 110;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getEnablePerch = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 110, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setEnablePerch = function(value) {
  return jspb.Message.setProto3BooleanField(this, 110, value);
};


/**
 * optional int32 manual_mode_selection = 111;
 * @return {number}
 */
proto.flight_deck.VehicleSettings.prototype.getManualModeSelection = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 111, 0));
};


/**
 * @param {number} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setManualModeSelection = function(value) {
  return jspb.Message.setProto3IntField(this, 111, value);
};


/**
 * repeated int32 mode_selection_list = 112;
 * @return {!Array<number>}
 */
proto.flight_deck.VehicleSettings.prototype.getModeSelectionListList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 112));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setModeSelectionListList = function(value) {
  return jspb.Message.setField(this, 112, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.addModeSelectionList = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 112, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.clearModeSelectionListList = function() {
  return this.setModeSelectionListList([]);
};


/**
 * optional bool enable_commanded_attitude = 113;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getEnableCommandedAttitude = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 113, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setEnableCommandedAttitude = function(value) {
  return jspb.Message.setProto3BooleanField(this, 113, value);
};


/**
 * optional bool public_safety_police_mode = 114;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getPublicSafetyPoliceMode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 114, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setPublicSafetyPoliceMode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 114, value);
};


/**
 * optional bool public_safety_emergency_mode = 115;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getPublicSafetyEmergencyMode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 115, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setPublicSafetyEmergencyMode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 115, value);
};


/**
 * optional bool european_union_lighting = 127;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getEuropeanUnionLighting = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 127, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setEuropeanUnionLighting = function(value) {
  return jspb.Message.setProto3BooleanField(this, 127, value);
};


/**
 * optional bool commanded_attitude_mode_on = 116;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getCommandedAttitudeModeOn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 116, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setCommandedAttitudeModeOn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 116, value);
};


/**
 * optional bool enable_get_microhard_rf_config = 117;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getEnableGetMicrohardRfConfig = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 117, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setEnableGetMicrohardRfConfig = function(value) {
  return jspb.Message.setProto3BooleanField(this, 117, value);
};


/**
 * optional bool enable_commanded_lost_comms = 118;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getEnableCommandedLostComms = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 118, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setEnableCommandedLostComms = function(value) {
  return jspb.Message.setProto3BooleanField(this, 118, value);
};


/**
 * optional MavlinkClientSettings mavlink_client_settings = 119;
 * @return {?proto.flight_deck.MavlinkClientSettings}
 */
proto.flight_deck.VehicleSettings.prototype.getMavlinkClientSettings = function() {
  return /** @type{?proto.flight_deck.MavlinkClientSettings} */ (
    jspb.Message.getWrapperField(this, proto.flight_deck.MavlinkClientSettings, 119));
};


/**
 * @param {?proto.flight_deck.MavlinkClientSettings|undefined} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
*/
proto.flight_deck.VehicleSettings.prototype.setMavlinkClientSettings = function(value) {
  return jspb.Message.setWrapperField(this, 119, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.clearMavlinkClientSettings = function() {
  return this.setMavlinkClientSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.hasMavlinkClientSettings = function() {
  return jspb.Message.getField(this, 119) != null;
};


/**
 * optional bool enable_gps_spoofing_detector = 120;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getEnableGpsSpoofingDetector = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 120, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setEnableGpsSpoofingDetector = function(value) {
  return jspb.Message.setProto3BooleanField(this, 120, value);
};


/**
 * optional bool enable_rsc_toggle_3ds = 121;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getEnableRscToggle3ds = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 121, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setEnableRscToggle3ds = function(value) {
  return jspb.Message.setProto3BooleanField(this, 121, value);
};


/**
 * optional bool enable_low_takeoff = 122;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getEnableLowTakeoff = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 122, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setEnableLowTakeoff = function(value) {
  return jspb.Message.setProto3BooleanField(this, 122, value);
};


/**
 * optional bool enable_rtk_support = 123;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getEnableRtkSupport = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 123, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setEnableRtkSupport = function(value) {
  return jspb.Message.setProto3BooleanField(this, 123, value);
};


/**
 * optional bool enable_flight_file_manifest_task = 124;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getEnableFlightFileManifestTask = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 124, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setEnableFlightFileManifestTask = function(value) {
  return jspb.Message.setProto3BooleanField(this, 124, value);
};


/**
 * optional bool is_european_union = 125;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getIsEuropeanUnion = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 125, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setIsEuropeanUnion = function(value) {
  return jspb.Message.setProto3BooleanField(this, 125, value);
};


/**
 * optional bool enable_eu_slow_speed = 126;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getEnableEuSlowSpeed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 126, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setEnableEuSlowSpeed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 126, value);
};


/**
 * optional bool enable_force_vio_degraded = 128;
 * @return {boolean}
 */
proto.flight_deck.VehicleSettings.prototype.getEnableForceVioDegraded = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 128, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.VehicleSettings} returns this
 */
proto.flight_deck.VehicleSettings.prototype.setEnableForceVioDegraded = function(value) {
  return jspb.Message.setProto3BooleanField(this, 128, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.flight_deck.VehicleSettingsOverrides.repeatedFields_ = [3,4,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.toObject = function(opt_includeInstance) {
  return proto.flight_deck.VehicleSettingsOverrides.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flight_deck.VehicleSettingsOverrides} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.VehicleSettingsOverrides.toObject = function(includeInstance, msg) {
  var f, obj = {
safetyHeightOverride: jspb.Message.getFieldWithDefault(msg, 1, 0),
heightCeilingOverride: jspb.Message.getFieldWithDefault(msg, 2, 0),
allowedIlluminationModesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
allowedObstacleMarginModesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
returnWithVisionOnlyOverride: jspb.Message.getFieldWithDefault(msg, 5, 0),
superzoomOverride: jspb.Message.getFieldWithDefault(msg, 6, 0),
digitalZoomOverride: jspb.Message.getFieldWithDefault(msg, 10, 0),
precisionFlightModeOverride: jspb.Message.getFieldWithDefault(msg, 7, 0),
skydiolinkAes256Override: jspb.Message.getFieldWithDefault(msg, 8, 0),
allowedNavigationModesList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
ignoreLowBatteryBehaviorOverride: jspb.Message.getFieldWithDefault(msg, 11, 0),
disableMediaEncryptionOverride: jspb.Message.getFieldWithDefault(msg, 12, 0),
offlineOverride: jspb.Message.getFieldWithDefault(msg, 13, 0),
rgbLedDisableOverride: jspb.Message.getFieldWithDefault(msg, 14, 0),
sdcardOverwriteOverride: jspb.Message.getFieldWithDefault(msg, 15, 0),
flightHistoryOverride: jspb.Message.getFieldWithDefault(msg, 16, 0),
multiResPreviewEnableOverride: jspb.Message.getFieldWithDefault(msg, 18, 0),
centralizedFrbRateEnableOverride: jspb.Message.getFieldWithDefault(msg, 19, 0),
alignedTelemetryCsvOverride: jspb.Message.getFieldWithDefault(msg, 21, 0),
displayHandWaveCalibrationOverride: jspb.Message.getFieldWithDefault(msg, 23, 0),
rebootBeforeScheduledMissionsSecondsOverride: jspb.Message.getFieldWithDefault(msg, 24, 0),
variableObstacleAvoidanceOverride: jspb.Message.getFieldWithDefault(msg, 25, 0),
dockJigglingEnabledOverride: jspb.Message.getFieldWithDefault(msg, 26, 0),
allowTakeoffFromMismatchedDockOverride: jspb.Message.getFieldWithDefault(msg, 27, 0),
allowOnVehiclePhotogrammetryOverride: jspb.Message.getFieldWithDefault(msg, 28, 0),
useBacktrackingRtxOverride: jspb.Message.getFieldWithDefault(msg, 29, 0),
ridFeatureEnabledOverride: jspb.Message.getFieldWithDefault(msg, 34, 0),
ridFeatureDisabledOverride: jspb.Message.getFieldWithDefault(msg, 35, 0),
allowSurfaceScanAlphaFeaturesOverride: jspb.Message.getFieldWithDefault(msg, 36, 0),
rebootMaxUptimeSecondsOverride: jspb.Message.getFieldWithDefault(msg, 37, 0),
defaultGeofence: (f = msg.getDefaultGeofence()) && pbtypes_util_geofence_geofence_pb.Geofence.toObject(includeInstance, f),
battImbalanceThresholdsOverride: (f = msg.getBattImbalanceThresholdsOverride()) && pbtypes_vehicle_health_monitor_fault_detectors_pb.BatteryCellVoltageImbalanceThresholds.toObject(includeInstance, f),
surfaceScanOverride: jspb.Message.getFieldWithDefault(msg, 40, 0),
disableObstacleAvoidanceDockLandingOverride: jspb.Message.getFieldWithDefault(msg, 42, 0),
allowPoweredPayloadsOverride: jspb.Message.getFieldWithDefault(msg, 43, 0),
disableCountryCodeScanOverride: jspb.Message.getFieldWithDefault(msg, 45, 0),
allow3dCaptureScanModeOverride: jspb.Message.getFieldWithDefault(msg, 46, 0),
allowSurfaceCaptureScanModeOverride: jspb.Message.getFieldWithDefault(msg, 47, 0),
allowMapCaptureScanModeOverride: jspb.Message.getFieldWithDefault(msg, 48, 0),
allowTowerCaptureScanModeOverride: jspb.Message.getFieldWithDefault(msg, 22, 0),
allowIndoorCaptureScanModeOverride: jspb.Message.getFieldWithDefault(msg, 20, 0),
allowIndoorCaptureScanModeV2Override: jspb.Message.getFieldWithDefault(msg, 63, 0),
allowOrbitCaptureScanModeOverride: jspb.Message.getFieldWithDefault(msg, 41, 0),
allowCylinderCaptureScanModeOverride: jspb.Message.getFieldWithDefault(msg, 49, 0),
allowSemanticTransmissionTowerScanModeOverride: jspb.Message.getFieldWithDefault(msg, 56, 0),
fullSpinOnTakeoffOverride: jspb.Message.getFieldWithDefault(msg, 50, 0),
allowIrSubjectTrackingOverride: jspb.Message.getFieldWithDefault(msg, 51, 0),
enableIspPixelContentFlipOverride: jspb.Message.getFieldWithDefault(msg, 52, 0),
enableVehicleRtspKlvOverride: jspb.Message.getFieldWithDefault(msg, 54, 0),
gimbalInspectionModeOverride: jspb.Message.getFieldWithDefault(msg, 55, 0),
flashDriveRequiredForFlightOverride: jspb.Message.getFieldWithDefault(msg, 57, 0),
allowCommandedElandOverride: jspb.Message.getFieldWithDefault(msg, 58, 0),
allowInternalMissionActionsOverride: jspb.Message.getFieldWithDefault(msg, 59, 0),
increasedMaxSpeedMapCaptureOverride: jspb.Message.getFieldWithDefault(msg, 60, 0),
allowMavlinkPerchOverride: jspb.Message.getFieldWithDefault(msg, 61, 0),
forceRgbLedDisableOverride: jspb.Message.getFieldWithDefault(msg, 62, 0),
enableHighAltitudeVioOverride: jspb.Message.getFieldWithDefault(msg, 64, 0),
enableAttitudeRtxOverride: jspb.Message.getFieldWithDefault(msg, 65, 0),
enableManualWffOverride: jspb.Message.getFieldWithDefault(msg, 66, 0),
enableJpgHighCompress3dsOverride: jspb.Message.getFieldWithDefault(msg, 67, 0),
enableGetMicrohardRfConfigOverride: jspb.Message.getFieldWithDefault(msg, 68, 0),
enableCommandedLostCommsOverride: jspb.Message.getFieldWithDefault(msg, 69, 0),
enableGpsSpoofingDetectorOverride: jspb.Message.getFieldWithDefault(msg, 70, 0),
enableRscToggle3dsOverride: jspb.Message.getFieldWithDefault(msg, 71, 0),
enableLowTakeoffOverride: jspb.Message.getFieldWithDefault(msg, 72, 0),
enableRtkSupportOverride: jspb.Message.getFieldWithDefault(msg, 73, 0),
enableFlightFileManifestTaskOverride: jspb.Message.getFieldWithDefault(msg, 74, 0),
isEuropeanUnionOverride: jspb.Message.getFieldWithDefault(msg, 75, 0),
enablePerchOverride: jspb.Message.getFieldWithDefault(msg, 76, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flight_deck.VehicleSettingsOverrides}
 */
proto.flight_deck.VehicleSettingsOverrides.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flight_deck.VehicleSettingsOverrides;
  return proto.flight_deck.VehicleSettingsOverrides.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flight_deck.VehicleSettingsOverrides} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flight_deck.VehicleSettingsOverrides}
 */
proto.flight_deck.VehicleSettingsOverrides.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setSafetyHeightOverride(value);
      break;
    case 2:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setHeightCeilingOverride(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.flight_deck.VehicleSettings.IlluminationMode>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAllowedIlluminationModes(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<!proto.flight_deck.VehicleSettings.ObstacleMarginMode>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAllowedObstacleMarginModes(values[i]);
      }
      break;
    case 5:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setReturnWithVisionOnlyOverride(value);
      break;
    case 6:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setSuperzoomOverride(value);
      break;
    case 10:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setDigitalZoomOverride(value);
      break;
    case 7:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setPrecisionFlightModeOverride(value);
      break;
    case 8:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setSkydiolinkAes256Override(value);
      break;
    case 9:
      var values = /** @type {!Array<!proto.flight_deck.NavigationMode.Mode>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAllowedNavigationModes(values[i]);
      }
      break;
    case 11:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setIgnoreLowBatteryBehaviorOverride(value);
      break;
    case 12:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setDisableMediaEncryptionOverride(value);
      break;
    case 13:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setOfflineOverride(value);
      break;
    case 14:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setRgbLedDisableOverride(value);
      break;
    case 15:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setSdcardOverwriteOverride(value);
      break;
    case 16:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setFlightHistoryOverride(value);
      break;
    case 18:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setMultiResPreviewEnableOverride(value);
      break;
    case 19:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setCentralizedFrbRateEnableOverride(value);
      break;
    case 21:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setAlignedTelemetryCsvOverride(value);
      break;
    case 23:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setDisplayHandWaveCalibrationOverride(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRebootBeforeScheduledMissionsSecondsOverride(value);
      break;
    case 25:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setVariableObstacleAvoidanceOverride(value);
      break;
    case 26:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setDockJigglingEnabledOverride(value);
      break;
    case 27:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setAllowTakeoffFromMismatchedDockOverride(value);
      break;
    case 28:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setAllowOnVehiclePhotogrammetryOverride(value);
      break;
    case 29:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setUseBacktrackingRtxOverride(value);
      break;
    case 34:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setRidFeatureEnabledOverride(value);
      break;
    case 35:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setRidFeatureDisabledOverride(value);
      break;
    case 36:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setAllowSurfaceScanAlphaFeaturesOverride(value);
      break;
    case 37:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRebootMaxUptimeSecondsOverride(value);
      break;
    case 38:
      var value = new pbtypes_util_geofence_geofence_pb.Geofence;
      reader.readMessage(value,pbtypes_util_geofence_geofence_pb.Geofence.deserializeBinaryFromReader);
      msg.setDefaultGeofence(value);
      break;
    case 39:
      var value = new pbtypes_vehicle_health_monitor_fault_detectors_pb.BatteryCellVoltageImbalanceThresholds;
      reader.readMessage(value,pbtypes_vehicle_health_monitor_fault_detectors_pb.BatteryCellVoltageImbalanceThresholds.deserializeBinaryFromReader);
      msg.setBattImbalanceThresholdsOverride(value);
      break;
    case 40:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setSurfaceScanOverride(value);
      break;
    case 42:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setDisableObstacleAvoidanceDockLandingOverride(value);
      break;
    case 43:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setAllowPoweredPayloadsOverride(value);
      break;
    case 45:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setDisableCountryCodeScanOverride(value);
      break;
    case 46:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setAllow3dCaptureScanModeOverride(value);
      break;
    case 47:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setAllowSurfaceCaptureScanModeOverride(value);
      break;
    case 48:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setAllowMapCaptureScanModeOverride(value);
      break;
    case 22:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setAllowTowerCaptureScanModeOverride(value);
      break;
    case 20:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setAllowIndoorCaptureScanModeOverride(value);
      break;
    case 63:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setAllowIndoorCaptureScanModeV2Override(value);
      break;
    case 41:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setAllowOrbitCaptureScanModeOverride(value);
      break;
    case 49:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setAllowCylinderCaptureScanModeOverride(value);
      break;
    case 56:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setAllowSemanticTransmissionTowerScanModeOverride(value);
      break;
    case 50:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setFullSpinOnTakeoffOverride(value);
      break;
    case 51:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setAllowIrSubjectTrackingOverride(value);
      break;
    case 52:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setEnableIspPixelContentFlipOverride(value);
      break;
    case 54:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setEnableVehicleRtspKlvOverride(value);
      break;
    case 55:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setGimbalInspectionModeOverride(value);
      break;
    case 57:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setFlashDriveRequiredForFlightOverride(value);
      break;
    case 58:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setAllowCommandedElandOverride(value);
      break;
    case 59:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setAllowInternalMissionActionsOverride(value);
      break;
    case 60:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setIncreasedMaxSpeedMapCaptureOverride(value);
      break;
    case 61:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setAllowMavlinkPerchOverride(value);
      break;
    case 62:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setForceRgbLedDisableOverride(value);
      break;
    case 64:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setEnableHighAltitudeVioOverride(value);
      break;
    case 65:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setEnableAttitudeRtxOverride(value);
      break;
    case 66:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setEnableManualWffOverride(value);
      break;
    case 67:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setEnableJpgHighCompress3dsOverride(value);
      break;
    case 68:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setEnableGetMicrohardRfConfigOverride(value);
      break;
    case 69:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setEnableCommandedLostCommsOverride(value);
      break;
    case 70:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setEnableGpsSpoofingDetectorOverride(value);
      break;
    case 71:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setEnableRscToggle3dsOverride(value);
      break;
    case 72:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setEnableLowTakeoffOverride(value);
      break;
    case 73:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setEnableRtkSupportOverride(value);
      break;
    case 74:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setEnableFlightFileManifestTaskOverride(value);
      break;
    case 75:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setIsEuropeanUnionOverride(value);
      break;
    case 76:
      var value = /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (reader.readEnum());
      msg.setEnablePerchOverride(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flight_deck.VehicleSettingsOverrides.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flight_deck.VehicleSettingsOverrides} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.VehicleSettingsOverrides.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSafetyHeightOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getHeightCeilingOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getAllowedIlluminationModesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
  f = message.getAllowedObstacleMarginModesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
  f = message.getReturnWithVisionOnlyOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getSuperzoomOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getDigitalZoomOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getPrecisionFlightModeOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getSkydiolinkAes256Override();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getAllowedNavigationModesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      9,
      f
    );
  }
  f = message.getIgnoreLowBatteryBehaviorOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getDisableMediaEncryptionOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getOfflineOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getRgbLedDisableOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getSdcardOverwriteOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getFlightHistoryOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getMultiResPreviewEnableOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      18,
      f
    );
  }
  f = message.getCentralizedFrbRateEnableOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      19,
      f
    );
  }
  f = message.getAlignedTelemetryCsvOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      21,
      f
    );
  }
  f = message.getDisplayHandWaveCalibrationOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      23,
      f
    );
  }
  f = message.getRebootBeforeScheduledMissionsSecondsOverride();
  if (f !== 0) {
    writer.writeInt32(
      24,
      f
    );
  }
  f = message.getVariableObstacleAvoidanceOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      25,
      f
    );
  }
  f = message.getDockJigglingEnabledOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      26,
      f
    );
  }
  f = message.getAllowTakeoffFromMismatchedDockOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      27,
      f
    );
  }
  f = message.getAllowOnVehiclePhotogrammetryOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      28,
      f
    );
  }
  f = message.getUseBacktrackingRtxOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      29,
      f
    );
  }
  f = message.getRidFeatureEnabledOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      34,
      f
    );
  }
  f = message.getRidFeatureDisabledOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      35,
      f
    );
  }
  f = message.getAllowSurfaceScanAlphaFeaturesOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      36,
      f
    );
  }
  f = message.getRebootMaxUptimeSecondsOverride();
  if (f !== 0) {
    writer.writeInt32(
      37,
      f
    );
  }
  f = message.getDefaultGeofence();
  if (f != null) {
    writer.writeMessage(
      38,
      f,
      pbtypes_util_geofence_geofence_pb.Geofence.serializeBinaryToWriter
    );
  }
  f = message.getBattImbalanceThresholdsOverride();
  if (f != null) {
    writer.writeMessage(
      39,
      f,
      pbtypes_vehicle_health_monitor_fault_detectors_pb.BatteryCellVoltageImbalanceThresholds.serializeBinaryToWriter
    );
  }
  f = message.getSurfaceScanOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      40,
      f
    );
  }
  f = message.getDisableObstacleAvoidanceDockLandingOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      42,
      f
    );
  }
  f = message.getAllowPoweredPayloadsOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      43,
      f
    );
  }
  f = message.getDisableCountryCodeScanOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      45,
      f
    );
  }
  f = message.getAllow3dCaptureScanModeOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      46,
      f
    );
  }
  f = message.getAllowSurfaceCaptureScanModeOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      47,
      f
    );
  }
  f = message.getAllowMapCaptureScanModeOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      48,
      f
    );
  }
  f = message.getAllowTowerCaptureScanModeOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      22,
      f
    );
  }
  f = message.getAllowIndoorCaptureScanModeOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      20,
      f
    );
  }
  f = message.getAllowIndoorCaptureScanModeV2Override();
  if (f !== 0.0) {
    writer.writeEnum(
      63,
      f
    );
  }
  f = message.getAllowOrbitCaptureScanModeOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      41,
      f
    );
  }
  f = message.getAllowCylinderCaptureScanModeOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      49,
      f
    );
  }
  f = message.getAllowSemanticTransmissionTowerScanModeOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      56,
      f
    );
  }
  f = message.getFullSpinOnTakeoffOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      50,
      f
    );
  }
  f = message.getAllowIrSubjectTrackingOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      51,
      f
    );
  }
  f = message.getEnableIspPixelContentFlipOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      52,
      f
    );
  }
  f = message.getEnableVehicleRtspKlvOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      54,
      f
    );
  }
  f = message.getGimbalInspectionModeOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      55,
      f
    );
  }
  f = message.getFlashDriveRequiredForFlightOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      57,
      f
    );
  }
  f = message.getAllowCommandedElandOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      58,
      f
    );
  }
  f = message.getAllowInternalMissionActionsOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      59,
      f
    );
  }
  f = message.getIncreasedMaxSpeedMapCaptureOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      60,
      f
    );
  }
  f = message.getAllowMavlinkPerchOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      61,
      f
    );
  }
  f = message.getForceRgbLedDisableOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      62,
      f
    );
  }
  f = message.getEnableHighAltitudeVioOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      64,
      f
    );
  }
  f = message.getEnableAttitudeRtxOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      65,
      f
    );
  }
  f = message.getEnableManualWffOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      66,
      f
    );
  }
  f = message.getEnableJpgHighCompress3dsOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      67,
      f
    );
  }
  f = message.getEnableGetMicrohardRfConfigOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      68,
      f
    );
  }
  f = message.getEnableCommandedLostCommsOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      69,
      f
    );
  }
  f = message.getEnableGpsSpoofingDetectorOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      70,
      f
    );
  }
  f = message.getEnableRscToggle3dsOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      71,
      f
    );
  }
  f = message.getEnableLowTakeoffOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      72,
      f
    );
  }
  f = message.getEnableRtkSupportOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      73,
      f
    );
  }
  f = message.getEnableFlightFileManifestTaskOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      74,
      f
    );
  }
  f = message.getIsEuropeanUnionOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      75,
      f
    );
  }
  f = message.getEnablePerchOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      76,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.flight_deck.VehicleSettingsOverrides.BooleanOverride = {
  DEFAULT: 0,
  FORCE_TRUE: 1,
  FORCE_FALSE: 2,
  USER_SETTING: 3,
  INIT_TRUE: 4
};

/**
 * optional BooleanOverride safety_height_override = 1;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getSafetyHeightOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setSafetyHeightOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional BooleanOverride height_ceiling_override = 2;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getHeightCeilingOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setHeightCeilingOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated VehicleSettings.IlluminationMode allowed_illumination_modes = 3;
 * @return {!Array<!proto.flight_deck.VehicleSettings.IlluminationMode>}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getAllowedIlluminationModesList = function() {
  return /** @type {!Array<!proto.flight_deck.VehicleSettings.IlluminationMode>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.flight_deck.VehicleSettings.IlluminationMode>} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setAllowedIlluminationModesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.flight_deck.VehicleSettings.IlluminationMode} value
 * @param {number=} opt_index
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.addAllowedIlluminationModes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.clearAllowedIlluminationModesList = function() {
  return this.setAllowedIlluminationModesList([]);
};


/**
 * repeated VehicleSettings.ObstacleMarginMode allowed_obstacle_margin_modes = 4;
 * @return {!Array<!proto.flight_deck.VehicleSettings.ObstacleMarginMode>}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getAllowedObstacleMarginModesList = function() {
  return /** @type {!Array<!proto.flight_deck.VehicleSettings.ObstacleMarginMode>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.flight_deck.VehicleSettings.ObstacleMarginMode>} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setAllowedObstacleMarginModesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.flight_deck.VehicleSettings.ObstacleMarginMode} value
 * @param {number=} opt_index
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.addAllowedObstacleMarginModes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.clearAllowedObstacleMarginModesList = function() {
  return this.setAllowedObstacleMarginModesList([]);
};


/**
 * optional BooleanOverride return_with_vision_only_override = 5;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getReturnWithVisionOnlyOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setReturnWithVisionOnlyOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional BooleanOverride superzoom_override = 6;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getSuperzoomOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setSuperzoomOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional BooleanOverride digital_zoom_override = 10;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getDigitalZoomOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setDigitalZoomOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional BooleanOverride precision_flight_mode_override = 7;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getPrecisionFlightModeOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setPrecisionFlightModeOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional BooleanOverride skydiolink_aes256_override = 8;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getSkydiolinkAes256Override = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setSkydiolinkAes256Override = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * repeated NavigationMode.Mode allowed_navigation_modes = 9;
 * @return {!Array<!proto.flight_deck.NavigationMode.Mode>}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getAllowedNavigationModesList = function() {
  return /** @type {!Array<!proto.flight_deck.NavigationMode.Mode>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<!proto.flight_deck.NavigationMode.Mode>} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setAllowedNavigationModesList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {!proto.flight_deck.NavigationMode.Mode} value
 * @param {number=} opt_index
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.addAllowedNavigationModes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.clearAllowedNavigationModesList = function() {
  return this.setAllowedNavigationModesList([]);
};


/**
 * optional BooleanOverride ignore_low_battery_behavior_override = 11;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getIgnoreLowBatteryBehaviorOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setIgnoreLowBatteryBehaviorOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional BooleanOverride disable_media_encryption_override = 12;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getDisableMediaEncryptionOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setDisableMediaEncryptionOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional BooleanOverride offline_override = 13;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getOfflineOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setOfflineOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional BooleanOverride rgb_led_disable_override = 14;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getRgbLedDisableOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setRgbLedDisableOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional BooleanOverride sdcard_overwrite_override = 15;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getSdcardOverwriteOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setSdcardOverwriteOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * optional BooleanOverride flight_history_override = 16;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getFlightHistoryOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setFlightHistoryOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * optional BooleanOverride multi_res_preview_enable_override = 18;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getMultiResPreviewEnableOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setMultiResPreviewEnableOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 18, value);
};


/**
 * optional BooleanOverride centralized_frb_rate_enable_override = 19;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getCentralizedFrbRateEnableOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setCentralizedFrbRateEnableOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 19, value);
};


/**
 * optional BooleanOverride aligned_telemetry_csv_override = 21;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getAlignedTelemetryCsvOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setAlignedTelemetryCsvOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 21, value);
};


/**
 * optional BooleanOverride display_hand_wave_calibration_override = 23;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getDisplayHandWaveCalibrationOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setDisplayHandWaveCalibrationOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 23, value);
};


/**
 * optional int32 reboot_before_scheduled_missions_seconds_override = 24;
 * @return {number}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getRebootBeforeScheduledMissionsSecondsOverride = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setRebootBeforeScheduledMissionsSecondsOverride = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional BooleanOverride variable_obstacle_avoidance_override = 25;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getVariableObstacleAvoidanceOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setVariableObstacleAvoidanceOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 25, value);
};


/**
 * optional BooleanOverride dock_jiggling_enabled_override = 26;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getDockJigglingEnabledOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setDockJigglingEnabledOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 26, value);
};


/**
 * optional BooleanOverride allow_takeoff_from_mismatched_dock_override = 27;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getAllowTakeoffFromMismatchedDockOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setAllowTakeoffFromMismatchedDockOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 27, value);
};


/**
 * optional BooleanOverride allow_on_vehicle_photogrammetry_override = 28;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getAllowOnVehiclePhotogrammetryOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setAllowOnVehiclePhotogrammetryOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 28, value);
};


/**
 * optional BooleanOverride use_backtracking_rtx_override = 29;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getUseBacktrackingRtxOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 29, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setUseBacktrackingRtxOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 29, value);
};


/**
 * optional BooleanOverride rid_feature_enabled_override = 34;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getRidFeatureEnabledOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 34, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setRidFeatureEnabledOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 34, value);
};


/**
 * optional BooleanOverride rid_feature_disabled_override = 35;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getRidFeatureDisabledOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 35, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setRidFeatureDisabledOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 35, value);
};


/**
 * optional BooleanOverride allow_surface_scan_alpha_features_override = 36;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getAllowSurfaceScanAlphaFeaturesOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 36, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setAllowSurfaceScanAlphaFeaturesOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 36, value);
};


/**
 * optional int32 reboot_max_uptime_seconds_override = 37;
 * @return {number}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getRebootMaxUptimeSecondsOverride = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 37, 0));
};


/**
 * @param {number} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setRebootMaxUptimeSecondsOverride = function(value) {
  return jspb.Message.setProto3IntField(this, 37, value);
};


/**
 * optional geofence.Geofence default_geofence = 38;
 * @return {?proto.geofence.Geofence}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getDefaultGeofence = function() {
  return /** @type{?proto.geofence.Geofence} */ (
    jspb.Message.getWrapperField(this, pbtypes_util_geofence_geofence_pb.Geofence, 38));
};


/**
 * @param {?proto.geofence.Geofence|undefined} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
*/
proto.flight_deck.VehicleSettingsOverrides.prototype.setDefaultGeofence = function(value) {
  return jspb.Message.setWrapperField(this, 38, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.clearDefaultGeofence = function() {
  return this.setDefaultGeofence(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.hasDefaultGeofence = function() {
  return jspb.Message.getField(this, 38) != null;
};


/**
 * optional fault_detectors.BatteryCellVoltageImbalanceThresholds batt_imbalance_thresholds_override = 39;
 * @return {?proto.fault_detectors.BatteryCellVoltageImbalanceThresholds}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getBattImbalanceThresholdsOverride = function() {
  return /** @type{?proto.fault_detectors.BatteryCellVoltageImbalanceThresholds} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_health_monitor_fault_detectors_pb.BatteryCellVoltageImbalanceThresholds, 39));
};


/**
 * @param {?proto.fault_detectors.BatteryCellVoltageImbalanceThresholds|undefined} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
*/
proto.flight_deck.VehicleSettingsOverrides.prototype.setBattImbalanceThresholdsOverride = function(value) {
  return jspb.Message.setWrapperField(this, 39, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.clearBattImbalanceThresholdsOverride = function() {
  return this.setBattImbalanceThresholdsOverride(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.hasBattImbalanceThresholdsOverride = function() {
  return jspb.Message.getField(this, 39) != null;
};


/**
 * optional BooleanOverride surface_scan_override = 40;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getSurfaceScanOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 40, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setSurfaceScanOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 40, value);
};


/**
 * optional BooleanOverride disable_obstacle_avoidance_dock_landing_override = 42;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getDisableObstacleAvoidanceDockLandingOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 42, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setDisableObstacleAvoidanceDockLandingOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 42, value);
};


/**
 * optional BooleanOverride allow_powered_payloads_override = 43;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getAllowPoweredPayloadsOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 43, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setAllowPoweredPayloadsOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 43, value);
};


/**
 * optional BooleanOverride disable_country_code_scan_override = 45;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getDisableCountryCodeScanOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 45, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setDisableCountryCodeScanOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 45, value);
};


/**
 * optional BooleanOverride allow_3d_capture_scan_mode_override = 46;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getAllow3dCaptureScanModeOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 46, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setAllow3dCaptureScanModeOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 46, value);
};


/**
 * optional BooleanOverride allow_surface_capture_scan_mode_override = 47;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getAllowSurfaceCaptureScanModeOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 47, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setAllowSurfaceCaptureScanModeOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 47, value);
};


/**
 * optional BooleanOverride allow_map_capture_scan_mode_override = 48;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getAllowMapCaptureScanModeOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 48, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setAllowMapCaptureScanModeOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 48, value);
};


/**
 * optional BooleanOverride allow_tower_capture_scan_mode_override = 22;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getAllowTowerCaptureScanModeOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setAllowTowerCaptureScanModeOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 22, value);
};


/**
 * optional BooleanOverride allow_indoor_capture_scan_mode_override = 20;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getAllowIndoorCaptureScanModeOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setAllowIndoorCaptureScanModeOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 20, value);
};


/**
 * optional BooleanOverride allow_indoor_capture_scan_mode_v2_override = 63;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getAllowIndoorCaptureScanModeV2Override = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 63, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setAllowIndoorCaptureScanModeV2Override = function(value) {
  return jspb.Message.setProto3EnumField(this, 63, value);
};


/**
 * optional BooleanOverride allow_orbit_capture_scan_mode_override = 41;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getAllowOrbitCaptureScanModeOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 41, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setAllowOrbitCaptureScanModeOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 41, value);
};


/**
 * optional BooleanOverride allow_cylinder_capture_scan_mode_override = 49;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getAllowCylinderCaptureScanModeOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 49, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setAllowCylinderCaptureScanModeOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 49, value);
};


/**
 * optional BooleanOverride allow_semantic_transmission_tower_scan_mode_override = 56;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getAllowSemanticTransmissionTowerScanModeOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 56, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setAllowSemanticTransmissionTowerScanModeOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 56, value);
};


/**
 * optional BooleanOverride full_spin_on_takeoff_override = 50;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getFullSpinOnTakeoffOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 50, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setFullSpinOnTakeoffOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 50, value);
};


/**
 * optional BooleanOverride allow_ir_subject_tracking_override = 51;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getAllowIrSubjectTrackingOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 51, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setAllowIrSubjectTrackingOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 51, value);
};


/**
 * optional BooleanOverride enable_isp_pixel_content_flip_override = 52;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getEnableIspPixelContentFlipOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 52, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setEnableIspPixelContentFlipOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 52, value);
};


/**
 * optional BooleanOverride enable_vehicle_rtsp_klv_override = 54;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getEnableVehicleRtspKlvOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 54, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setEnableVehicleRtspKlvOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 54, value);
};


/**
 * optional BooleanOverride gimbal_inspection_mode_override = 55;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getGimbalInspectionModeOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 55, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setGimbalInspectionModeOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 55, value);
};


/**
 * optional BooleanOverride flash_drive_required_for_flight_override = 57;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getFlashDriveRequiredForFlightOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 57, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setFlashDriveRequiredForFlightOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 57, value);
};


/**
 * optional BooleanOverride allow_commanded_eland_override = 58;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getAllowCommandedElandOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 58, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setAllowCommandedElandOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 58, value);
};


/**
 * optional BooleanOverride allow_internal_mission_actions_override = 59;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getAllowInternalMissionActionsOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 59, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setAllowInternalMissionActionsOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 59, value);
};


/**
 * optional BooleanOverride increased_max_speed_map_capture_override = 60;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getIncreasedMaxSpeedMapCaptureOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 60, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setIncreasedMaxSpeedMapCaptureOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 60, value);
};


/**
 * optional BooleanOverride allow_mavlink_perch_override = 61;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getAllowMavlinkPerchOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 61, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setAllowMavlinkPerchOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 61, value);
};


/**
 * optional BooleanOverride force_rgb_led_disable_override = 62;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getForceRgbLedDisableOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 62, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setForceRgbLedDisableOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 62, value);
};


/**
 * optional BooleanOverride enable_high_altitude_vio_override = 64;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getEnableHighAltitudeVioOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 64, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setEnableHighAltitudeVioOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 64, value);
};


/**
 * optional BooleanOverride enable_attitude_rtx_override = 65;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getEnableAttitudeRtxOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 65, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setEnableAttitudeRtxOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 65, value);
};


/**
 * optional BooleanOverride enable_manual_wff_override = 66;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getEnableManualWffOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 66, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setEnableManualWffOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 66, value);
};


/**
 * optional BooleanOverride enable_jpg_high_compress_3ds_override = 67;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getEnableJpgHighCompress3dsOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 67, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setEnableJpgHighCompress3dsOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 67, value);
};


/**
 * optional BooleanOverride enable_get_microhard_rf_config_override = 68;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getEnableGetMicrohardRfConfigOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 68, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setEnableGetMicrohardRfConfigOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 68, value);
};


/**
 * optional BooleanOverride enable_commanded_lost_comms_override = 69;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getEnableCommandedLostCommsOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 69, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setEnableCommandedLostCommsOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 69, value);
};


/**
 * optional BooleanOverride enable_gps_spoofing_detector_override = 70;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getEnableGpsSpoofingDetectorOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 70, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setEnableGpsSpoofingDetectorOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 70, value);
};


/**
 * optional BooleanOverride enable_rsc_toggle_3ds_override = 71;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getEnableRscToggle3dsOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 71, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setEnableRscToggle3dsOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 71, value);
};


/**
 * optional BooleanOverride enable_low_takeoff_override = 72;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getEnableLowTakeoffOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 72, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setEnableLowTakeoffOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 72, value);
};


/**
 * optional BooleanOverride enable_rtk_support_override = 73;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getEnableRtkSupportOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 73, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setEnableRtkSupportOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 73, value);
};


/**
 * optional BooleanOverride enable_flight_file_manifest_task_override = 74;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getEnableFlightFileManifestTaskOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 74, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setEnableFlightFileManifestTaskOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 74, value);
};


/**
 * optional BooleanOverride is_european_union_override = 75;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getIsEuropeanUnionOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 75, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setIsEuropeanUnionOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 75, value);
};


/**
 * optional BooleanOverride enable_perch_override = 76;
 * @return {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride}
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.getEnablePerchOverride = function() {
  return /** @type {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} */ (jspb.Message.getFieldWithDefault(this, 76, 0));
};


/**
 * @param {!proto.flight_deck.VehicleSettingsOverrides.BooleanOverride} value
 * @return {!proto.flight_deck.VehicleSettingsOverrides} returns this
 */
proto.flight_deck.VehicleSettingsOverrides.prototype.setEnablePerchOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 76, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flight_deck.SettingsUpdateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.flight_deck.SettingsUpdateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flight_deck.SettingsUpdateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.SettingsUpdateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flight_deck.SettingsUpdateResponse}
 */
proto.flight_deck.SettingsUpdateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flight_deck.SettingsUpdateResponse;
  return proto.flight_deck.SettingsUpdateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flight_deck.SettingsUpdateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flight_deck.SettingsUpdateResponse}
 */
proto.flight_deck.SettingsUpdateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flight_deck.SettingsUpdateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flight_deck.SettingsUpdateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flight_deck.SettingsUpdateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.SettingsUpdateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.flight_deck.SettingsUpdateResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.SettingsUpdateResponse} returns this
 */
proto.flight_deck.SettingsUpdateResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flight_deck.SettingsPreviewSource.prototype.toObject = function(opt_includeInstance) {
  return proto.flight_deck.SettingsPreviewSource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flight_deck.SettingsPreviewSource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.SettingsPreviewSource.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
previewSource: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flight_deck.SettingsPreviewSource}
 */
proto.flight_deck.SettingsPreviewSource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flight_deck.SettingsPreviewSource;
  return proto.flight_deck.SettingsPreviewSource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flight_deck.SettingsPreviewSource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flight_deck.SettingsPreviewSource}
 */
proto.flight_deck.SettingsPreviewSource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPreviewSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flight_deck.SettingsPreviewSource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flight_deck.SettingsPreviewSource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flight_deck.SettingsPreviewSource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.SettingsPreviewSource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPreviewSource();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.flight_deck.SettingsPreviewSource.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.flight_deck.SettingsPreviewSource} returns this
 */
proto.flight_deck.SettingsPreviewSource.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool preview_source = 2;
 * @return {boolean}
 */
proto.flight_deck.SettingsPreviewSource.prototype.getPreviewSource = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.SettingsPreviewSource} returns this
 */
proto.flight_deck.SettingsPreviewSource.prototype.setPreviewSource = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


goog.object.extend(exports, proto.flight_deck);
