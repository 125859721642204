/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "skills";

/**
 * This file automatically generated by skymarshal.
 * DO NOT MODIFY BY HAND!!!!
 */

export interface GoToWaypointSource {
}

export enum GoToWaypointSource_Enum {
  UNSPECIFIED = 0,
  CLIENT = 1,
  SKILL = 2,
  AUTONOMY = 3,
  FAULT = 4,
  BACKGROUND = 5,
  MISSION = 6,
  EXTERNAL_API = 7,
  UNRECOGNIZED = -1,
}

export function goToWaypointSource_EnumFromJSON(object: any): GoToWaypointSource_Enum {
  switch (object) {
    case 0:
    case "UNSPECIFIED":
      return GoToWaypointSource_Enum.UNSPECIFIED;
    case 1:
    case "CLIENT":
      return GoToWaypointSource_Enum.CLIENT;
    case 2:
    case "SKILL":
      return GoToWaypointSource_Enum.SKILL;
    case 3:
    case "AUTONOMY":
      return GoToWaypointSource_Enum.AUTONOMY;
    case 4:
    case "FAULT":
      return GoToWaypointSource_Enum.FAULT;
    case 5:
    case "BACKGROUND":
      return GoToWaypointSource_Enum.BACKGROUND;
    case 6:
    case "MISSION":
      return GoToWaypointSource_Enum.MISSION;
    case 7:
    case "EXTERNAL_API":
      return GoToWaypointSource_Enum.EXTERNAL_API;
    case -1:
    case "UNRECOGNIZED":
    default:
      return GoToWaypointSource_Enum.UNRECOGNIZED;
  }
}

export function goToWaypointSource_EnumToJSON(object: GoToWaypointSource_Enum): string {
  switch (object) {
    case GoToWaypointSource_Enum.UNSPECIFIED:
      return "UNSPECIFIED";
    case GoToWaypointSource_Enum.CLIENT:
      return "CLIENT";
    case GoToWaypointSource_Enum.SKILL:
      return "SKILL";
    case GoToWaypointSource_Enum.AUTONOMY:
      return "AUTONOMY";
    case GoToWaypointSource_Enum.FAULT:
      return "FAULT";
    case GoToWaypointSource_Enum.BACKGROUND:
      return "BACKGROUND";
    case GoToWaypointSource_Enum.MISSION:
      return "MISSION";
    case GoToWaypointSource_Enum.EXTERNAL_API:
      return "EXTERNAL_API";
    case GoToWaypointSource_Enum.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

function createBaseGoToWaypointSource(): GoToWaypointSource {
  return {};
}

export const GoToWaypointSource = {
  encode(_: GoToWaypointSource, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GoToWaypointSource {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGoToWaypointSource();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GoToWaypointSource {
    return {};
  },

  toJSON(_: GoToWaypointSource): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GoToWaypointSource>, I>>(base?: I): GoToWaypointSource {
    return GoToWaypointSource.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GoToWaypointSource>, I>>(_: I): GoToWaypointSource {
    const message = createBaseGoToWaypointSource();
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };
