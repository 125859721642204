/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "eigen_lcm";

/**
 * This file automatically generated by skymarshal.
 * DO NOT MODIFY BY HAND!!!!
 */

export interface QuaterniondPb {
  xyzw: number[];
}

function createBaseQuaterniondPb(): QuaterniondPb {
  return { xyzw: [] };
}

export const QuaterniondPb = {
  encode(message: QuaterniondPb, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(26).fork();
    for (const v of message.xyzw) {
      writer.double(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuaterniondPb {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuaterniondPb();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag === 25) {
            message.xyzw.push(reader.double());

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.xyzw.push(reader.double());
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuaterniondPb {
    return { xyzw: globalThis.Array.isArray(object?.xyzw) ? object.xyzw.map((e: any) => globalThis.Number(e)) : [] };
  },

  toJSON(message: QuaterniondPb): unknown {
    const obj: any = {};
    if (message.xyzw?.length) {
      obj.xyzw = message.xyzw;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<QuaterniondPb>, I>>(base?: I): QuaterniondPb {
    return QuaterniondPb.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<QuaterniondPb>, I>>(object: I): QuaterniondPb {
    const message = createBaseQuaterniondPb();
    message.xyzw = object.xyzw?.map((e) => e) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };
