/* eslint-disable */
import _m0 from "protobufjs/minimal";
import {
  FreeLookMode_Enum,
  freeLookMode_EnumFromJSON,
  freeLookMode_EnumToJSON,
  MotionArgs,
  Waypoint,
} from "../../waypoints";

export const protobufPackage = "skills";

export interface OrbitPoiActionArgs {
  /**
   * Arguments for running an orbit POI action
   * Waypoint specifiying the center of the orbit, including height
   */
  orbitCenter:
    | Waypoint
    | undefined;
  /** Radius of the orbit in meters */
  radius: number;
  /** Orbit speed in meters per second */
  speed: number;
  /** Number of rotations to perform before the action is complete */
  numRotations: number;
  /** Gimbal pitch to maintain while performing the orbit */
  gimbalPitch: number;
  /** Whether to hide AR or not (default is to display it) */
  hideAr: boolean;
}

export interface OrbitPoiResultData {
}

export interface OrbitPoiActionStateData {
  /**
   * Currently active args used by the orbit. Note that this may be different from when the orbit
   * was started since the user can change them with movement commands and other interactions.
   */
  actionArgs: OrbitPoiActionArgs | undefined;
}

/** Determines where the waypoint that intercepts the orbit is placed in the GotoAndOrbitPoiAction */
export interface InterceptOrbitPoiMode {
}

export enum InterceptOrbitPoiMode_Enum {
  /** UNSPECIFIED - Default behavior, no waypoint is placed before the orbit */
  UNSPECIFIED = 0,
  /** PERPENDICULAR - Intersect the orbit by flying to the closest point on the orbit radius */
  PERPENDICULAR = 1,
  /** TANGENTIAL - Intersect the orbit at a tangent */
  TANGENTIAL = 2,
  /** CUSTOM - Custom point after which to start the orbit */
  CUSTOM = 3,
  UNRECOGNIZED = -1,
}

export function interceptOrbitPoiMode_EnumFromJSON(object: any): InterceptOrbitPoiMode_Enum {
  switch (object) {
    case 0:
    case "UNSPECIFIED":
      return InterceptOrbitPoiMode_Enum.UNSPECIFIED;
    case 1:
    case "PERPENDICULAR":
      return InterceptOrbitPoiMode_Enum.PERPENDICULAR;
    case 2:
    case "TANGENTIAL":
      return InterceptOrbitPoiMode_Enum.TANGENTIAL;
    case 3:
    case "CUSTOM":
      return InterceptOrbitPoiMode_Enum.CUSTOM;
    case -1:
    case "UNRECOGNIZED":
    default:
      return InterceptOrbitPoiMode_Enum.UNRECOGNIZED;
  }
}

export function interceptOrbitPoiMode_EnumToJSON(object: InterceptOrbitPoiMode_Enum): string {
  switch (object) {
    case InterceptOrbitPoiMode_Enum.UNSPECIFIED:
      return "UNSPECIFIED";
    case InterceptOrbitPoiMode_Enum.PERPENDICULAR:
      return "PERPENDICULAR";
    case InterceptOrbitPoiMode_Enum.TANGENTIAL:
      return "TANGENTIAL";
    case InterceptOrbitPoiMode_Enum.CUSTOM:
      return "CUSTOM";
    case InterceptOrbitPoiMode_Enum.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Consists of a go to waypoint, followed by an orbit to more predictably enter the orbit */
export interface GotoAndOrbitPoiActionArgs {
  orbitArgs: OrbitPoiActionArgs | undefined;
  interceptMode: InterceptOrbitPoiMode_Enum;
  /** Motion args for how to approach the waypoint placed before the orbit */
  motionArgs:
    | MotionArgs
    | undefined;
  /** if intercept_mode is CUSTOM, then this waypoint will be added before the orbit */
  customIntercept:
    | Waypoint
    | undefined;
  /** Free look mode to use for the waypoint that is placed before the orbit */
  waypointFreeLookMode: FreeLookMode_Enum;
}

export interface GotoAndOrbitPoiResultData {
}

function createBaseOrbitPoiActionArgs(): OrbitPoiActionArgs {
  return { orbitCenter: undefined, radius: 0, speed: 0, numRotations: 0, gimbalPitch: 0, hideAr: false };
}

export const OrbitPoiActionArgs = {
  encode(message: OrbitPoiActionArgs, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orbitCenter !== undefined) {
      Waypoint.encode(message.orbitCenter, writer.uint32(10).fork()).ldelim();
    }
    if (message.radius !== 0) {
      writer.uint32(21).float(message.radius);
    }
    if (message.speed !== 0) {
      writer.uint32(29).float(message.speed);
    }
    if (message.numRotations !== 0) {
      writer.uint32(37).float(message.numRotations);
    }
    if (message.gimbalPitch !== 0) {
      writer.uint32(45).float(message.gimbalPitch);
    }
    if (message.hideAr === true) {
      writer.uint32(48).bool(message.hideAr);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrbitPoiActionArgs {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrbitPoiActionArgs();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orbitCenter = Waypoint.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.radius = reader.float();
          continue;
        case 3:
          if (tag !== 29) {
            break;
          }

          message.speed = reader.float();
          continue;
        case 4:
          if (tag !== 37) {
            break;
          }

          message.numRotations = reader.float();
          continue;
        case 5:
          if (tag !== 45) {
            break;
          }

          message.gimbalPitch = reader.float();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.hideAr = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OrbitPoiActionArgs {
    return {
      orbitCenter: isSet(object.orbitCenter) ? Waypoint.fromJSON(object.orbitCenter) : undefined,
      radius: isSet(object.radius) ? globalThis.Number(object.radius) : 0,
      speed: isSet(object.speed) ? globalThis.Number(object.speed) : 0,
      numRotations: isSet(object.numRotations) ? globalThis.Number(object.numRotations) : 0,
      gimbalPitch: isSet(object.gimbalPitch) ? globalThis.Number(object.gimbalPitch) : 0,
      hideAr: isSet(object.hideAr) ? globalThis.Boolean(object.hideAr) : false,
    };
  },

  toJSON(message: OrbitPoiActionArgs): unknown {
    const obj: any = {};
    if (message.orbitCenter !== undefined) {
      obj.orbitCenter = Waypoint.toJSON(message.orbitCenter);
    }
    if (message.radius !== 0) {
      obj.radius = message.radius;
    }
    if (message.speed !== 0) {
      obj.speed = message.speed;
    }
    if (message.numRotations !== 0) {
      obj.numRotations = message.numRotations;
    }
    if (message.gimbalPitch !== 0) {
      obj.gimbalPitch = message.gimbalPitch;
    }
    if (message.hideAr === true) {
      obj.hideAr = message.hideAr;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrbitPoiActionArgs>, I>>(base?: I): OrbitPoiActionArgs {
    return OrbitPoiActionArgs.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OrbitPoiActionArgs>, I>>(object: I): OrbitPoiActionArgs {
    const message = createBaseOrbitPoiActionArgs();
    message.orbitCenter = (object.orbitCenter !== undefined && object.orbitCenter !== null)
      ? Waypoint.fromPartial(object.orbitCenter)
      : undefined;
    message.radius = object.radius ?? 0;
    message.speed = object.speed ?? 0;
    message.numRotations = object.numRotations ?? 0;
    message.gimbalPitch = object.gimbalPitch ?? 0;
    message.hideAr = object.hideAr ?? false;
    return message;
  },
};

function createBaseOrbitPoiResultData(): OrbitPoiResultData {
  return {};
}

export const OrbitPoiResultData = {
  encode(_: OrbitPoiResultData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrbitPoiResultData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrbitPoiResultData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): OrbitPoiResultData {
    return {};
  },

  toJSON(_: OrbitPoiResultData): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<OrbitPoiResultData>, I>>(base?: I): OrbitPoiResultData {
    return OrbitPoiResultData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OrbitPoiResultData>, I>>(_: I): OrbitPoiResultData {
    const message = createBaseOrbitPoiResultData();
    return message;
  },
};

function createBaseOrbitPoiActionStateData(): OrbitPoiActionStateData {
  return { actionArgs: undefined };
}

export const OrbitPoiActionStateData = {
  encode(message: OrbitPoiActionStateData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.actionArgs !== undefined) {
      OrbitPoiActionArgs.encode(message.actionArgs, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrbitPoiActionStateData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrbitPoiActionStateData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.actionArgs = OrbitPoiActionArgs.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OrbitPoiActionStateData {
    return { actionArgs: isSet(object.actionArgs) ? OrbitPoiActionArgs.fromJSON(object.actionArgs) : undefined };
  },

  toJSON(message: OrbitPoiActionStateData): unknown {
    const obj: any = {};
    if (message.actionArgs !== undefined) {
      obj.actionArgs = OrbitPoiActionArgs.toJSON(message.actionArgs);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrbitPoiActionStateData>, I>>(base?: I): OrbitPoiActionStateData {
    return OrbitPoiActionStateData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OrbitPoiActionStateData>, I>>(object: I): OrbitPoiActionStateData {
    const message = createBaseOrbitPoiActionStateData();
    message.actionArgs = (object.actionArgs !== undefined && object.actionArgs !== null)
      ? OrbitPoiActionArgs.fromPartial(object.actionArgs)
      : undefined;
    return message;
  },
};

function createBaseInterceptOrbitPoiMode(): InterceptOrbitPoiMode {
  return {};
}

export const InterceptOrbitPoiMode = {
  encode(_: InterceptOrbitPoiMode, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InterceptOrbitPoiMode {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInterceptOrbitPoiMode();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): InterceptOrbitPoiMode {
    return {};
  },

  toJSON(_: InterceptOrbitPoiMode): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<InterceptOrbitPoiMode>, I>>(base?: I): InterceptOrbitPoiMode {
    return InterceptOrbitPoiMode.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InterceptOrbitPoiMode>, I>>(_: I): InterceptOrbitPoiMode {
    const message = createBaseInterceptOrbitPoiMode();
    return message;
  },
};

function createBaseGotoAndOrbitPoiActionArgs(): GotoAndOrbitPoiActionArgs {
  return {
    orbitArgs: undefined,
    interceptMode: 0,
    motionArgs: undefined,
    customIntercept: undefined,
    waypointFreeLookMode: 0,
  };
}

export const GotoAndOrbitPoiActionArgs = {
  encode(message: GotoAndOrbitPoiActionArgs, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orbitArgs !== undefined) {
      OrbitPoiActionArgs.encode(message.orbitArgs, writer.uint32(10).fork()).ldelim();
    }
    if (message.interceptMode !== 0) {
      writer.uint32(16).int32(message.interceptMode);
    }
    if (message.motionArgs !== undefined) {
      MotionArgs.encode(message.motionArgs, writer.uint32(26).fork()).ldelim();
    }
    if (message.customIntercept !== undefined) {
      Waypoint.encode(message.customIntercept, writer.uint32(34).fork()).ldelim();
    }
    if (message.waypointFreeLookMode !== 0) {
      writer.uint32(40).int32(message.waypointFreeLookMode);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GotoAndOrbitPoiActionArgs {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGotoAndOrbitPoiActionArgs();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orbitArgs = OrbitPoiActionArgs.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.interceptMode = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.motionArgs = MotionArgs.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.customIntercept = Waypoint.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.waypointFreeLookMode = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GotoAndOrbitPoiActionArgs {
    return {
      orbitArgs: isSet(object.orbitArgs) ? OrbitPoiActionArgs.fromJSON(object.orbitArgs) : undefined,
      interceptMode: isSet(object.interceptMode) ? interceptOrbitPoiMode_EnumFromJSON(object.interceptMode) : 0,
      motionArgs: isSet(object.motionArgs) ? MotionArgs.fromJSON(object.motionArgs) : undefined,
      customIntercept: isSet(object.customIntercept) ? Waypoint.fromJSON(object.customIntercept) : undefined,
      waypointFreeLookMode: isSet(object.waypointFreeLookMode)
        ? freeLookMode_EnumFromJSON(object.waypointFreeLookMode)
        : 0,
    };
  },

  toJSON(message: GotoAndOrbitPoiActionArgs): unknown {
    const obj: any = {};
    if (message.orbitArgs !== undefined) {
      obj.orbitArgs = OrbitPoiActionArgs.toJSON(message.orbitArgs);
    }
    if (message.interceptMode !== 0) {
      obj.interceptMode = interceptOrbitPoiMode_EnumToJSON(message.interceptMode);
    }
    if (message.motionArgs !== undefined) {
      obj.motionArgs = MotionArgs.toJSON(message.motionArgs);
    }
    if (message.customIntercept !== undefined) {
      obj.customIntercept = Waypoint.toJSON(message.customIntercept);
    }
    if (message.waypointFreeLookMode !== 0) {
      obj.waypointFreeLookMode = freeLookMode_EnumToJSON(message.waypointFreeLookMode);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GotoAndOrbitPoiActionArgs>, I>>(base?: I): GotoAndOrbitPoiActionArgs {
    return GotoAndOrbitPoiActionArgs.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GotoAndOrbitPoiActionArgs>, I>>(object: I): GotoAndOrbitPoiActionArgs {
    const message = createBaseGotoAndOrbitPoiActionArgs();
    message.orbitArgs = (object.orbitArgs !== undefined && object.orbitArgs !== null)
      ? OrbitPoiActionArgs.fromPartial(object.orbitArgs)
      : undefined;
    message.interceptMode = object.interceptMode ?? 0;
    message.motionArgs = (object.motionArgs !== undefined && object.motionArgs !== null)
      ? MotionArgs.fromPartial(object.motionArgs)
      : undefined;
    message.customIntercept = (object.customIntercept !== undefined && object.customIntercept !== null)
      ? Waypoint.fromPartial(object.customIntercept)
      : undefined;
    message.waypointFreeLookMode = object.waypointFreeLookMode ?? 0;
    return message;
  },
};

function createBaseGotoAndOrbitPoiResultData(): GotoAndOrbitPoiResultData {
  return {};
}

export const GotoAndOrbitPoiResultData = {
  encode(_: GotoAndOrbitPoiResultData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GotoAndOrbitPoiResultData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGotoAndOrbitPoiResultData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GotoAndOrbitPoiResultData {
    return {};
  },

  toJSON(_: GotoAndOrbitPoiResultData): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GotoAndOrbitPoiResultData>, I>>(base?: I): GotoAndOrbitPoiResultData {
    return GotoAndOrbitPoiResultData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GotoAndOrbitPoiResultData>, I>>(_: I): GotoAndOrbitPoiResultData {
    const message = createBaseGotoAndOrbitPoiResultData();
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
