import { Object3D, Quaternion } from "three";

import { TAU } from "@skydio/math";
import { TransPb } from "@skydio/pbtypes/pbtypes/gen/body/trans_pb";
import type { UnstampedTransf } from "@skydio/pbtypes/pbtypes/gen/vehicle_stats_lite/unstamped_transf_pb";

// define mod such that it always returns a positive number
export const mod = (n: number, m: number) => ((n % m) + m) % m;
// ported from util/common/math.py
export const mod_2_pi = (angle: number) => {
  return mod(angle + Math.PI, TAU) - Math.PI;
};
export const angleDifference = (desired: number, actual: number) => {
  return mod_2_pi(desired - actual);
};

export const convertTransPbToObject3 = (
  trans: TransPb.AsObject | UnstampedTransf.AsObject
): Object3D => {
  const output = new Object3D();
  // @ts-ignore
  output.translateX(trans.position.dataList[0]);
  // @ts-ignore
  output.translateY(trans.position.dataList[1]);
  // @ts-ignore
  output.translateZ(trans.position.dataList[2]);
  output.setRotationFromQuaternion(
    // @ts-ignore
    new Quaternion().fromArray(trans.orientation.xyzwList)
  );
  output.updateMatrix();
  return output;
};
