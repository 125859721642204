/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { QuaterniondPb } from "../eigen_lcm/Quaterniond";
import { Vector3dPb } from "../eigen_lcm/Vector3d";

export const protobufPackage = "body";

/**
 * This file automatically generated by skymarshal.
 * DO NOT MODIFY BY HAND!!!!
 */

export interface TransPb {
  utime: number;
  orientation: QuaterniondPb | undefined;
  position: Vector3dPb | undefined;
}

function createBaseTransPb(): TransPb {
  return { utime: 0, orientation: undefined, position: undefined };
}

export const TransPb = {
  encode(message: TransPb, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.utime !== 0) {
      writer.uint32(8).sint64(message.utime);
    }
    if (message.orientation !== undefined) {
      QuaterniondPb.encode(message.orientation, writer.uint32(18).fork()).ldelim();
    }
    if (message.position !== undefined) {
      Vector3dPb.encode(message.position, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TransPb {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTransPb();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.utime = longToNumber(reader.sint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.orientation = QuaterniondPb.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.position = Vector3dPb.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TransPb {
    return {
      utime: isSet(object.utime) ? globalThis.Number(object.utime) : 0,
      orientation: isSet(object.orientation) ? QuaterniondPb.fromJSON(object.orientation) : undefined,
      position: isSet(object.position) ? Vector3dPb.fromJSON(object.position) : undefined,
    };
  },

  toJSON(message: TransPb): unknown {
    const obj: any = {};
    if (message.utime !== 0) {
      obj.utime = Math.round(message.utime);
    }
    if (message.orientation !== undefined) {
      obj.orientation = QuaterniondPb.toJSON(message.orientation);
    }
    if (message.position !== undefined) {
      obj.position = Vector3dPb.toJSON(message.position);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TransPb>, I>>(base?: I): TransPb {
    return TransPb.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TransPb>, I>>(object: I): TransPb {
    const message = createBaseTransPb();
    message.utime = object.utime ?? 0;
    message.orientation = (object.orientation !== undefined && object.orientation !== null)
      ? QuaterniondPb.fromPartial(object.orientation)
      : undefined;
    message.position = (object.position !== undefined && object.position !== null)
      ? Vector3dPb.fromPartial(object.position)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
